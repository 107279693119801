import React, { useState } from 'react';
import { Box, Card, IconButton, Stack, Typography } from '@mui/material';
import { CloudUpload as CloudUploadIcon, Delete } from '@mui/icons-material';
import clsx from 'clsx';
import { grey } from '@mui/material/colors';
import { Xls502Icon } from '../../../common/Icons';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';

export const FileUpload = ({
  accept = '.xls,.xlsx',
  hoverLabel = 'Clique ou arraste aqui a planilha Excel.',
  dropLabel = 'Solte o arquivo aqui',
  width = '100%',
  height = 'auto',
  backgroundColor = '#fff',
  onChange,
  onDrop,
  onRemove,
}) => {
  const { exibirAlerta } = useCommonItems();

  const [fileName, setFileName] = useState('');
  const [labelText, setLabelText] = useState(hoverLabel);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);

  const stopDefaults = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const isFileExtensionValid = (fileName) => {
    const validExtensions = accept.split(',');
    const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();
    return validExtensions.includes(fileExtension);
  };

  const dragEvents = {
    onMouseEnter: () => setIsMouseOver(true),
    onMouseLeave: () => setIsMouseOver(false),
    onDragEnter: (e) => {
      stopDefaults(e);
      setIsDragOver(true);
      setLabelText(dropLabel);
    },
    onDragLeave: (e) => {
      stopDefaults(e);
      setIsDragOver(false);
      setLabelText(hoverLabel);
    },
    onDragOver: stopDefaults,
    onDrop: (e) => {
      stopDefaults(e);
      setLabelText(hoverLabel);
      setIsDragOver(false);

      const file = e.dataTransfer.files[0];
      if (file) {
        if (!isFileExtensionValid(file.name)) {
          exibirAlerta('Tipo de arquivo inválido.', 'É permitido apenas arquivos do tipo .xls ou .xlsx', 'warning');
          return;
        }

        setFileName(file.name);
        onDrop(e);
      }
    },
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      setFileName(event.target.files[0].name);
    }
    onChange(event);
  };

  return (
    <>
      <input
        onChange={handleChange}
        accept={accept}
        style={{ display: 'none' }}
        id="file-upload"
        type="file"
      />

      {fileName === '' ? (
        <label
          htmlFor="file-upload"
          {...dragEvents}
          className={clsx(isDragOver && 'onDragOver')}
          style={{
            cursor: 'pointer',
            textAlign: 'center',
            display: 'flex',
            width,
            height,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width,
              height,
              position: 'relative',
              pointerEvents: 'none',
              backgroundColor,
              margin: 'auto',
              maxWidth: '800px',
              padding: 8,
              borderRadius: 5,
              border: '2px dashed',
              borderColor: isDragOver || isMouseOver ? 'primary.main' : 'grey.300',
              transition: 'border-color 0.3s',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Stack spacing={1} alignItems="center">
                <CloudUploadIcon
                  fontSize="large"
                  sx={{ color: isDragOver || isMouseOver ? 'primary.main' : grey[600], transition: 'color 0.3s' }}
                />

                <Typography variant="h1" color={grey[800]}>
                  Inicie a importação
                </Typography>

                <Typography variant="body" color={grey[600]}>
                  {labelText}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </label>
      ) : (
        <Box
          sx={{
            width,
            height,
            position: 'relative',
            backgroundColor,
            margin: 'auto',
            maxWidth: '800px',
            paddingX: 8,
            paddingY: 7.4,
            borderRadius: 5,
            border: '2px dashed',
            borderColor: 'primary.main',
            transition: 'border-color 0.3s',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Stack spacing={1} alignItems="center">
              <Card
                variant='outlined'
                sx={{
                  maxWidth: '180px',
                  margin: 'auto',
                  padding: 2,
                  position: 'relative',
                  zIndex: 9,
                }}
              >
                <IconButton
                  size="small"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    color: grey[600],
                  }}
                  onClick={() => {
                    setFileName('');
                    onRemove();
                  }}
                >
                  <Delete />
                </IconButton>

                <Xls502Icon fontSize="large" color="primary" />

                <Typography variant="body1" color={grey[800]}>
                  {fileName.length > 30
                    ? `${fileName.substring(0, 30)}...${fileName.substring(fileName.lastIndexOf('.'))}`
                    : fileName
                  }
                </Typography>
              </Card>
            </Stack>
          </Box>
        </Box >
      )}
    </>
  );
};
