import { Print } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { getImageSrc } from './configs/utils';

export const RetiradaDetailsModal = ({ open, onClose, retiradaDetails }) => {
  const printModalContent = () => {
    const modalContent = document.getElementById('modal-content');

    // copy modalContent to another variable to remove buttons
    const modalContentToPrint = modalContent.cloneNode(true);
    const buttons = modalContentToPrint.querySelectorAll('button');
    buttons.forEach(button => button.remove());

    if (modalContentToPrint) {
      const printWindow = window.open('', '_blank', 'width=800,height=600');
      printWindow.document.write(`
        <html>
          <head>
            <title>Imprimir Detalhes da Retirada</title>
            <style>
              body { font-family: Arial, sans-serif; padding: 20px; }
              .modal-content { display: flex; flex-direction: column; align-items: center; gap: 0px }
              .modal-content * { margin-top: 10px; margin-bottom: 10px; }
              .modal-content h2 { margin-bottom: 20px; }
            </style>
          </head>
          <body>
            <div class="modal-content">
              ${modalContentToPrint.innerHTML}
            </div>
          </body>
        </html>
      `);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} id='modal-content'>
      <DialogTitle variant='h5' sx={{ fontWeight: 'bold', color: grey[800] }}>Detalhes da Retirada</DialogTitle>
      <DialogContent>
        {retiradaDetails ? (
          <Stack spacing={0.5} sx={{ width: '100%' }}>
            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>Empresa: </Typography>
              {retiradaDetails.empresa.nomeFantasia}
            </Typography>

            <Divider variant="middle" />

            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>Funcionário: </Typography>
              {retiradaDetails.pessoa.nome}
            </Typography>

            <Divider variant="middle" />

            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>
                Produto: </Typography>
              {retiradaDetails.quantidadeProduto}x {retiradaDetails.produto.nome}
            </Typography>

            <Divider variant="middle" />

            <Stack direction="row" spacing={9}>
              <Typography variant='subtitle1'>
                <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>Autenticação Via: </Typography>
                {retiradaDetails.tipoAutenticacao}
              </Typography>

              {retiradaDetails.tipoAutenticacao == "biometria" && (
                <img src={getImageSrc(retiradaDetails.faceBiometricPhoto)} alt={retiradaDetails.pessoa.nome} style={{ maxWidth: 150, maxHeight: 250, borderRadius: 4 }} />
              )}
            </Stack>

            <Divider variant="middle" />

            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>Equipamento: </Typography>
              {retiradaDetails.equipamento.nome}
            </Typography>

            <Divider variant="middle" />

            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>IP do Equipamento: </Typography>
              {retiradaDetails.dadosEquipamento.ip}
            </Typography>

            <Divider variant="middle" />

            <Typography variant='subtitle1'>
              <Typography variant='subtitle1' component="span" sx={{ fontWeight: '700', color: grey[800] }}>Próxima Retirada: </Typography>
              {retiradaDetails.proximaRetirada.data}
            </Typography>
          </Stack>
        ) : (
          <Typography variant='subtitle1'>Carregando...</Typography>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="background" variant='contained'>
          Fechar
        </Button>

        <Button onClick={printModalContent} color="primary" variant="contained" startIcon={<Print />}>
          Imprimir
        </Button>
      </DialogActions>
    </Dialog >
  );
};