import { useState } from 'react';
import dayjs from 'dayjs';
import {
  Button,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { blue, grey, red } from '@mui/material/colors';
import { Add, Delete, FileOpen, Notifications } from '@mui/icons-material';
import { InfoIcon } from '../../../../common/Icons';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import { getFormErrorMessage, renderDay } from '../../../../../configs/functions';
import { TooltipSelectDisabled } from '../../../../common/TooltipSelectDisabled';

export function DocumentItem({ handleUpdateDocumentData, prontuario, register, errors, setError, handleDeleteProntuario, tiposDocumento, selectedEmpresaId, disableActions }) {
  const [hasExpiration, setHasExpiration] = useState(prontuario ? !!prontuario.data_fim : false);
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Grid container spacing={2} sx={{ alignItems: 'end' }}>
        <Grid item xs={12} md={6}>
          <TextField
            disabled
            size='small'
            label="Arquivo Enviado"
            name={`prontuarios[${prontuario.id}].arquivo`}
            {...register(`prontuarios[${prontuario.id}].arquivo`, { required: false })}
            fullWidth
            defaultValue={prontuario.arquivo instanceof File ? prontuario.arquivo.name : prontuario.nome_arquivo}
            error={!!errors[`prontuarios[${prontuario.id}].arquivo`]}
            helperText={getFormErrorMessage(errors, `prontuarios[${prontuario.id}].arquivo`)}
            InputProps={{
              inputProps: { min: 0 },
              startAdornment:
                <InputAdornment position="start">
                  <FileOpen sx={{ color: grey[500] }} />
                </InputAdornment>,
            }}
          />
        </Grid>

        <Grid item xs={12} md={5}>
          <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
            <TextField
              select
              fullWidth
              size='small'
              label="Tipo do Documento"
              name={`prontuarios[${prontuario.id}].id_documento`}
              disabled={!selectedEmpresaId || disableActions}
              defaultValue={prontuario ? prontuario.id_documento : ''}
              error={!!errors[`prontuarios[${prontuario.id}].id_documento`]}
              helperText={getFormErrorMessage(errors, `prontuarios[${prontuario.id}].id_documento`)}
              {...register(`prontuarios[${prontuario.id}].id_documento`, { required: false })}
              onChange={(e) => {
                handleUpdateDocumentData({ id_documento: e.target.value }, prontuario.id);
              }}
            >
              {tiposDocumento.length > 0 ? (
                tiposDocumento.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.titulo}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled>Nenhum disponível</MenuItem>
              )}
              <MenuItem
                value=""
                sx={{ bgcolor: grey[50] }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  setOpenDialog(true);
                }}
              >
                <Add sx={{ mr: 1 }} fontSize='small' />
                <Typography variant='body1' color='primary'>Criar Novo</Typography>
              </MenuItem>
            </TextField>
          </TooltipSelectDisabled>
        </Grid>

        <Grid item xs={12} md={1}>
          {!disableActions && (
            <Button
              onClick={handleDeleteProntuario}
              variant='contained'
              sx={{ mb: 0.3, minWidth: 15, bgcolor: red[600], "&:hover": { bgcolor: red[800] } }}
            >
              <Delete />
            </Button>
          )}
        </Grid>

        <Grid item xs={12} md={3}>
          <Typography variant='body2' color='textSecondary'>
            Documento possui Validade?
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={hasExpiration}
                onChange={() => setHasExpiration(prev => !prev)}
                color="primary"
                disabled={disableActions}
              />
            }
            label={hasExpiration ? "Sim" : "Não"}
          />
        </Grid>

        {!hasExpiration ? (
          <Grid item xs={12} md={3}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Ocorrência"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              name={`prontuarios[${prontuario.id}].data_ocorrencia`}
              {...register(`prontuarios[${prontuario.id}].data_ocorrencia`, { required: false })}
              defaultValue={prontuario ? dayjs(prontuario.data_ocorrencia) : dayjs()}
              dayOfWeekFormatter={renderDay}
              onChange={(date) => handleUpdateDocumentData({ data_ocorrencia: dayjs(date).format('YYYY-MM-DD') }, prontuario.id)}
              onError={(error) => {
                if (error) {
                  const errorMessage = error == "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                  setError(`prontuarios[${prontuario.id}].data_ocorrencia`, { type: "invalid", message: errorMessage });
                }
              }}
              disabled={disableActions}
              slotProps={{
                textField: {
                  error: !!errors[`prontuarios[${prontuario.id}].data_ocorrencia`],
                  helperText: errors[`prontuarios[${prontuario.id}].data_ocorrencia`] ? errors[`prontuarios[${prontuario.id}].data_ocorrencia`].message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                }
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={12} md={3}>
              <DatePicker
                sx={{ width: '100%' }}
                label="Data de Início"
                size='small'
                format="DD/MM/YYYY"
                openTo="day"
                name={`prontuarios[${prontuario.id}].data_inicio`}
                {...register(`prontuarios[${prontuario.id}].data_inicio`, { required: false })}
                defaultValue={prontuario ? prontuario.data_inicio && dayjs(prontuario.data_inicio) : dayjs()}
                maxDate={dayjs()}
                dayOfWeekFormatter={renderDay}
                onChange={(date) => handleUpdateDocumentData({ data_inicio: dayjs(date).format('YYYY-MM-DD') }, prontuario.id)}
                onError={(error) => {
                  if (error) {
                    const errorMessage = error == "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                    setError(`prontuarios[${prontuario.id}].data_inicio`, { type: "invalid", message: errorMessage });
                  }
                }}
                disabled={disableActions}
                slotProps={{
                  textField: {
                    error: !!errors[`prontuarios[${prontuario.id}].data_inicio`],
                    helperText: errors[`prontuarios[${prontuario.id}].data_inicio`] ? errors[`prontuarios[${prontuario.id}].data_inicio`].message : null,
                    size: 'small',
                    style: {
                      backgroundColor: '#fff',
                      borderRadius: 4,
                    }
                  }
                }}
                TextField={(params) => (
                  <TextField
                    size='small'
                    autoComplete='off'
                    fullWidth
                    type="date"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <DatePicker
                sx={{ width: '100%' }}
                label="Data de Expiração"
                size='small'
                format="DD/MM/YYYY"
                openTo="day"
                name={`prontuarios[${prontuario.id}].data_fim`}
                defaultValue={prontuario && prontuario.data_fim && dayjs(prontuario.data_fim)}
                dayOfWeekFormatter={renderDay}
                onChange={(date) => handleUpdateDocumentData({ data_fim: dayjs(date).format('YYYY-MM-DD') }, prontuario.id)}
                onError={(error) => {
                  if (error) {
                    const errorMessage = error == "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                    setError(`prontuarios[${prontuario.id}].data_fim`, { type: "invalid", message: errorMessage });
                  }
                }}
                disabled={disableActions}
                slotProps={{
                  textField: {
                    error: !!errors[`prontuarios[${prontuario.id}].data_fim`],
                    helperText: errors[`prontuarios[${prontuario.id}].data_fim`] ? errors[`prontuarios[${prontuario.id}].data_fim`].message : null,
                    size: 'small',
                    style: {
                      backgroundColor: '#fff',
                      borderRadius: 4,
                    }
                  },
                }}
                TextField={(params) => (
                  <TextField
                    size='small'
                    autoComplete='off'
                    fullWidth
                    type="date"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <TextField
                fullWidth
                size='small'
                type='number'
                label='Ponto de Alerta'
                name={`prontuarios[${prontuario.id}].ponto_alerta`}
                defaultValue={prontuario ? prontuario.ponto_alerta : 0}
                {...register(`prontuarios[${prontuario.id}].ponto_alerta`, { required: false })}
                error={!!errors.ponto_alerta}
                helperText={!!errors.ponto_alerta && "Informe a quantidade de dias antes do vencimento"}
                onBlur={(e) => handleUpdateDocumentData({ ponto_alerta: e.target.value }, prontuario.id)}
                disabled={disableActions}
                InputProps={{
                  inputProps: { min: 0 },
                  startAdornment:
                    <InputAdornment position="start">
                      <Notifications sx={{ color: grey[500] }} />
                    </InputAdornment>,
                  endAdornment:
                    <>
                      <Typography sx={{ mx: 1 }}>
                        dia(s)
                      </Typography>

                      <InputAdornment position="end">
                        <Tooltip title="Quantidade de dias que avisa antes do vencimento do produto." placement="top">
                          <InfoIcon sx={{ color: blue[500], cursor: 'pointer' }} />
                        </Tooltip>
                      </InputAdornment>
                    </>,
                }}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Divider variant='middle' />

      <ConfirmDialog
        title="Criar novo Tipo de Documento?"
        description="Essa ação irá redirecioná-lo para uma nova página."
        goAction={() => window.location.href = '/gerenciamento/organizacao/tipos_de_documento'}
        handleClose={setOpenDialog}
        state={openDialog}
        buttonColor="primary"
      />
    </>
  )
}
