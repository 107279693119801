import axios from "axios";
import { urlDashboard } from "../../../../../constants/endpoints";

const makeRequest = (endpoint, companiesIds = null, period = null) => {
  companiesIds = Array.isArray(companiesIds) ? companiesIds : [companiesIds];

  if (companiesIds) {
    const params = period ? { companies: companiesIds, period } : { companies: companiesIds };
    return axios.get(`${urlDashboard}/${endpoint}`, { params });
  }
}

export const getConsumoEpiPorCentroCusto = (companiesIds, period = 30) => makeRequest('consumoEPI', companiesIds, period);

export const getRetiradasRequisicoes = (companiesIds, period = 90) => makeRequest('retiradasRequisicoes', companiesIds, period);

export const getRequisicoesDepartamento = (companiesIds) => makeRequest('requisicoesDepartamento', companiesIds);

export const getConsumoEpiFunc = (companiesIds) => makeRequest('consumoEpiFunc', companiesIds);

export const getTotalEpisVencidos = (companiesIds) => makeRequest('totalEpisVencidos', companiesIds);

export const getTotalCAsVencidos = (companiesIds) => makeRequest('caVencidos', companiesIds);

export const getTotalCAsVencer = (companiesIds) => makeRequest('vencimentoPeriodoCA', companiesIds);

export const getTotalEquipEstoqueBaixo = (companiesIds) => makeRequest('equipEstoqueBaixo', companiesIds);

export const getTotalEquipDesconectados = (companiesIds) => makeRequest('equipDesconectados', companiesIds);

export const getProductsRanking = (companiesIds) => makeRequest('rankingProdutos', companiesIds);