import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  MenuItem,
  CircularProgress
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { LocationOn, } from '@mui/icons-material';
import { manipulaPontoEntrega } from '../configs/functions';
import { fillInputErrors, getFormErrorMessage } from '../../../../../configs/functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { GetLocationDialog } from './GetLocationDialog';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { getDefaultValueSelectField } from '../../../../../utils';

export function PontoEntregaForm({ open, setOpen, pontoEntrega, empresas }) {
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [location, setLocation] = useState(null);
  const [openDialogLocation, setOpenDialogLocation] = useState(false);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isEditAction = !!pontoEntrega;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const onSubmit = async (data) => {
    let formDataToSend = {};
    setIsLoading(true);
    exibirDialog('Salvando. Aguarde...');

    if (isEditAction) {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      formDataToSend = dirtyData;

      if (location && JSON.parse(pontoEntrega.geolocation) !== location) {
        formDataToSend.geolocation = location;
      }

      formDataToSend.id = pontoEntrega.id;
    } else {
      delete data.id;
      delete data.latitude;
      delete data.longitude;
      data.geolocation = location;
      formDataToSend = data;
    }

    manipulaPontoEntrega(formDataToSend, isEditAction)
      .then((res) => {
        exibirAlerta('Sucesso', res.data.message, 'success');
        setOpen(false)
      })
      .catch((error) => {
        console.error(error);
        let errorMessage = "Erro ao cadastrar ponto de entrega.";
        if (error.response) {
          errorMessage = error.response.data.message
          fillInputErrors(error, setError);
        }
        exibirAlerta('Erro', errorMessage, 'error');
      }).finally(() => {
        setIsLoading(false);
        handleCloseDialog();
      });
  };

  function handleLocationClick() {
    if (navigator.geolocation) {
      setIsLoadingLocation(true);
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLocation({ latitude, longitude });
          setIsLoadingLocation(false);
        },
        () => {
          exibirAlerta('Erro', 'Permissão para obter localização não concedida.', 'error');
          setIsLoadingLocation(false);
        }
      );
    } else {
      exibirAlerta('Erro', 'Não foi possível buscar sua localização, tente com um navegador diferente.', 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const geolocation = pontoEntrega ? JSON.parse(pontoEntrega.geolocation) : null;

      const defaultValues = {
        id: pontoEntrega ? pontoEntrega.id : '',
        nome: pontoEntrega ? pontoEntrega.nome : '',
        status: pontoEntrega ? pontoEntrega.status : 1,
        descricao: pontoEntrega ? pontoEntrega.descricao : '',
        latitude: geolocation ? geolocation.latitude : '',
        longitude: geolocation ? geolocation.longitude : '',
        id_empresa: getDefaultValueSelectField(pontoEntrega, pontoEntrega?.empresa?.id, empresas),
      };
      if (pontoEntrega) {
        setLocation(geolocation);
      }
      reset(defaultValues);
    } else {
      reset();
      setLocation(null);
    }
  }, [empresas, open, pontoEntrega, reset]);

  useEffect(() => {
    if (location) {
      setValue('latitude', location.latitude);
      setValue('longitude', location.longitude);
    }
  }, [location, setValue]);

  return (<>
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Ponto de Entrega <strong>{pontoEntrega.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Ponto de Entrega</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Ponto de Entrega.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nome'
                    placeholder='Digite o nome do Ponto de Entrega'
                    name='nome'
                    fullWidth
                    {...register("nome", { required: true })}
                    error={!!errors.nome}
                    helperText={getFormErrorMessage(errors, 'nome')}
                    variant="outlined"
                  />
                </Grid>

                {empresas &&
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='id_empresa'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={pontoEntrega ? pontoEntrega.id_empresa : ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Empresa"
                          onChange={(e) => {
                            setValue('id_empresa', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors("id_empresa");
                            }
                          }}
                          error={!!errors.id_empresa}
                          helperText={getFormErrorMessage(errors, 'id_empresa')}
                        >
                          {empresas.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.nomeFantasia}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao")}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant='body1'>Localização do Ponto de Entrega</Typography>
                </Grid>

                <Grid item xs={6} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    disabled
                    margin='dense'
                    label='Latitude'
                    name='latitude'
                    {...register("latitude")}
                    error={!!errors.latitude}
                    helperText={getFormErrorMessage(errors, 'latitude')}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} md={3}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    fullWidth
                    disabled
                    margin='dense'
                    label='Longitude'
                    name='longitude'
                    {...register("longitude")}
                    error={!!errors.longitude}
                    helperText={getFormErrorMessage(errors, 'longitude')}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={4} mt={3}>
                  <Button
                    variant='contained'
                    onClick={() => setOpenDialogLocation(true)}
                    startIcon={<LocationOn />}
                    disabled={isLoadingLocation}
                  >
                    {isLoadingLocation
                      ? <><CircularProgress size={16} sx={{ marginRight: 1 }} /> Obtendo Localização...</>
                      : location ? `Alterar Localização` : 'Selecionar Localização'
                    }
                  </Button>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
      </form>

      <GetLocationDialog
        open={openDialogLocation}
        setOpen={setOpenDialogLocation}
        handleLocationClick={handleLocationClick}
      />
    </Dialog>
  </>);
}