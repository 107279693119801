import { Card, CardContent, Typography, IconButton } from '@mui/material';
import { lightGreen, grey } from '@mui/material/colors';
import { Delete } from '@mui/icons-material';

function SelectionCard({ id, title, description, isSelected, onClick, onRemove }) {
    return (
        <Card
            onClick={onClick}
            elevation={2}
            sx={{
                cursor: 'pointer',
                backgroundColor: isSelected ? lightGreen[700] : 'white',
                color: isSelected ? 'white' : 'black',
                height: '5rem !important',
                position: 'relative',
                transition: 'all 0.3s',
                '&:hover': {
                    backgroundColor: isSelected ? lightGreen[700] : lightGreen[100],
                },
            }}
        >
            <CardContent>
                <Typography variant="h5" component="div" sx={{ fontWeight: isSelected ? 600 : 400 }}>
                    {title}
                </Typography>
                <Typography variant="body2" sx={{ minHeight: 50 }}> {/* Definindo uma altura mínima para a descrição */}
                    {description}
                </Typography>
            </CardContent>
            <IconButton
                aria-label="Remover"
                onClick={(e) => {
                    e.stopPropagation(); // Impedir que o evento de clique se propague para o card
                    onRemove(id);
                }}
                sx={{ position: 'absolute', top: 5, right: 5, color: isSelected ? 'white' : grey[500] }}
            >
                <Delete />
            </IconButton>
        </Card>
    );
}

export default SelectionCard;
