import React from 'react';
import {
  Box,
  Card,
  Dialog,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { formatKey, renderValue } from '../../epis/alerts/utils/formatDetailsAlert';
import { Close } from '@mui/icons-material';

export function AlertDetailsError({ alertas, open, setOpen }) {
  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" mb={2} fontWeight='bold'>Detalhes Erros de Entrega:</Typography>
        <Typography variant='body2'>
          <Stack spacing={1}>
            {alertas.map((alerta) =>
              <React.Fragment key={alerta.id}>
                <Card sx={{ padding: 2, marginBottom: 1 }} variant="outlined">
                  <Typography variant='body2'>
                    <strong>Id Alarme: </strong> {alerta.id}
                  </Typography>

                  {Object.entries(alerta.json_info).map(([key, value], index) => (
                    <Box key={`${key}-${index}`}>
                      <Typography variant='body2'>
                        <strong>{formatKey(key)}:</strong> {renderValue(value, true)}
                      </Typography>
                    </Box>
                  ))}
                </Card>

                {alertas.length - 1 !== alertas.indexOf(alerta) && <Divider />}
              </React.Fragment>
            )}
          </Stack>
        </Typography>
      </Box>
    </Dialog>
  )
}