import { TableRow, TableCell, Skeleton, Stack, useMediaQuery } from '@mui/material';

export function ProductSkeletonTableRow({ numCells, numRows = 5 }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  return (
    <>
      {Array(numRows).fill(0).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={50} />
          </TableCell>

          <TableCell>
            <Stack direction="row" spacing={2} justifyContent='start' alignItems='center'>
              <Skeleton variant="circular" width={34} height={34} />
              <Skeleton variant="text" width={isMobile ? 100 : 320} />
            </Stack>
          </TableCell>

          {Array(numCells - 3).fill(0).map((_, index) => (
            <TableCell key={index}>
              <Skeleton variant="text" width={"100%"} />
            </TableCell>
          ))}

          <TableCell align='right'>
            <Stack direction="row" spacing={1.2} justifyContent={'flex-end'}>
              <Skeleton align='right' variant="rounded" width={58} height={20} />
            </Stack>
          </TableCell>

          <TableCell align='right' sx={{ minWidth: 110 }}>
            <Stack direction="row" spacing={1.2} justifyContent={'flex-end'} sx={{ p: 0.5 }}>
              <Skeleton variant="circular" width={30} height={30} />
              <Skeleton variant="circular" width={30} height={30} />
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}