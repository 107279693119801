import { Breadcrumbs, Grid, Typography } from '@mui/material';
import React from 'react';
import PageTitle from '../../../common/PageTitle';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';

function Settings() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <Typography variant='span'>Gerenciamento</Typography>
                    <LinkNavegacao to='/gerenciamento/configuracoes'>Configurações</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<SettingsIcon fontSize='large' />} title={'Configurações'} />
            </Grid>
        </Grid>
    </>);
}

export default Settings;