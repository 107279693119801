import { React } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box, Divider, ListItem } from '@mui/material';

function MenuTitle({ title, icon, stateVar, fChangeState }) {



    return (<>
        {<Box sx={{ backgroundColor: "#f4f4f4" }}>
            <ListItemButton onClick={fChangeState} sx={{ px: 1 }}>
                <ListItem>
                    {icon && <ListItemIcon>{icon}</ListItemIcon>}
                    <ListItemText primary={title}
                        primaryTypographyProps={{
                            fontFamily: "Open Sans Bold",
                            textTransform: 'uppercase'
                        }
                        } />
                    {stateVar ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

            </ListItemButton>
            <Divider variant='middle' />
        </Box>}
    </>);
}

export default MenuTitle;