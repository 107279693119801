import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_CRYPTO_KEY;

export function encrypt(text) {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
}

export function decrypt(ciphertext) {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
}
