import { BrowserRouter } from 'react-router-dom';

import { AppRoutes } from './app.routes';
import { AuthRoutes } from './auth.routes';
import { FirstAccessRoutes } from './first-access.routes';
import { AppLayout } from '../layouts/AppLayout';

export function Routes({ userToken, isFirstAccess }) {
  return (
    <BrowserRouter>
      {userToken ?
        (isFirstAccess
          ? <FirstAccessRoutes />
          : (
            <AppLayout>
              <AppRoutes />
            </AppLayout>
          )
        )
        : <AuthRoutes />
      }
    </BrowserRouter>
  )
}