import { useRef, useState, useCallback } from 'react';
import WebCam from 'react-webcam';
import { Box, Grid, Button, Stack, Typography } from '@mui/material';
import { AddAPhoto, CameraAlt, Cancel, NoAccounts } from '@mui/icons-material';
import maskFaceWebcam from '../../../../../assets/images/mascara-detector-facial.png';
import cameraShutterSound from '../../../../../assets/sounds/camera-shutter.mp3';
import { grey } from '@mui/material/colors';

function DisplayBoxIncorrectBiometry() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', bgcolor: '#f5f5f5', textAlign: 'center' }}>
      <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
        <NoAccounts sx={{ fontSize: '60px', color: "#00000033" }} />

        <Typography variant='body2' sx={{ color: grey[700] }}>
          Biometria facial inválida. Por favor, cadastre novamente.
        </Typography>
      </Stack>
    </Box>
  );
}

export const WebcamCapture = ({ biometriaSrc, setBiometriaSrc, isAllowedCapture, setIsAllowedCapture, disabled }) => {
  const webcamRef = useRef(null);
  const audioRef = useRef(new Audio(cameraShutterSound));
  const [countdown, setCountdown] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const isValidImage = biometriaSrc && biometriaSrc.includes('/9j/');

  const startCapture = () => {
    setIsAllowedCapture(true);
    setCountdown(3);
  };

  const captureScreenshotPhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (!imageSrc) return;
    const imageInBase64 = '/9j/' + imageSrc.split('base64,/9j/')[1];
    setBiometriaSrc(imageInBase64);

    audioRef.current.play();
  }, [webcamRef, setBiometriaSrc, audioRef]);

  const startCountdown = useCallback(() => {
    setIsCounting(true);

    const timeBetweenCountdown = 700;

    let timer = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, timeBetweenCountdown);

    setTimeout(() => {
      clearInterval(timer);
      setIsCounting(false);
      captureScreenshotPhoto(); // Após o cronômetro, captura a foto
    }, timeBetweenCountdown * 3);

    return () => clearInterval(timer);
  }, [captureScreenshotPhoto]);

  return (
    <Grid item xs={12} md={12}>
      <Stack direction='column' spacing={1} justifyContent='center' alignItems='center'>
        <Box sx={{ position: 'relative', borderRadius: 1, width: '250px', height: '250px', overflow: 'hidden', border: '1px solid #00000033' }}>
          {isAllowedCapture ?
            (biometriaSrc
              ? isValidImage
                ? <img
                  src={`data:image/jpeg;base64,${biometriaSrc}`}
                  style={{
                    width: '100%',
                    objectFit: 'cover',
                    height: '100%'
                  }}
                  alt=""
                />
                : <DisplayBoxIncorrectBiometry />
              :
              <>
                <img src={maskFaceWebcam} style={{ position: 'absolute', zIndex: 2, width: 250 }} alt="" />
                <WebCam
                  ref={webcamRef}
                  mirrored
                  screenshotFormat="image/jpeg"
                  videoConstraints={{
                    width: 250,
                    height: 250,
                    facingMode: "user"
                  }}
                />
              </>
            )
            : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', bgcolor: '#f5f5f5' }}>
              <NoAccounts sx={{ fontSize: '60px', color: "#00000033" }} />
            </Box>
          }

          {isAllowedCapture && !biometriaSrc && countdown > 0 && (
            <Typography variant='h1' style={{ position: 'absolute', zIndex: 3, top: 10, left: 10, color: '#fff' }}>
              {countdown}
            </Typography>
          )}
        </Box>

        {isAllowedCapture ?
          <Button
            variant='contained'
            onClick={() => {
              if (!biometriaSrc) {
                startCountdown(); // Iniciar contagem regressiva antes da captura
              } else {
                setBiometriaSrc(null);
                startCapture();
              }
            }}
            startIcon={!biometriaSrc ? <CameraAlt /> : <Cancel />}
            disabled={isCounting || disabled}
          >
            {!biometriaSrc ? 'Capturar' : 'Capturar Novamente'}
          </Button>
          :
          <Button
            variant='contained'
            onClick={startCapture}
            startIcon={<AddAPhoto />}
            disabled={disabled}
          >
            Abrir Câmera
          </Button>
        }
      </Stack>

      {/* Elemento de áudio para reprodução do som */}
      <audio ref={audioRef} src={cameraShutterSound} />
    </Grid>
  );
}
