import React from 'react'
import { Box } from '@mui/material'

function Footer() {
  return (<>
    {
      process.env.NODE_ENV !== 'production' &&
      <Box sx={{ textAlign: 'center', padding: 1, position: 'fixed', bottom: 0, left: 0, backgroundColor: '#ffd200' }}>
        <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode.</small><br />
        <small>Application version: {process.env.REACT_APP_VERSION}</small><br />
        <small>API: {process.env.REACT_APP_API}</small>

      </Box>
    }
  </>);
}

export default Footer;