import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import { Close, LocationOn, LocationSearching } from '@mui/icons-material';

export function GetLocationDialog({ open, setOpen, handleLocationClick }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogTitle id="alert-dialog-title">
        Localização do Ponto de Entrega
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Como deseja informar a localização do ponto de entrega?
        </DialogContentText>

        <Stack spacing={2} mt={2}>
          <Button
            onClick={() => {
              handleLocationClick();
              setOpen(false);
            }}
            autoFocus
            variant='contained'
            startIcon={<LocationOn />}
          >
            Usar Localização Atual
          </Button>

          <Button
            variant="contained"
            startIcon={<LocationSearching />}
            onClick={() => { }}
            disabled
          >
            Selecionar manualmente
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}