import { Routes, Route, Navigate } from 'react-router-dom';

import Signin from '../components/screens/signin';
import { ForgotPassword } from '../components/screens/ForgotPassword';
import { NewPassword } from '../components/screens/NewPassword';

export function AuthRoutes() {
  return (
    <Routes>
      <Route path='/' Component={Signin} />
      <Route path='/esqueceu-senha' Component={ForgotPassword} />
      <Route path='/nova-senha' Component={NewPassword} />

      {/* Rota de Fallback, faz o redirecionamento de rotas indisponíveis */}
      <Route path='*' element={<Navigate to="/" />} />
    </Routes>
  )
}