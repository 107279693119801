import axios from 'axios';
import { urlEquipamento, urlConfigEquipamento, urlPontoEntrega, urlEstoque } from '../../../../../constants/endpoints';

// Ponto Entrega
export const carregaPontosEntrega = (filter = null) => axios.get(urlPontoEntrega, { params: filter });

export const deletaPontoEntrega = (id) => axios.delete(`${urlPontoEntrega}/${id}`)

export const atualizaPontoEntrega = (id, data) => axios.patch(`${urlPontoEntrega}/${id}`, data)

export const cadastraPontoEntrega = (data) => axios.post(`${urlPontoEntrega}`, data)

export const manipulaPontoEntrega = (data, isEditAction) => isEditAction ? atualizaPontoEntrega(data.id, data) : cadastraPontoEntrega(data)

// Estoque
export const carregaEstoques = (filter = null) => axios.get(urlEstoque, { params: filter });

export const deletaEstoque = (id) => axios.delete(`${urlEstoque}/${id}`)

export const atualizaEstoque = (id, data) => axios.patch(`${urlEstoque}/${id}`, data)

export const cadastraEstoque = (data) => axios.post(`${urlEstoque}`, data)

export const manipulaEstoque = (data, isEditAction) => isEditAction ? atualizaEstoque(data.id, data) : cadastraEstoque(data)

// Equipamentos
export const carregaEquipamentos = (filter) => {
  return axios.get(urlEquipamento, { params: filter })
}

export const atualizaEquipamento = (id, data) => axios.patch(`${urlEquipamento}/${id}`, data)

export const cadastraEquipamento = (data) => axios.post(`${urlEquipamento}`, data)

export const manipulaEquipamento = (data, isEditAction) => isEditAction ? atualizaEquipamento(data.id, data) : cadastraEquipamento(data)

export const deletaEquipamento = (id) => axios.delete(`${urlEquipamento}/${id}`)

export const generateUpdateTokenEquipamento = (id) => axios.post(`${urlEquipamento}/generateUpdateToken`, { "id_equipamento": id })

// ConfigEquipamento
export const carregaConfigsEquipamento = (filter = null) => {
  if (filter) {
    return axios.get(`${urlConfigEquipamento}/buscarConfigEquipamento`, { params: filter })
  }

  return axios.get(urlConfigEquipamento)
};

export const atualizaConfigEquipamento = (id, data) => axios.patch(`${urlConfigEquipamento}/${id}`, data)

export const cadastraConfigEquipamento = (data) => axios.post(`${urlConfigEquipamento}`, data)

export const manipulaConfigEquipamento = (data, isEditAction) => isEditAction ? atualizaConfigEquipamento(data.id, data) : cadastraConfigEquipamento(data)

export const deletaConfigEquipamento = (id) => axios.delete(`${urlConfigEquipamento}/${id}`)

export const arrayGroupInPairs = (array) => {
  const pairs = [];
  for (let i = 0; i < array.length; i += 2) {
    pairs.push(array.slice(i, i + 2));
  }
  return pairs;
}