import { EarProtectionIcon, EyeProtectionIcon, FootProtectionIcon, FootWashIcon, HairProtectionIcon, HandProtectionIcon, WearSeatBeltsIcon, WearAntiStaticBootsIcon, WearAntiStaticGlovesIcon, WearApronIcon, WearDustMaskIcon, WearEarPlugAndGogglesIcon, WearFaceShieldIcon, WearHalfMaskRespiratorIcon, WearHelmetAndFaceShieldIcon, WearHighVisibilityJacketIcon, WearLaboratoryCoatIcon, WearLifeJacketIcon, WearProtectiveClothingIcon, WearRespiratorIcon, WearSafetyHarnessIcon, WearSafetyHelmetIcon, WearWeldingMaskIcon } from '../components/common/Icons';

const estadosBrasileiros = [
  { sigla: 'AC', nome: 'Acre' },
  { sigla: 'AL', nome: 'Alagoas' },
  { sigla: 'AP', nome: 'Amapá' },
  { sigla: 'AM', nome: 'Amazonas' },
  { sigla: 'BA', nome: 'Bahia' },
  { sigla: 'CE', nome: 'Ceará' },
  { sigla: 'DF', nome: 'Distrito Federal' },
  { sigla: 'ES', nome: 'Espírito Santo' },
  { sigla: 'GO', nome: 'Goiás' },
  { sigla: 'MA', nome: 'Maranhão' },
  { sigla: 'MT', nome: 'Mato Grosso' },
  { sigla: 'MS', nome: 'Mato Grosso do Sul' },
  { sigla: 'MG', nome: 'Minas Gerais' },
  { sigla: 'PA', nome: 'Pará' },
  { sigla: 'PB', nome: 'Paraíba' },
  { sigla: 'PR', nome: 'Paraná' },
  { sigla: 'PE', nome: 'Pernambuco' },
  { sigla: 'PI', nome: 'Piauí' },
  { sigla: 'RJ', nome: 'Rio de Janeiro' },
  { sigla: 'RN', nome: 'Rio Grande do Norte' },
  { sigla: 'RS', nome: 'Rio Grande do Sul' },
  { sigla: 'RO', nome: 'Rondônia' },
  { sigla: 'RR', nome: 'Roraima' },
  { sigla: 'SC', nome: 'Santa Catarina' },
  { sigla: 'SP', nome: 'São Paulo' },
  { sigla: 'SE', nome: 'Sergipe' },
  { sigla: 'TO', nome: 'Tocantins' }
];

const categoryIcons = [
  { name: 'EarProtection', component: EarProtectionIcon, name_PtBr: 'Proteção Auditiva' },
  { name: 'EyeProtection', component: EyeProtectionIcon, name_PtBr: 'Proteção Ocular' },
  { name: 'FootProtection', component: FootProtectionIcon, name_PtBr: 'Proteção para os Pés' },
  { name: 'FootWash', component: FootWashIcon, name_PtBr: 'Proteção para os Pés Impermeável' },
  { name: 'HairProtection', component: HairProtectionIcon, name_PtBr: 'Proteção Capilar' },
  { name: 'HandProtection', component: HandProtectionIcon, name_PtBr: 'Proteção para as Mãos' },
  { name: 'WearSeatBelts', component: WearSeatBeltsIcon, name_PtBr: 'Cintos de Segurança' },
  { name: 'WearAntiStaticBoots', component: WearAntiStaticBootsIcon, name_PtBr: 'Botas Antiestáticas' },
  { name: 'WearAntiStaticGloves', component: WearAntiStaticGlovesIcon, name_PtBr: 'Luvas Antiestáticas' },
  { name: 'WearApron', component: WearApronIcon, name_PtBr: 'Avental' },
  { name: 'WearDustMask', component: WearDustMaskIcon, name_PtBr: 'Máscara de Poeira' },
  { name: 'WearFaceShield', component: WearFaceShieldIcon, name_PtBr: 'Protetor Facial' },
  { name: 'WearHalfMaskRespirator', component: WearHalfMaskRespiratorIcon, name_PtBr: 'Respirador de Meia Máscara' },
  { name: 'WearHelmetAndFaceShield', component: WearHelmetAndFaceShieldIcon, name_PtBr: 'Capacete e Protetor Facial' },
  { name: 'WearHighVisibilityJacket', component: WearHighVisibilityJacketIcon, name_PtBr: 'Jaqueta de Alta Visibilidade' },
  { name: 'WearLaboratoryCoat', component: WearLaboratoryCoatIcon, name_PtBr: 'Jaleco de Laboratório' },
  { name: 'WearLifeJacket', component: WearLifeJacketIcon, name_PtBr: 'Colete Salva-Vidas' },
  { name: 'WearProtectiveClothing', component: WearProtectiveClothingIcon, name_PtBr: 'Roupas de Proteção' },
  { name: 'WearRespirator', component: WearRespiratorIcon, name_PtBr: 'Respirador' },
  { name: 'WearSafetyHarness', component: WearSafetyHarnessIcon, name_PtBr: 'Cinto de Segurança' },
  { name: 'WearSafetyHelmet', component: WearSafetyHelmetIcon, name_PtBr: 'Capacete de Segurança' },
  { name: 'WearWeldingMask', component: WearWeldingMaskIcon, name_PtBr: 'Máscara de Solda' },
  { name: 'WearEarPlugAndGoggles', component: WearEarPlugAndGogglesIcon, name_PtBr: 'Protetores Auriculares e Óculos' },
];

export {
  estadosBrasileiros,
  categoryIcons
}
