import {
  TableRow,
  TableCell,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  Box,
} from '@mui/material';
import { Mail, Phone } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { IndicatorsTableCell } from './IndicatorsTableCell';
import { InfoIcon } from '../../common/Icons';

export function CompanyRow({ empresa }) {
  const alertasErroEntrega = empresa.alertas.filter((alerta) => alerta.alarme.id === 15);

  return (
    <TableRow hover key={empresa.id} sx={{ backgroundColor: '#f1f1f1' }}>
      <TableCell>{empresa.nome}</TableCell>
      <TableCell>
        <Tooltip
          title={
            empresa.contato.email || empresa.contato.telefone ? (
              <Box sx={{ backgroundColor: grey[50], padding: 1, borderRadius: 1, color: grey[900] }}>
                <Typography variant="body2" mb={1}>Informações do Responsável:</Typography>

                {empresa.contato.email && (
                  <a href={`mailto:${empresa.contato.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Stack direction="row" spacing={1} mt={1}>
                      <Mail fontSize='small' color='primary' />
                      <Typography variant="body2">{empresa.contato.email}</Typography>
                    </Stack>
                  </a>
                )}

                {empresa.contato.telefone && (
                  <a href={`tel:${empresa.contato.telefone}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Stack direction="row" spacing={1} mt={1}>
                      <Phone fontSize='small' color='primary' />
                      <Typography variant="body2">{empresa.contato.telefone}</Typography>
                    </Stack>
                  </a>
                )}
              </Box>
            ) : 'Sem informações de contato'
          }
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: grey[600],
                '& .MuiTooltip-arrow': {
                  color: grey[600],
                },
              },
            },
          }}
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </TableCell>

      <IndicatorsTableCell
        alertas={alertasErroEntrega ?? []}
        equipamentos={empresa?.equipamentos ?? []}
        totalEquipDesconectados={empresa.summary.totalEquipDesconectados}
        totalEquipEstoqueBaixo={empresa.summary.totalEquipEstoqueBaixo}
        totalEpisVencidos={empresa.summary.totalEpisVencidos}
        totalCAsVencidos={empresa.summary.totalCAsVencidos}
        totalCAsVencer={empresa.summary.totalCAsVencer}
      />
    </TableRow>
  )
}