import { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box, Tooltip, Stack, useMediaQuery } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { Legend } from '../../../../../common/Legend';
import { TutorialInstructions } from '../../../../../common/TutorialInstructions';
import ConfirmDialog from '../../../../../common/ConfirmDialog';

export function SecondStepEquipmentForm({ qtdMotores, motores, setMotores }) {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const [firstMotorIsSelected, setFirstMotorIsSelected] = useState(false);
  const [selectedMotor, setSelectedMotor] = useState(null);
  const [selectableMotores, setSelectableMotores] = useState([]);

  const [openUngroupDialog, setOpenUngroupDialog] = useState(false);
  const [openOccupiedDialog, setOpenOccupiedDialog] = useState(false);

  const [indexesOccupiedGroup, setIndexesOccupiedGroup] = useState({ firstMotorIndex: null, targetMotorIndex: null });

  const legendItems = [
    { label: 'Motor Livre', color: green[200] },
    { label: 'Motor Ocupado', color: green[900] },
    { label: 'Motor Agrupado', color: grey[400] },
  ];

  const colCount = qtdMotores === 60 ? 10 : 6;

  const checkIsFirstInRow = (index) => {
    return (qtdMotores === 36 && index % 6 === 0) || (qtdMotores === 60 && index % 10 === 0);
  };

  const checkIsLastInRow = (index) => {
    return (qtdMotores === 36 && index % 6 === 5) || (qtdMotores === 60 && index % 10 === 9);
  };

  const handleGroupMotor = (selectedMotorIndex, targetMotorIndex) => {
    const updatedMotors = [...motores];
    const toggleUngroup = !updatedMotors[selectedMotorIndex].agrupado_por;

    // if the motor is already grouped, confirm the ungroup
    if (!toggleUngroup && !openUngroupDialog) {
      if (firstMotorIsSelected) {
        handleCancelGroupSelection();
        return;
      }
      setOpenUngroupDialog(true);
      setSelectedMotor(updatedMotors[selectedMotorIndex]);
      return;
    }

    updatedMotors[selectedMotorIndex].agrupado_por = toggleUngroup ? targetMotorIndex + 1 : null;
    updatedMotors[selectedMotorIndex].id_produto = null;
    updatedMotors[selectedMotorIndex].is_dirty = true;
    if (!toggleUngroup) updatedMotors[selectedMotorIndex].status = 'livre';

    setMotores(updatedMotors);
  };

  const handleSelectMotor = (index) => {
    const clickedMotor = motores[index];

    if (!firstMotorIsSelected) {
      setFirstMotorIsSelected(true);
      setSelectedMotor(clickedMotor);
      focusOnSelectable(index);
    } else {
      const selectedMotorIndex = index;
      const previousMotorIndex = index - 1;
      const nextMotorIndex = index + 1;

      let firstMotorIndex = null;
      let targetMotorIndex = null;

      if (selectedMotor.numero_motor < motores[selectedMotorIndex].numero_motor) {
        firstMotorIndex = previousMotorIndex;
        targetMotorIndex = selectedMotorIndex;
      } else if (selectedMotor.numero_motor > motores[selectedMotorIndex].numero_motor) {
        firstMotorIndex = selectedMotorIndex;
        targetMotorIndex = nextMotorIndex;
      }

      // verify if targetMotorIndex is already 'ocupado'
      if ((motores[firstMotorIndex].status === 'ocupado' || motores[targetMotorIndex].status === 'ocupado') && !openOccupiedDialog) {
        setIndexesOccupiedGroup({ firstMotorIndex, targetMotorIndex });
        setOpenOccupiedDialog(true);
        return;
      }

      handleGroupMotor(firstMotorIndex, targetMotorIndex);

      setFirstMotorIsSelected(false);
      setSelectedMotor(null);
    }
  };

  const focusOnSelectable = (index) => {
    const selectable = [];

    if (!checkIsFirstInRow(index)) {
      const shouldPush = index <= 1 || (motores[index - 2] && !motores[index - 2].agrupado_por);
      if (shouldPush) {
        selectable.push(index - 1);
      }
    }

    if (!checkIsLastInRow(index) && motores[index + 1] && !motores[index + 1].agrupado_por) {
      selectable.push(index + 1);
    }

    setSelectableMotores(selectable);
  };

  const handleCancelGroupSelection = () => {
    setFirstMotorIsSelected(false);
    setSelectedMotor(null);
  };

  const handleConfirmUngroupAction = () => {
    if (selectedMotor && selectedMotor.numero_motor) {
      const selectedMotorIndex = selectedMotor.numero_motor - 1;
      const targetMotorIndex = selectedMotor.agrupado_por - 1;
      handleGroupMotor(selectedMotorIndex, targetMotorIndex);
    }
    setOpenUngroupDialog(false);
    setSelectedMotor(null);
  };

  const handleConfirmOccupiedAction = () => {
    if (indexesOccupiedGroup.firstMotorIndex !== null && indexesOccupiedGroup.targetMotorIndex !== null) {
      handleGroupMotor(indexesOccupiedGroup.firstMotorIndex, indexesOccupiedGroup.targetMotorIndex);
    }
    setOpenOccupiedDialog(false);
    setIndexesOccupiedGroup({ firstMotorIndex: null, targetMotorIndex: null });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      // ao pressionar esc, cancela a seleção de grupo
      if (event.key === 'Escape') {
        handleCancelGroupSelection();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} columnGap={3}>
      <Grid item xs={12}>
        <Typography variant='h5' gutterBottom textAlign='center'>Deseja agrupar os motores?</Typography>
      </Grid>

      <Grid item xs={12} md={qtdMotores === 36 ? 5 : 7}>
        <Box sx={{ border: 1, borderRadius: 2, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Grid
            container
            onClick={() => {
              if (firstMotorIsSelected) {
                handleCancelGroupSelection();
              }
            }}
          >
            {motores.map((motor, index) => {
              if (motor.agrupado_por) {
                const pair = [motor, motores[motor.agrupado_por - 1]];
                const indexMotorDesagrupar = pair[1].numero_motor - 1;

                return (
                  <Grid container item key={index} xs={12 / (colCount / 2)} justifyContent="center" alignItems="center" textAlign='center'>
                    <Button
                      sx={{
                        px: isSmallScreen ? 0.1 : 0.8,
                        borderRadius: 2,
                        my: 0.1,
                        border: 2,
                        borderColor: green[200],
                        cursor: 'pointer',
                        background: 'none',
                        '&:hover': { background: 'none' }
                      }}
                      onClick={() => handleGroupMotor(index, indexMotorDesagrupar)}
                    >
                      <Tooltip title={'Desfazer grupo.'}>
                        <Stack direction='row' gap={qtdMotores === 36 ? (isSmallScreen ? 0.5 : 3) : (isSmallScreen ? 0.5 : 2)}>
                          {pair.map((motor) => (
                            <Box key={motor.numero_motor}>
                              <Typography variant='span' sx={{ cursor: 'pointer' }}>
                                <Button
                                  disabled={motor.agrupado_por}
                                  variant='contained'
                                  sx={{
                                    minWidth: isSmallScreen ? '32px' : '36px',
                                    maxWidth: isSmallScreen ? '32px' : '44px',
                                    paddingX: isSmallScreen ? 0 : 1,
                                    backgroundColor: motor.status === 'livre' ? green[200] : green[900],
                                    '&:hover': {
                                      backgroundColor: motor.status === 'livre' ? green[400] : green[800],
                                    },
                                  }}
                                >
                                  <Typography variant='caption' textAlign='center'>{motor.numero_motor}</Typography>
                                </Button>
                              </Typography>
                            </Box>
                          ))}
                        </Stack>
                      </Tooltip>
                    </Button>
                  </Grid>
                )
              } else {
                // if previous motor is grouped, with the current motor, skip it
                if (motores[index - 1] && motores[index - 1].agrupado_por == motor.numero_motor) {
                  return null;
                }

                return (
                  <Grid item key={index} xs={12 / colCount} justifyContent="center" alignItems="center" textAlign='center' sx={{ my: 1 }}>
                    <Button
                      variant='contained'
                      sx={{
                        minWidth: isSmallScreen ? '32px' : '36px',
                        maxWidth: isSmallScreen ? '32px' : '44px',
                        paddingX: isSmallScreen ? 0 : 1,
                        my: 0.1,
                        backgroundColor: motor.status === 'livre' ? green[200] : green[900],
                        '&:hover': {
                          backgroundColor: motor.status === 'livre' ? green[400] : green[800],
                        },
                      }}
                      onClick={() => handleSelectMotor(index)}
                      disabled={firstMotorIsSelected && !selectableMotores.includes(index)}
                    >
                      <Typography variant='caption' textAlign='center'>{motor.numero_motor}</Typography>
                    </Button>
                  </Grid>
                );
              }
            })}
          </Grid>
        </Box>

        <Grid item xs={12}>
          <Legend items={legendItems} />
        </Grid>
      </Grid>

      <Grid item xs={12} md={qtdMotores === 36 ? 5 : 4} sx={{ mb: 3 }}>
        <TutorialInstructions
          title='Instruções de Agrupamento:'
          steps={[
            'Clique em um motor livre para selecioná-lo.',
            'Em seguida, clique em um dos motores disponíveis para agrupá-lo.',
            'Caso deseje cancelar a seleção de grupo, pressione a tecla "Esc", ou clique em algum dos motores desabilitados.',
            'Para desfazer um agrupamento, clique sobre o mesmo.',
          ]}
        />
      </Grid>

      <ConfirmDialog
        title={'Tem certeza que deseja desfazer o agrupamento?'}
        description={'Ao confirmar essa ação o motor será desagrupado.'}
        goAction={handleConfirmUngroupAction}
        handleClose={() => {
          setSelectedMotor(null);
          setOpenUngroupDialog(false);
        }}
        state={openUngroupDialog}
      />

      <ConfirmDialog
        title={'Motor Ocupado'}
        description={
          <>
            <Typography>Um dos motores selecionados já está ocupado.</Typography>
            <Typography>Deseja realmente agrupar este motor?</Typography>
          </>
        }
        goAction={handleConfirmOccupiedAction}
        handleClose={() => {
          setSelectedMotor(null);
          setOpenOccupiedDialog(false);
        }}
        state={openOccupiedDialog}
      />
    </Grid>
  );
}
