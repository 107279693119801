//todas as URLs deste arquivos sao relacionadas à API: https://<host>/api/
export const urlValidateToken = 'validateToken'
export const urlLogin = 'login'

export const urlUnidadesDeMedidas = 'unidadeMedidas';
export const urlTamanhosEVariacoes = 'variacoes';
export const urlCategorias = 'categorias';
export const urlProdutos = 'produtos';
export const urlProdutoFilho = 'produtoFilho';

export const urlEmpresas = "empresas";
export const urlDepartamentos = "departamentos";
export const urlAreas = 'areas';
export const urlFuncoes = 'funcoes';
export const urlSetores = 'setores';
export const urlContratos = 'contratos';
export const urlCentroCustos = 'centroCustos';
export const urlJustificativaRequisicao = 'justificativaRequisicao';
export const urlJustificativaDevolucao = 'justificativaDevolucao'
export const urlRetiradas = "/retiradas";
export const urlRetiradasExtras = "/retiradaExtra";
export const urlTipoDocumento = "/tipoDocumento";
export const urlRegimeTrabalho = "/regimeTrabalho";
export const urlProntuarios = "/prontuarios";
export const urlDevolucao = "/devolucao";

export const urlEquipamento = "equipamento";
export const urlConfigEquipamento = "configEquipamento";
export const urlPontoEntrega = "pontoEntrega";
export const urlEstoque = "estoque";
export const urlDashboard = "dashboard";

export const urlFichaEpiColaborador = "relatorios/fichaEpiColaborador";
export const urlSaldoEpisPessoa = "pessoas/buscarEpi ";
export const urlFichaEpiEmpresa = "relatorios/fichaEpiEmpresa";
export const urlFichaEpiEmpresaPDF = "relatorios/fichaEpiEmpresaPDF";
export const urlRelatorioAbastecimento = "relatorios/abastecimento";
export const urlSaldoMaquina = "relatorios/saldoMaquina";
export const urlRelatorioRetiradas = "relatorios/retiradas";
export const urlRelatorioPendenciasRetiradas = "relatorios/pendenciasRetiradas";
export const urlRelatorioRetiradasExtras = "relatorios/retiradasExtras";
export const urlSaidaProdutosFunc = "relatorios/saidaProdutosFunc";
export const urlSaidaProdutosFuncAgrupado = "relatorios/saidaProdutosFuncAgrupado";
export const urlRelatorioRetiradasFunc = "relatorios/retiradasFunc";
export const urlSaidaProdutos = "relatorios/saidaProdutos";

export const urlPessoas = "pessoas";

export const urlBuscaProdutoCodigo = 'produtos/buscarProduto';

export const urlGHEs = 'grupos';
export const urlPermissao = 'permissao';

export const urlCategoriasEmpresa = 'categorias/categoriasEmpresa';

export const urlProdutosCategoria = 'categorias/:id/produtos';

export const urlConfigAlarme = 'configAlarme';
export const urlAlarmes = 'alarmes';
export const urlAlertas = 'alertas';

// rotas exclusivas adminSempher
export const urlHabilidades = "/ability";
export const urlRoles = "/roles";
export const urlRelatoriosExcelImport = "/relatoriosExcel/import";