import { Navigate, Outlet } from 'react-router-dom';

export function AdminProtectRoute() {
  const checkPermissions = () => {
    const data = localStorage.getItem("@STW:userData");
    const userData = JSON.parse(data);

    return userData && userData.isSempher;
  };

  if (!checkPermissions()) {
    localStorage.setItem(
      "@STW:alert",
      JSON.stringify({
        title: 'Ops',
        message: 'Você não tem permissão para acessar essa página',
        type: 'error',
      })
    );
    return <Navigate to="/" />;
  }

  return <Outlet />;
}
