import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import PageTitle from '../../../common/PageTitle';
import CardOption from '../../../common/CardOption';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import { Rule } from '@mui/icons-material';

function Guidelines() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <LinkNavegacao to='/epis/diretrizes'>Diretrizes</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<Rule fontSize='large' />} title='Diretrizes' />
            </Grid>


            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Regras para Grupos de Trabalho e Permissões" description="Cadastre grupos de trabalho e seus EPIs permitidos, além de definir prazos para solicitações. Controle o acesso de forma eficiente, adaptando-se às necessidades de cada equipe." url='ghes' />
            </Grid>
        </Grid>
    </>);
}

export default Guidelines;