import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import OpenSansRegular from './fonts/OpenSans-Regular.ttf'
import OpenSansBold from './fonts/OpenSans-Bold.ttf'
import OpenSansLight from './fonts/OpenSans-Light.ttf'

let theme = createTheme({

  palette: {
    mode: 'light',
    primary: {
      main: '#1e5139',
    },
    secondary: {
      main: '#e91e63',
    },
    error: {
      main: '#f10606',
    },
    background: {
      default: '#EFEFEF',
    },
    textSecondary: {
      main: "rgba(0, 0, 0, 0.6)",
    },
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 13,
    h1: {
      fontSize: '2rem',
      fontFamily: 'Open Sans Bold',
    },
    h2: {
      fontSize: '1.3rem',
      fontFamily: 'Open Sans Bold',
    },
    h3: {
      fontSize: '1.1rem',
      fontFamily: 'Open Sans Bold',
    },
  },

  components: {

    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          fontSize: "0.8em",
          marginBottom: 15
        }
      }
    },

    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: "#d8d8d8"
        }
      }
    },

    MuiTableCell: {
      styleOverrides: {
        head: {
          fontSize: "1rem",
          fontWeight: "bold"
        }
      }
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          // sm (600px)
          '@media (max-width: 600px)': {
            width: '100%',
            margin: '12px',
          },
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Open Sans';
          src: url(${OpenSansRegular}) format('truetype');
        }
              
        @font-face {
          font-family: 'Open Sans Bold';
          src: url(${OpenSansBold}) format('truetype');
        }

        @font-face {
          font-family: 'Open Sans Light';
          src: url(${OpenSansLight}) format('truetype');
        }
        `,
    },
  }
});

// Add responsive font sizes to theme
theme = responsiveFontSizes(theme);

export default theme