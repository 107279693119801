import { Breadcrumbs, Grid, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';
import { WorkersIcon } from '../../../common/Icons';

function People() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <Typography variant='span'>Pessoas</Typography>
                </Breadcrumbs>
                <PageTitle icon={<WorkersIcon fontSize='large' />} title='Pessoas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Colaboradores" description="Gerencie os colaboradores da sua empresa." url='colaboradores' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Usuários Administrativos" description="Gerencie as pessoas que tem acesso à administração dos sistemas." url='usuarios' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Funções" description="Gerencie as funções dos colaboradores da sua empresa." url='funcoes' />
            </Grid>
        </Grid>
    );
}

export default People;