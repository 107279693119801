import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  CircularProgress,
} from '@mui/material';

export function ConfirmGenerateTokenDialog({ open, selectedEquipment, goAction, handleClose, isLoading }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirmação de Geração de Novo Token
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" pb={2}>
          <Typography variant="body1" gutterBottom>
            Ao gerar um novo token, o token antigo será  <strong>excluído permanentemente</strong>. Essa ação é irreversível.
          </Typography>
        </DialogContentText>

        <DialogContentText id="alert-dialog-description">
          <Typography variant="body1" gutterBottom>
            Deseja continuar e gerar um novo token para o equipamento: <br /> <strong>{selectedEquipment?.nome}</strong>
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={handleClose}>
          Cancelar
        </Button>

        <Button
          variant='contained'
          onClick={goAction}
          disabled={isLoading}
          startIcon={isLoading && <CircularProgress size={16} sx={{ color: 'textSecondary' }} />}
        >
          Estou ciente. Gerar Token
        </Button>
      </DialogActions>
    </Dialog>
  )
}
