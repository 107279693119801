import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { DocumentIcon, ProtectiveGearAIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportFichaEPIToPDF } from './configs/utils';
import { generateFichaEPIEmpresaPDF, getDataFichaEPI } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useQuery } from '@tanstack/react-query';
import { PaginatedUserAutocomplete } from '../../../common/PaginatedUserAutocomplete';

export function FichaEPI() {
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [pessoas, setPessoas] = useState([]);
  const [pagePessoas, setPagePessoas] = useState(0);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [hasMorePessoas, setHasMorePessoas] = useState(true);

  const [dataFichaEPI, setDataFichaEPI] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const [isOpenConfirmationPeriodModal, setIsOpenConfirmationPeriodModal] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => [
    { field: 'produtoCa', headerName: 'CA', minWidth: 150, flex: 0.5 },
    { field: 'produtoNome', headerName: 'Produto', minWidth: 360, flex: 2 },
    { field: 'produtoCodExterno', headerName: 'Cód. Produto', minWidth: 120, flex: 0.5 },
    { field: 'qtd_produto', headerName: 'Quantidade', minWidth: 120, flex: 0.5 },
    { field: 'data', headerName: 'Data', minWidth: 200, flex: 1 },
    { field: 'nomeFuncao', headerName: 'Função', minWidth: 200, flex: 1 },
    { field: 'ponto_entrega', headerName: 'Ponto de Entrega', minWidth: 200, flex: 1 },
  ], []);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);

      const defaultValues = {
        id_pessoa: null,
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
      };
      reset(defaultValues);

      return response;
    },
    refetchOnWindowFocus: false,
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  async function loadReportData(id_pessoa, data_inicio, data_fim) {
    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        data_inicio,
        data_fim,
        // limit: rowsPerPage,
        // offset: page * rowsPerPage,
      };

      if (id_pessoa === 'all') {
        filter.pessoa = 1
      } else {
        filter.id_pessoa = id_pessoa
      }

      const res = await getDataFichaEPI(filter);
      setDataFichaEPI(res.data.retiradas);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  // async function loadExportPdfData(id_empresa, data_inicio, data_fim) {
  //   try {
  //     data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
  //     data_fim = dayjs(data_fim).format('YYYY-MM-DD');

  //     const filter = {
  //       id_empresa,
  //       data_inicio,
  //       data_fim
  //     };

  //     const res = await getDataFichaEPIEmpresa(filter);
  //     return res.data.retiradasAgrupadas;
  //   } catch (error) {
  //     console.error(error);
  //     exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error')
  //   }
  // };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_pessoa, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  const listaColaboradores = async (currentPage = 0, initialPageValue = false, filterName = "") => {
    if (!selectedEmpresaId) return;

    if (initialPageValue) {
      setPessoas([]);
      setPagePessoas(0);
      setHasMorePessoas(true);
    }

    try {
      setIsLoadingPessoas(true);

      const filters = {
        id_empresa: selectedEmpresaId,
        nome: filterName,
        limit: 10,
        offset: (initialPageValue ? 0 : currentPage) * 10,
      };

      const response = await listaUsuariosFilter(filters);
      const newOptions = response.data.data;

      setPessoas((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMorePessoas(newOptions.length > 0);
      setPagePessoas((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Erro ao carregar colaboradores:", error);
    } finally {
      setIsLoadingPessoas(false);
    }
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, []);

  useEffect(() => {
    listaColaboradores(0, true);
  }, [selectedEmpresaId]);

  useEffect(() => {
    const id_pessoa = getValues('id_pessoa');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_pessoa || !data_inicio || !data_fim) {
      return;
    }

    loadReportData(id_pessoa, data_inicio, data_fim);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  function exportDataToPDF(visibleColumns, reportName) {
    try {
      if (!getValues('id_pessoa')) {
        return exibirAlerta('Ops', 'Selecione um colaborador para exportar', 'warning');
      }

      // if date range is not selected
      if (!getValues('data_inicio') || !getValues('data_fim')) {
        return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
      }

      exportFichaEPIToPDF({
        visibleColumns,
        reportName,
        colaborador: pessoas.find(pessoa => pessoa.id == getValues('id_pessoa')),
        tableData: dataFichaEPI,
        data_inicio: getValues('data_inicio'),
        data_fim: getValues('data_fim'),
        selectEmpresa: empresas.find(empresa => empresa.id === Number(selectedEmpresaId)),
        dadosUsuario
      })
    } catch (error) {
      exibirAlerta('Ops', 'Erro ao exportar dados para PDF', 'error');
      console.log(error);
    }
  }

  async function exportAllFichasEmpresaToPDF() {
    if (selectedEmpresaId === null) {
      return exibirAlerta('Ops', 'Selecione uma empresa para exportar', 'warning');
    }

    const dataInicio = getValues('data_inicio');
    const dataFim = getValues('data_fim');

    if (!dataInicio || !dataFim) {
      return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
    }

    try {
      exibirDialog('Estamos registrando sua solicitação, aguarde...', 'info');

      // call api to request ficha epi empresa pdf
      const params = {
        id_empresa: selectedEmpresaId,
        data_inicio: dayjs(dataInicio).format('YYYY-MM-DD'),
        data_fim: dayjs(dataFim).format('YYYY-MM-DD'),
      };

      await generateFichaEPIEmpresaPDF(params);

      exibirAlerta(
        'Relatório solicitado com sucesso!',
        'Você receberá um e-mail com o relatório em breve.',
        'success'
      );

      // fluxo fazendo get dos dados e gerando pdf no front

      // get ficha epi empresa
      // const tableData = await loadExportPdfData(selectedEmpresaId, dataInicio, dataFim);

      // exportFichaEPIEmpresaToPDF({
      //   colaborador: pessoas.find(pessoa => pessoa.id == getValues('id_empresa')),
      //   tableData,
      //   dataInicio: dayjs(dataInicio).format('DD/MM/YYYY'),
      //   dataFim: dayjs(dataFim).format('DD/MM/YYYY'),
      //   selectedEmpresa: empresas.find(empresa => empresa.id == selectedEmpresaId),
      //   dadosUsuario
      // })
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao exportar dados para PDF', 'error');
      console.log(error);
    } finally {
      handleCloseDialog();
      setIsOpenConfirmationPeriodModal(false);
    }
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Ficha de Consumo de EPI's - Colaborador</Typography>
        </Breadcrumbs>
        <PageTitle icon={<ProtectiveGearAIcon fontSize='large' />} title="Ficha de Consumo de EPI's - Colaborador" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setIsSearched(false);
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_pessoa', null, { shouldDirty: true })
              }}
              textHelper={false}
              isLoading={isLoadingEmpresas}
            />
          </Grid>

          {pessoas &&
            <Grid item xs={12} md={6}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <PaginatedUserAutocomplete
                  name="id_pessoa"
                  control={control}
                  rules={{ required: true }}
                  label="Colaborador"
                  disabled={!selectedEmpresaId}
                  options={pessoas}
                  getOptionLabel={(option) => {
                    // verify if the user exists in pessoas
                    const pessoa = pessoas.find(p => p.id === option);
                    return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                  }}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                      {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                    </MenuItem>
                  )}
                  onChange={(event, value) => {
                    setIsSearched(false);
                    setValue('id_pessoa', value ? value.id : null, { shouldDirty: true });

                    if (value) {
                      clearErrors('id_pessoa')
                    } else {
                      listaColaboradores(0, true);
                    }
                  }}
                  onInput={(event) => {
                    setPessoas([]);
                    setPagePessoas(0);
                    setHasMorePessoas(true);
                    const filterValue = event.target.value ?? "";
                    listaColaboradores(0, true, filterValue);
                  }}
                  loadOptionsFn={() => listaColaboradores(pagePessoas, false, "")}
                  hasMore={hasMorePessoas}
                  helperText={getFormErrorMessage(errors, 'id_pessoa')}
                  error={!!errors.id_pessoa}
                  loading={isLoadingPessoas}
                  textFieldSize="small"
                  textFieldStyle={{ backgroundColor: '#fff' }}
                />
              </TooltipSelectDisabled>
            </Grid>
          }
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={4}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Início"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              maxDate={dayjs()}
              dayOfWeekFormatter={renderDay}
              defaultValue={dayjs().subtract(1, 'month')}
              onChange={(data) => {
                setValue('data_inicio', data, { shouldDirty: true })
                if (data && data.isBefore(dayjs())) {
                  clearErrors('data_inicio');
                }
              }}
              onError={(error) => {
                if (error) {
                  const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                  setError("data_inicio", { type: "invalid", message: errorMessage })
                }
              }}
              slotProps={{
                textField: {
                  error: !!errors.data_inicio,
                  helperText: errors.data_inicio ? errors.data_inicio.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                }
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Fim"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              maxDate={dayjs().add(1, 'day')}
              dayOfWeekFormatter={renderDay}
              defaultValue={dayjs()}
              onChange={(data) => {
                setValue('data_fim', data, { shouldDirty: true })
                if (data && data.isBefore(dayjs().add(1, 'day'))) {
                  clearErrors('data_fim');
                }
              }}
              onError={(error) => {
                if (error) {
                  const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                  setError("data_fim", { type: "invalid", message: errorMessage })
                }
              }}
              slotProps={{
                textField: {
                  error: !!errors.data_fim,
                  helperText: errors.data_fim ? errors.data_fim.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                },
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading || Object.keys(errors).length > 0 || !verifyFieldsAreFilled([getValues('id_pessoa'), getValues('data_inicio'), getValues('data_fim')])}
              startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: dataFichaEPI.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            getRowId={(row) => row.idRetirada}
            rows={dataFichaEPI}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="client"
            // paginationModel={{ pageSize: rowsPerPage, page: page }}
            // onPaginationModelChange={({ page, pageSize }) => {
            //   setPage(page);
            //   setRowsPerPage(pageSize);
            // }}
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_ficha_epi"
                  pdfExport={exportDataToPDF}
                  props={props}
                  customItem={
                    <MenuItem onClick={() => setIsOpenConfirmationPeriodModal(true)}>
                      <DocumentIcon sx={{ mr: 1 }} /> Relatório Completo da Empresa
                    </MenuItem>
                  }
                />,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione um colaborador e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>

      <ConfirmDialog
        title='Relatório Completo da Empresa'
        description={<>
          <Typography mb={2}>
            Por gentileza, confirme o período selecionado para exportar o relatório completo da empresa.
          </Typography>

          <Typography variant='body1' color='textSecondary'>
            Data de Início: <strong>{dayjs(getValues('data_inicio')).format('DD/MM/YYYY')}</strong> <br />
            Data de Fim: <strong>{dayjs(getValues('data_fim')).format('DD/MM/YYYY')}</strong>
          </Typography>
        </>}
        goAction={exportAllFichasEmpresaToPDF}
        state={isOpenConfirmationPeriodModal}
        handleClose={setIsOpenConfirmationPeriodModal}
        buttonColor='primary'
        confirmText='Exportar'
      />
    </Grid>
  );
}