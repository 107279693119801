import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import PageTitle from '../../../common/PageTitle';
import { NotificationsActive } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';

function Alerts() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/treinamentos'>Treinamentos</LinkNavegacao >
                    <LinkNavegacao to='/treinamentos/alertas'>Alertas</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<NotificationsActive fontSize='large' />} title='Alertas' />
            </Grid>
        </Grid>
    </>);
}

export default Alerts;