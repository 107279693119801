import { useEffect, useState } from 'react';
import {
    Typography,
    TableCell,
    TableRow,
    Tooltip,
    Checkbox,
    IconButton,
    Stack,
    Box,
} from '@mui/material';
import { Info, MarkEmailRead, ScheduleSend, Unsubscribe } from '@mui/icons-material';
import { green, grey, red, yellow } from '@mui/material/colors';
import { atualizaAlerta } from './configs/functions';
import { getContrastYIQ } from './configs/utils';
import { hasPermission } from '../../../../configs/functions';
import { BoxImportantIcon, Checkmark2Icon } from '../../../common/Icons';
import { formatKey, renderValue } from './utils/formatDetailsAlert';

function getStyles(hexadecimalColor) {
    // determina a cor do texto baseado na cor de fundo
    const contrastColor = getContrastYIQ(hexadecimalColor);
    return {
        backgroundColor: hexadecimalColor ? `${hexadecimalColor}80` : 'transparent',
        borderRadius: 1,
        fontWeight: '600',
        color: contrastColor,
    };
}

const StatusEnvioIcon = ({ status }) => {
    let statusEnvioIcon;
    switch (status) {
        case 'enviado':
            statusEnvioIcon = (
                <Tooltip title="Enviado com sucesso">
                    <IconButton size='small'>
                        <MarkEmailRead sx={{ fontSize: 26, color: green[400] }} />
                    </IconButton>
                </Tooltip>
            );
            break;
        case 'erro':
            statusEnvioIcon = (
                <Tooltip title="Erro ao enviar alerta">
                    <IconButton size='small'>
                        <Unsubscribe sx={{ fontSize: 28, color: red[400] }} />
                    </IconButton>
                </Tooltip>
            );
            break;
        case 'aguardando':
            statusEnvioIcon = (
                <Tooltip title="Envio pendente">
                    <IconButton size='small'>
                        <ScheduleSend sx={{ fontSize: 28, color: yellow[600] }} />
                    </IconButton>
                </Tooltip>
            );
            break;
        default:
            statusEnvioIcon = (
                <Tooltip title="Status desconhecido">
                    <IconButton size='small'>
                        <Unsubscribe sx={{ fontSize: 28, color: grey[500] }} />
                    </IconButton>
                </Tooltip>
            );
    }

    return statusEnvioIcon;
}

export function AlertRow({ data, handleExibirAlerta, handleUpdateStatus }) {
    const [isPending, setIsPending] = useState(data.status);

    useEffect(() => {
        setIsPending(data.status);
    }, [data.status]);

    const handleToggleStatus = async () => {
        if (!hasPermission(["admin", "admin_alerta", "update_alerta"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return;
        }

        try {
            const newStatus = isPending ? 0 : 1;

            const res = await atualizaAlerta({ id: data.id, status: newStatus })
            if (res) {
                const message = isPending ? 'Alerta resolvido!' : 'Alerta reaberto!';
                const status = isPending ? 'success' : 'warning';
                const title = isPending ? 'Sucesso' : 'Atenção';

                handleExibirAlerta(title, message, status)
                handleUpdateStatus(data.id, newStatus)
                setIsPending(newStatus)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    return (
        <TableRow key={data.id}>
            <TableCell>{data.empresa.nome}</TableCell>

            <TableCell sx={getStyles(data.hexadecimalColor)}>
                <Stack direction='row' spacing={1} alignItems='center' justifyContent='space-between'>
                    {data.alarme.tipo}

                    <Tooltip
                        title={
                            <Box sx={{ backgroundColor: grey[50], padding: 1, borderRadius: 1, color: grey[900] }}>
                                <Typography variant='body2'>
                                    <Stack spacing={1}>
                                        {Object.entries(JSON.parse(data.json_info)).map(([key, value], index) => (
                                            <Box key={`${key}-${index}`}>
                                                <Typography variant='body2'>
                                                    <strong>{formatKey(key)}:</strong> {renderValue(value)}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                </Typography>
                            </Box>
                        }
                        placement="bottom"
                    >
                        <IconButton size='small' sx={{ backgroundColor: "#fff", '&:hover': { backgroundColor: "#dddddd" } }}>
                            <Info color='#2196f3' htmlColor='#2196f3' />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </TableCell>

            <TableCell>{data.createdAt}</TableCell>

            <TableCell>
                <StatusEnvioIcon status={data.statusNotificacao} />
            </TableCell>

            <TableCell align='center'>
                {data.alarme.tipo === "Descumprimento de retirada de EPI" ? (
                    <Tooltip title={isPending ? "Retirada Pendente" : "Retirada Realizada"}>
                        <IconButton size='small'>
                            {isPending
                                ? <BoxImportantIcon sx={{ fontSize: 28 }} />
                                : <Checkmark2Icon sx={{ fontSize: 28 }} />
                            }
                        </IconButton>
                    </Tooltip>
                ) : (
                    <Tooltip title={isPending ? 'Marcar como Resolvido' : 'Reabrir Alerta'}>
                        <Checkbox
                            // se não está pendente (status = 0), então está resolvido (checado)
                            checked={!isPending}
                            onClick={handleToggleStatus}
                            disabled={!hasPermission(["admin", "admin_alerta", "update_alerta"])}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Tooltip>
                )}
            </TableCell>
        </TableRow>
    )
}