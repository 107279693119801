// Constantes para tempo mínimo e máximo de rotação
const minRotationTime = Number(process.env.REACT_APP_MIN_ROTATION_TIME_MACHINE) || 1600;
const maxRotationTime = Number(process.env.REACT_APP_MAX_ROTATION_TIME_MACHINE) || 3500;

/**
 * Calcula o tempo de rotação.
 * Fórmula: tempo mínimo de rotação + ((valor do byte ∗ (tempo máximo de rotação − tempo mínimo de rotação)) / 255)
 * @param {number} byteValue - O valor do byte a ser convertido
 * @returns {number} Tempo de rotação calculado
 */
export function formulaCalRotationTimes(byteValue) {
  return Math.round(minRotationTime + ((byteValue * (maxRotationTime - minRotationTime)) / 255));
}

/**
 * Retorna o valor padrão para o campo de seleção.
 * @param {object} item - O item atual (se aplicável, no modo de edição)
 * @param {any} existentValue - O valor existente para o campo, se houver
 * @param {Array} arrayOptions - A lista de opções disponíveis
 * @param {string} [keyIdArray='id'] - A chave usada para identificar o valor (padrão é 'id')
 * @returns {any} O valor selecionado ou uma string vazia
 */
export function getDefaultValueSelectField(item, existentValue, arrayOptions, keyIdArray = 'id') {
  // Verifica se o valor existente está presente nas opções em modo de edição
  if (item && existentValue) {
    const existentValueInOptions = arrayOptions.find((option) =>
      keyIdArray === 'id'
        ? option[keyIdArray] === existentValue
        : option[keyIdArray]?.[0] === existentValue
    );

    if (existentValueInOptions) {
      return existentValue;
    }
  }

  // Retorna o valor da única opção, se houver apenas uma
  if (arrayOptions.length === 1) {
    const singleOption = arrayOptions[0];
    return keyIdArray === 'id' ? singleOption.id : singleOption[keyIdArray]?.[0] || '';
  }

  // Retorna string vazia como valor padrão
  return '';
}

// Debounce para onInputChange (500ms)
export const debounceOnInputChange = (fn, delay) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
};