import { LinearProgress } from '@mui/material';
import React, { useState, useEffect } from 'react';

function AnimatedProgress({ duration }) {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        let startTime;

        const updateProgress = () => {
            const currentTime = Date.now();
            const elapsedTime = currentTime - startTime;
            startTime = currentTime;

            setProgress((prevProgress) => {
                const newProgress = prevProgress + (100 / ((duration * 0.92) / elapsedTime));
                // 0.92 é um atraso medio que calculamos ^
                if (newProgress >= 100) {
                    setProgress(100);
                    clearInterval(intervalId);
                }

                return newProgress;
            });
        };

        startTime = Date.now();
        updateProgress(); // Chama imediatamente para iniciar o progresso

        const intervalId = setInterval(updateProgress, 100);

        return () => clearInterval(intervalId);
    }, [duration]);

    return (
        <LinearProgress variant="determinate" value={progress} />
    );
}

export default AnimatedProgress;
