import { TableCell, TableRow, IconButton, Tooltip, } from '@mui/material';
import { hasPermission } from '../../../../../configs/functions';
import { ManualIcon } from '../../../../common/Icons';
import { RetiradaExtraStatusCell } from '../../reports/components/RetiradaExtraStatusCell';

export function RetiradaExtraRow({ data, handleOpenEditForm }) {
    return (
        <TableRow sx={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
            <TableCell sx={{ flex: 2, border: 0 }}>{data.pessoa?.nome || 'Sem nome'}</TableCell>
            <TableCell sx={{ flex: 2, border: 0, minWidth: 160 }}>{data.produto.nome} - {data.produto.ca}</TableCell>
            <TableCell sx={{ flex: 3, border: 0 }}>{data.justificativaOcorrencia?.nome || 'Sem justificativa'}</TableCell>
            <TableCell sx={{ flex: 1, border: 0, minWidth: 115 }}>
                <RetiradaExtraStatusCell status={data.status} />
            </TableCell>
            <TableCell sx={{ flex: 2, border: 0 }}>{data.responsavel?.nome || 'N/A'}</TableCell>
            <TableCell sx={{ flex: 2, border: 0 }}>{data.createdAt}</TableCell>

            <TableCell align='right' sx={{ flex: 1, border: 0 }}>
                {hasPermission(["admin", "admin_retiradaExtra", "updateStatus_retiradaExtra"]) &&
                    <Tooltip title='Analisar Retirada'>
                        <IconButton
                            aria-label="Analisar Retirada"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <ManualIcon fontSize='large' />
                        </IconButton>
                    </Tooltip>
                }
            </TableCell>
        </TableRow>
    )
}