import { Breadcrumbs, Grid, Typography } from '@mui/material';
import PageTitle from '../../../common/PageTitle';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';
import { RequestServiceIcon } from '../../../common/Icons';

export function Retiradas() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <Typography variant='span'>Retiradas</Typography>
                </Breadcrumbs>

                <PageTitle icon={<RequestServiceIcon fontSize='large' />} title='Retiradas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Retiradas Extras" description="Visualize as retiradas extras feitas." url='retiradas_extras' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Devolução de EPI's" description="Registre devoluções de EPIs com eficiência. Acompanhe o histórico de devoluções, garantindo a segurança e a integridade dos equipamentos." url='devolucoes' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Justificativas para pedidos fora de regra"
                    description="Crie justificativas para solicitações de EPIs fora das regras predefinidas. Permita flexibilidade quando necessário, garantindo segurança e atendendo às circunstâncias específicas dos colaboradores."
                    url='justificativa_requisicao'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Justificativas de Devolução"
                    description="Crie justificativas para devoluções de EPIs fora das regras predefinidas. Permita flexibilidade quando necessário, garantindo segurança e atendendo às circunstâncias específicas dos colaboradores."
                    url='justificativa_devolucao'
                />
            </Grid>
        </Grid>
    );
}