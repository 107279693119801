import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useForm } from "react-hook-form"
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    Paper,
    Stack,
    Step,
    StepButton,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import Logotipo from "../../../assets/images/stw_logo_preto.svg"
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { validatePasswordRegex } from '../../../configs/functions';
import { atualizaUsuario } from '../management/people/configs/functions';
import { useAppContext } from '../../../contexts/AppContext';
import { WebcamCapture } from '../management/people/components/WebcamCapture';
import ConfirmDialog from '../../common/ConfirmDialog';
import FirstStepGeneratePassword from './FirstStepGeneratePassword';

export function FirstAccess() {
    const { exibirAlerta } = useCommonItems();
    const { dadosUsuario, signOut, updateDadosUsuarioLocalStorage } = useAppContext();

    const [openBioFaceAlert, setOpenBioFaceAlert] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    // armazena base64 da imagem capturada
    const [biometriaSrc, setBiometriaSrc] = useState(null);
    const [isAllowedCapture, setIsAllowedCapture] = useState(false);

    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Nova Senha', 'Biometria'];

    const {
        register,
        handleSubmit,
        setError,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const handleNext = async (event) => {
        event.preventDefault();
        switch (activeStep) {
            case 0:
                const newPassword = getValues('new_password');
                const newPasswordConfirmation = getValues('new_password_confirmation');

                if (await validatePasswordInput(newPassword, newPasswordConfirmation)) {
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    clearErrors('new_password');
                    clearErrors('new_password_confirmation');
                }
                break;
            case 1:
                if (!biometriaSrc) {
                    setOpenBioFaceAlert(true);
                    return;
                }

                onSubmit(getValues());
                break;
            default:
                break;
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (pos) => {
        if (pos < activeStep) {
            setActiveStep(pos)
        }
    }

    const validatePasswordInput = async (newPassword, newPasswordConfirmation) => {
        if (newPassword.length < 8) {
            setError('new_password', { type: 'manual', message: 'A senha deve ter no mínimo 8 caracteres.' });
            return false;
        }

        if (newPassword !== newPasswordConfirmation) {
            setError('new_password_confirmation', { type: 'manual', message: 'As senhas não conferem.' });
            exibirAlerta('As senhas não conferem.', '', 'error');
            return false;
        }

        if (validatePasswordRegex(newPassword) === false) {
            setError('new_password', { type: 'manual', message: 'A senha deve conter ao menos uma letra e um número.' });
            return false;
        }

        const state = newPassword && newPasswordConfirmation;

        return state;
    }

    const onSubmit = async (data) => {
        if (!validatePasswordInput(data.new_password, data.new_password_confirmation)) {
            exibirAlerta('Preencha os campos corretamente.', '', 'error');
            setActiveStep(0);
            return;
        }

        // data to /alterarSenhaTemporaria route
        // nova_senha: data.new_password,
        // nova_senha_confirmation: data.new_password_confirmation,

        const dataToSend = {
            primeiro_acesso: 0,
            senha: data.new_password,
        };

        if (biometriaSrc) {
            dataToSend.bio_face_identifier = biometriaSrc;
        }

        try {
            setIsLoadingSubmit(true);
            // const response = await alterarSenhaTemporaria(dataToSend);
            const response = await atualizaUsuario(dadosUsuario.pessoa.id_pessoa, dataToSend);
            if (response) {
                // const message = response.data.message ?? 'Senha gerada com sucesso.';
                const message = 'Dados atualizados com sucesso!';
                exibirAlerta(message, '', 'success');

                // atualizar dados do usuário
                dadosUsuario.primeiro_acesso = false;
                dadosUsuario.senha = data.new_password;

                if (biometriaSrc) {
                    dadosUsuario.pessoa.avatar = biometriaSrc;
                }

                updateDadosUsuarioLocalStorage(dadosUsuario);

                // redirect to home appRoutes
                navigate('/');
            }
        } catch (error) {
            const messageError = error.response.data.error ?? 'Ocorreu um erro.';
            exibirAlerta(messageError, '', 'error')
        } finally {
            setIsLoadingSubmit(false);
        }
    }

    return (<>
        <Helmet title="Primeiro Acesso" defer={false} />

        <Container maxWidth={'sm'}>
            <Paper sx={{ p: 3, mt: 8, borderTopColor: "primary.main", borderTopStyle: 'solid', borderTopWidth: 8 }} elevation={4}>
                <Box
                    component={'img'}
                    src={Logotipo}
                    sx={{
                        height: 'auto',
                        width: '36%',
                        margin: "0 auto",
                        display: "block",
                        mb: 2
                    }}
                />

                <Typography variant='h2' component={'h1'} textAlign={'center'}>
                    Seja Bem Vindo, {dadosUsuario.pessoa.nome}!
                </Typography>

                <Divider variant='middle' sx={{ my: 2 }} />

                <Stepper activeStep={activeStep} sx={{ px: { xs: 2, sm: 10 }, py: 2, overflowX: 'scroll' }} orientation='horizontal'>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepButton color="inherit" onClick={() => handleStep(index)}>
                                <StepLabel>{label}</StepLabel>
                            </StepButton>
                        </Step>
                    ))}
                </Stepper>

                {activeStep === 0 && (
                    <Typography p textAlign={'center'} variant='body1'>
                        Crie uma nova senha para acessar o sistema.
                    </Typography>
                )}

                {activeStep === 1 && (
                    <>
                        <Typography p pb={0} textAlign={'center'} variant='body1'>
                            Faça a captura da sua Biometria Facial.
                        </Typography>

                        <Typography p textAlign={'center'} variant='body2' sx={{ color: 'text.secondary' }}>
                            Não obrigatório, mas altamente recomendado para acesso às máquinas.
                        </Typography>
                    </>
                )}

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{ px: { xs: 1, lg: 7 }, my: 3 }}>
                        {activeStep === 0 && (
                            <FirstStepGeneratePassword
                                register={register}
                                errors={errors}
                                clearErrors={clearErrors}
                            />
                        )}

                        {activeStep === 1 && (
                            <WebcamCapture
                                biometriaSrc={biometriaSrc}
                                setBiometriaSrc={setBiometriaSrc}
                                isAllowedCapture={isAllowedCapture}
                                setIsAllowedCapture={setIsAllowedCapture}
                                isEditAction={false}
                                disabled={false}
                            />
                        )}
                    </FormControl>

                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        {activeStep === 0 && (
                            <Button variant='contained' color='background' onClick={() => signOut()}>
                                Sair
                            </Button>
                        )}

                        {activeStep === 1 && (
                            <Button variant='contained' color='background' onClick={handleBack}>
                                Voltar
                            </Button>
                        )}

                        <Button
                            variant='contained'
                            onClick={handleNext}
                            disabled={isLoadingSubmit}
                            startIcon={isLoadingSubmit && <CircularProgress size={16} sx={{ color: grey[400] }} />}
                        >
                            {activeStep === 0 ? 'Próximo' : 'Salvar'}
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </Container>

        <ConfirmDialog
            title="Tem certeza que não deseja cadastrar a biometria facial?"
            description="O cadastro da biometria facial é primordial para acesso das máquinas."
            state={openBioFaceAlert}
            goAction={() => {
                setOpenBioFaceAlert(false);
                onSubmit(getValues());
            }}
            handleClose={() => setOpenBioFaceAlert(false)}
            confirmText='Continuar sem biometria'
        />
    </>);
}