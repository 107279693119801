import { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Stepper,
  Step,
  StepLabel,
  StepButton,
  Box,
  DialogContentText,
  useMediaQuery,
} from '@mui/material';
import { SpreadSheetImportStep } from './SpreadSheetImportForm';

export function SpreadsheetImportDialog({ open, setOpen, spreadsheetFile }) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [createdIdEmpresa, setCreatedIdEmpresa] = useState(null);

  const handleNext = async () => {
    // Verifique se o primeiro passo foi concluído antes de avançar
    if (activeStep === 0 && !createdIdEmpresa) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (pos) => {
    if (pos < activeStep) {
      setActiveStep(pos)
    }
  }

  const steps = [
    'Empresa',
    'CentroCusto',
    'Departamento',
    'Area',
    'Setor',
    'Funcao',
    'Grupo',
    'Pessoa',
    'Produto',
  ];

  useEffect(() => {
    if (!open) {
      setCreatedIdEmpresa(null);
    }

    setActiveStep(0);

    return () => {
      setCreatedIdEmpresa(null);
    }
  }, [open]);

  return (
    <Dialog open={open} fullWidth maxWidth={'lg'} disableEscapeKeyDown>
      <DialogTitle>
        Importação de Planilhas
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Faça o procedimento com atenção, pois não será possível desfazer as alterações.
        </DialogContentText>

        <Stepper activeStep={activeStep} sx={{ px: { xs: 1, sm: 2 }, py: 2, overflowX: 'scroll' }} orientation={isMobile ? 'vertical' : 'horizontal'}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color="inherit" onClick={() => handleStep(index)}>
                <StepLabel>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <Box sx={{ p: 2, backgroundColor: '#fafafa' }} >
          <FormControl variant="standard" fullWidth >
            <SpreadSheetImportStep
              file={spreadsheetFile}
              activeStep={activeStep}
              steps={steps}
              isLoading={isLoading}
              handleNext={handleNext}
              setIsLoading={setIsLoading}
              createdIdEmpresa={createdIdEmpresa}
              setCreatedIdEmpresa={setCreatedIdEmpresa}
            />
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setOpen(false)} variant="contained">Cancelar</Button>

        <Divider orientation="vertical" flexItem />

        {activeStep !== 0 && (
          <Button onClick={handleBack} variant="outlined" type="button">Voltar</Button>
        )}

        {activeStep !== steps.length - 1 ? (
          <Button
            onClick={handleNext}
            variant="contained"
            type="button"
            disabled={isLoading || (activeStep === 0 && !createdIdEmpresa)}
          >
            Próximo
          </Button>
        ) : (
          <Button variant="contained" onClick={() => setOpen(false)} type="button">
            Concluído
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
