import { useEffect, useState } from 'react'
import {
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export function AbilitiesCheckboxes({ roles, abilities, pessoa, control, setValue, allowEdit }) {

  const [orphanAbilities, setOrphanAbilities] = useState([]);

  // estados para controlar checkboxes de roles e abilities
  const [parentChecked, setParentChecked] = useState({});
  const [childChecked, setChildChecked] = useState({});

  // Handler for parent checkbox change
  const handleParentChange = (roleId, isChecked) => {
    setParentChecked((prev) => ({
      ...prev,
      [roleId]: isChecked,
    }));

    // If parent checkbox is checked, set all children checkboxes to checked
    setChildChecked((prev) => ({
      ...prev,
      [roleId]: Object.keys(prev[roleId]).reduce((acc, key) => {
        acc[key] = isChecked;
        return acc;
      }, {}),
    }));
  };

  // Handler for individual child checkbox change
  const handleChildChange = (roleId, abilityId, isChecked) => {
    setChildChecked((prev) => ({
      ...prev,
      [roleId]: {
        ...prev[roleId],
        [abilityId]: isChecked,
      },
    }));
  };

  useEffect(() => {
    if (roles.length > 0) {
      const initialChildChecked = roles.reduce((acc, role) => {
        acc[role.id] = role.abilities.reduce((acc, ability) => {
          acc[ability.id] = pessoa?.abilities.includes(ability.id) || false;
          return acc;
        }, {});
        return acc;
      }, {});
      setChildChecked(initialChildChecked);

      // verifica se todas as habilidades do role estão marcadas p/ inicializar o parentChecked
      const initialParentChecked = roles.reduce((acc, role) => {
        acc[role.id] = role.abilities.every((ability) => pessoa?.abilities.includes(ability.id));
        return acc;
      }, {});
      setParentChecked(initialParentChecked);
    }

    // Filter abilities that are not in any role
    const orphanAbilities = abilities.filter(ability => !roles.some(role => isAbilityInRole(role, ability)));

    setOrphanAbilities(orphanAbilities);
  }, [roles, abilities]);


  // Helper function to check if an ability is in a role
  const isAbilityInRole = (role, ability) => role.abilities.some(roleAbility => roleAbility.id === ability.id);

  useEffect(() => {
    for (const roleId in childChecked) {
      let allChecked = false;
      if (childChecked[roleId]) {
        allChecked = Object.values(childChecked[roleId]).every(Boolean);
      }

      // Update parent checkbox state based on child checkbox state
      setParentChecked((prev) => ({
        ...prev,
        [roleId]: allChecked,
      }));
    }
  }, [childChecked]);

  return (
    <>
      <Grid container spacing={2} sx={{ py: 1 }} item xs={12}>
        <Controller
          name='abilities'
          control={control}
          defaultValue={[]}
          rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma habilidade." }}
          render={({ field: { onChange, value } }) => (
            roles?.map((role) => (
              <Grid item xs={12} md={4} key={role.id}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FormControlLabel
                      label={role.nome}
                      control={
                        <Checkbox
                          checked={parentChecked[role.id] || false}
                          disabled={!allowEdit}
                          indeterminate={!parentChecked[role.id] && Object.values(childChecked[role.id] || {}).some(Boolean)}
                          // impede o toggle do Accordion pelo checkbox
                          onClick={e => e.stopPropagation()}
                          onChange={(e, isChecked) => {
                            let updatedAbilities = [...value];

                            childChecked[role.id] = role.abilities.reduce((acc, ability) => {
                              acc[ability.id] = isChecked;
                              if (isChecked) {
                                updatedAbilities.push(ability.id);
                              } else {
                                updatedAbilities = updatedAbilities.filter((id) => id !== ability.id);
                              }
                              return acc;
                            }, {});

                            setValue('abilities', updatedAbilities, { shouldDirty: true });
                            onChange(updatedAbilities);
                            handleParentChange(role.id, isChecked);
                          }}
                        />
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                      {role.abilities.map((ability) => (
                        <FormControlLabel
                          key={ability.id}
                          label={ability.nome}
                          control={
                            <Checkbox
                              checked={childChecked[role.id]?.[ability.id] || false}
                              disabled={!allowEdit}
                              onChange={(e, isChecked) => {
                                let updatedAbilities = [...value];
                                if (isChecked) {
                                  updatedAbilities.push(ability.id);
                                } else {
                                  updatedAbilities = updatedAbilities.filter((id) => id !== ability.id);
                                }
                                setValue('abilities', updatedAbilities, { shouldDirty: true });
                                onChange(updatedAbilities);
                                handleChildChange(role.id, ability.id, isChecked)
                              }}
                            />
                          }
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))
          )}
        />
      </Grid>

      {orphanAbilities &&
        <Controller
          name='abilities'
          control={control}
          defaultValue={[]}
          rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma habilidade." }}
          render={({ field: { onChange, value } }) => (
            orphanAbilities.map((ability) => (
              <Grid item xs={12} md={4} key={ability.id}>
                <FormControlLabel
                  label={ability.nome}
                  control={
                    <Checkbox
                      defaultChecked={pessoa?.abilities.includes(ability.id)}
                      disabled={(ability.id == 1 && pessoa?.abilities.includes(1)) || !allowEdit}
                      onChange={(e, isChecked) => {
                        let updatedAbilities = [...value];
                        if (isChecked) {
                          updatedAbilities.push(ability.id);
                        } else {
                          updatedAbilities = updatedAbilities.filter((id) => id !== ability.id);
                        }
                        setValue('abilities', updatedAbilities, { shouldDirty: true });
                        onChange(updatedAbilities);
                      }}
                    />
                  }
                />
              </Grid>
            ))
          )}
        />
      }
    </>
  )
}
