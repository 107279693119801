import { Tooltip } from '@mui/material';

export function TooltipSelectDisabled({ children, isDisabled, text }) {
  return (
    <Tooltip
      title={!isDisabled ? (text ?? "Selecione uma Empresa para habilitar este campo") : ""}
      disableHoverListener={!!isDisabled}
      disableFocusListener={!!isDisabled}
    >
      <span>
        {children}
      </span>
    </Tooltip>
  )
}
