import {
  Grid,
  DialogTitle,
  Dialog,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Avatar,
} from '@mui/material';
import { Close, Person } from '@mui/icons-material';
import { blue } from '@mui/material/colors';
import { formatarCPF } from '../../../../../configs/functions';

const userInfoFields = [
  { label: 'E-mail', valuePath: 'email' },
  { label: 'Matrícula', valuePath: 'matricula' },
  { label: 'Celular', valuePath: 'celular' },
  { label: 'CPF', valuePath: 'cpf', formatFunc: formatarCPF },
  { label: 'RG', valuePath: 'rg' },
  { label: 'Nacionalidade', valuePath: 'nacionalidade' },
  { label: 'Gênero', valuePath: 'genero' },
  { label: 'Data de Nascimento', valuePath: 'dtNascimento' },
  { label: 'Cidade de Naturalidade', valuePath: 'cidade_naturalidade' },
  { label: 'Estado de Naturalidade', valuePath: 'estadoNaturalidade' },
  { label: 'Empresa', valuePath: 'empresa.fantasyName' },
  { label: 'Supervisor', valuePath: 'supervisor.nomeSupervisor' },
  { label: 'Grupo', valuePath: 'grupo.nome' },
  { label: 'Função', valuePath: 'funcao.nome' },
  { label: 'Área', valuePath: 'area.nome' },
  { label: 'Setor', valuePath: 'setor.nome' },
  { label: 'Centro de Custo', valuePath: 'centroCusto.nome' },
  { label: 'Tipo de Usuário', valuePath: 'tipoUsuario' },
  { label: 'Status', valuePath: 'status', formatFunc: (status) => (status ? 'Ativo' : 'Inativo') },
  { label: 'Data de Criação', valuePath: 'createdAt' },
  { label: 'Última atualização', valuePath: 'updatedAt' },
];

export function UserModalInfo({ open, setOpen, pessoa }) {
  function handleClose() {
    setOpen(false);
  }

  const renderUserInfoField = ({ label, valuePath, formatFunc }) => {
    const value = valuePath.split('.').reduce((acc, part) => acc && acc[part], pessoa);
    const displayValue = formatFunc ? (value ? formatFunc(value) : 'N/A') : (value ?? 'N/A');

    return (
      <Grid item xs={12} md={6} key={label}>
        <ListItem disableGutters>
          <ListItemText
            primary={`${label}:`}
            secondary={displayValue}
          />
        </ListItem>
      </Grid>
    );
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth="sm">
      {pessoa && (
        <>
          <DialogTitle>Informações do Colaborador</DialogTitle>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>

          <List sx={{ pt: 0, px: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ListItem disableGutters>
                  <ListItemAvatar>
                    <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                      {pessoa.biofaceIdentifier ? (
                        <img src={`data:image/jpeg;base64,${pessoa.biofaceIdentifier}`} alt="bioface" style={{ width: '100%' }} />
                      ) : (
                        <Person />
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={pessoa.nome || 'N/A'} />
                </ListItem>
              </Grid>

              {userInfoFields.map(renderUserInfoField)}

            </Grid>
          </List>
        </>
      )}
    </Dialog>
  );
}