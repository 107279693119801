import axios from "axios";
import { urlContratos } from '../../../../constants/endpoints';

const getContrato = (filter = null) => axios.get(urlContratos, { params: filter });

const getContratosAndCompaniesInfo = (filter = null) => axios.get(`${urlContratos}/suporteSempher`, { params: filter });

const updateContrato = (contrato) => {
  if (!contrato.id) return Promise.reject('id não informado');

  return axios.patch(`${urlContratos}/${contrato.id}`, contrato);
}

const deletaContrato = (id) => {
  if (!id) return Promise.reject('id não informado');

  return axios.delete(`${urlContratos}/${id}`)
}

export {
  getContrato,
  getContratosAndCompaniesInfo,
  updateContrato,
  deletaContrato,
}
