import { Helmet } from 'react-helmet-async';
import { Breadcrumbs, Button, Grid, Stack, Typography } from '@mui/material';
import notFoundImg from "../../../assets/images/404.png"
import { grey } from '@mui/material/colors';
import { Home } from '@mui/icons-material';
import LinkNavegacao from '../../common/Link';
import { Link } from 'react-router-dom';

export function PageNotFound() {
  return (<>
    <Helmet title='Página não encontrada' defer={false} />

    <Grid container justifyContent="center" alignItems="center">
      <Grid xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'>
            <Stack direction="row" spacing={1} alignItems="center">
              <Home fontSize='small' />
              <Typography variant='subtitle3'>
                Página Inicial
              </Typography>
            </Stack>
          </LinkNavegacao>
          <Typography variant='span'>Página não encontrada</Typography>
        </Breadcrumbs>

        <Stack spacing={3} alignItems="center" justifyContent='center' sx={{ height: '100vh', mt: '-10%', textAlign: 'center' }}>
          <img src={notFoundImg} alt="" width={'60%'} style={{ maxWidth: 330 }} />

          <Stack spacing={1}>
            <Typography variant="h1" color={grey[800]}>Oops!</Typography>
            <Typography variant="h1" color={grey[800]}>Página não encontrada</Typography>

            <Typography variant="body" color={grey[600]}>
              A página que você está procurando não foi encontrada.
            </Typography>
          </Stack>

          <Link to={'/'}>
            <Button variant='contained'>
              Voltar para Página Inicial
            </Button>
          </Link>
        </Stack>
      </Grid>
    </Grid>
  </>);
}