import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Autocomplete,
} from '@mui/material';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { listaHabilidades, manipulaHabilidade } from '../configs/functions';

export function AbilityForm({ open, setOpen, ability, queryKey }) {
  const queryClient = useQueryClient();
  const { exibirAlerta } = useCommonItems();

  const isEditAction = !!ability;

  const { data: listaAbilitiesData } = useQuery({
    queryKey: ['listaAbilities'],
    queryFn: () => listaHabilidades()
  });

  const abilities = listaAbilitiesData?.data?.data ?? [];
  const selectableAbilities = isEditAction ? abilities.filter(item => item.id !== ability.id) : abilities;

  const {
    register,
    handleSubmit,
    setError,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const { mutateAsync: manipulaHabilidadeFn, isPending } = useMutation({
    mutationFn: (data) => manipulaHabilidade(data, isEditAction),
  })

  async function handleFormSubmit(data) {
    try {
      let formDataToSend = {};

      if (isEditAction) {
        const dirtyFieldKeys = Object.keys(dirtyFields);

        const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
          if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(dirtyData).length === 0) {
          exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
          setOpen(false);
          return;
        }

        formDataToSend = dirtyData;
        formDataToSend.id = ability.id;
      } else {
        delete data.id;
        formDataToSend = data;
      }

      // filter dependencies to contain only the id
      const dependenciesIds = data.dependencies.map(item => item.id);
      formDataToSend.dependencies = dependenciesIds;

      const response = await manipulaHabilidadeFn(formDataToSend);
      if (!response) {
        throw new Error('Erro ao cadastrar Habilidade.');
      }

      // invalidate e refetch para atualizar os dados
      queryClient.invalidateQueries(queryKey);

      exibirAlerta('Sucesso', response.data.message, 'success');
      setOpen(false);
    } catch (error) {
      console.error(error);
      let errorMessage = "Erro ao cadastrar Habilidade.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: ability ? ability.id : '',
        nome: ability ? ability.nome : '',
        descricao: ability ? ability.descricao : '',
        dependencies: ability ? ability.dependencies : [],
        hierarquia: ability ? ability.hierarquia : 1,
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [ability, open, reset]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Habilidade <strong>{ability.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Habilidade</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Habilidade.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nome'
                    placeholder='Digite o Nome da Permissão'
                    fullWidth
                    {...register("nome", { required: true })}
                    error={!!errors.nome}
                    helperText={getFormErrorMessage(errors, 'nome')}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ form: { autoComplete: 'off' } }}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    type='number'
                    margin='dense'
                    label='Hierarquia'
                    placeholder='Digite a Hierarquia da Permissão'
                    fullWidth
                    {...register("hierarquia", { required: true })}
                    error={!!errors.hierarquia}
                    helperText={!!errors.hierarquia ? getFormErrorMessage(errors, 'hierarquia') : "Quanto menor o valor, maior a hierarquia"}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ min: 1, form: { autoComplete: 'off' } }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao")}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ mt: 1 }}>
                    <Controller
                      name="dependencies"
                      control={control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          multiple
                          options={selectableAbilities}
                          getOptionLabel={(option) => option.nome}
                          isOptionEqualToValue={(option, value) => option.id === value.id}
                          value={field.value || []}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                          noOptionsText="Nenhuma permissão encontrada"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Dependências"
                              placeholder="Selecione as Dependências"
                              helperText={getFormErrorMessage(errors, 'dependencies')}
                              error={!!errors.dependencies}
                            />
                          )}
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isPending} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}