import { Routes, Route, Navigate } from 'react-router-dom';
import { FirstAccess } from '../components/screens/FirstAccess';

export function FirstAccessRoutes() {
  return (
    <Routes>
      <Route path='/primeiro-acesso' Component={FirstAccess} />

      {/* Rota de Fallback, faz o redirecionamento de rotas indisponíveis */}
      <Route path='*' element={<Navigate to="/primeiro-acesso" />} />
    </Routes>
  )
}