import { Breadcrumbs, Grid, Typography, Divider } from '@mui/material';
import PageTitle from '../../../common/PageTitle';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';
import { StatisticsIcon } from '../../../common/Icons';

function Reports() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <Typography variant='span'>Relatórios</Typography>
                </Breadcrumbs>

                <PageTitle icon={<StatisticsIcon fontSize='large' />} title='Relatórios' />
            </Grid>

            {/* Seção: Retiradas e Saldos */}
            <Grid item xs={12}>
                <Typography variant="h5">Retiradas e Saldos</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Ficha de EPI - Colaborador" description="Veja a ficha de EPI de cada colaborador." url='ficha_epi_colaborador' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Retiradas" description="Detalhe as retiradas realizadas." url='retiradas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Retiradas Extras" description="Visualize as retiradas extras feitas." url='retiradas_extras' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Retiradas por Funcionário" description="Veja as retiradas por cada funcionário." url='retiradas_funcionario' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Pendências de Retiradas" description="Acompanhe as pendências de retiradas." url='pendencias_retiradas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Relatório de Saldo de EPI's por Pessoa"
                    description="Consulte o saldo de EPI's de cada pessoa."
                    url='saldo_pessoa'
                />
            </Grid>

            {/* Seção: Entregas e Saídas */}
            <Grid item xs={12}>
                <Typography variant="h5">Entregas e Saídas</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Saídas por Produtos" description="Acompanhe as saídas de produtos." url='saidas_produtos' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Entregas de Produto por Responsável" description="Analise as entregas de produtos." url='entregas_produtos_responsavel' />
            </Grid>

            {/* Seção: Máquinas e Abastecimento */}
            <Grid item xs={12}>
                <Typography variant="h5">Máquinas e Abastecimento</Typography>
                <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Saldos de Máquina" description="Consulte os saldos das máquinas." url='saldos_maquina' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Relatório de Abastecimento" description="Controle o abastecimento realizado." url='abastecimento_maquina' />
            </Grid>
        </Grid>
    );
}

export default Reports;