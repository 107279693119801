// Função para converter uma cor hexadecimal para RGB
function hexToRgb(hex) {
  if (!hex) return [255, 255, 255];
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return [r, g, b];
}

// Função para calcular a luminância relativa
function getLuminance([r, g, b]) {
  const a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

// Função para determinar a cor do texto
export function getContrastYIQ(hex) {
  const rgb = hexToRgb(hex);
  const luminance = getLuminance(rgb);
  return luminance > 0.179 ? '#000000' : '#FFFFFF'; // Limiar baseado na luminância
}

// Função mais simples para determinar a cor do texto
export function getColorByBgColor(bgColor) {
  if (!bgColor) return '#000';
  return (parseInt(bgColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';
}