import { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Typography,
  InputAdornment,
  Tooltip,
  IconButton,
  Stack,
} from '@mui/material';
import { ContentCopy, CheckCircleOutlineOutlined } from '@mui/icons-material';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';

export function GenerateTokenDialog({ open, selectedEquipment, handleClose }) {
  const { exibirAlerta } = useCommonItems();
  const [isCopied, setIsCopied] = useState(false);

  const copyTokenToClipboard = (token) => {
    try {
      navigator.clipboard.writeText(token);
      exibirAlerta("Sucesso", "Token copiado para a área de transferência", "success");
      setIsCopied(true)
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  useEffect(() => {
    setIsCopied(false)
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Token Gerado com Sucesso!
      </DialogTitle>

      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description" pb={3}>
          <Typography variant="body1" gutterBottom>
            Este token é de <strong>uso único</strong>. Certifique-se de copiá-lo antes de fechar esta janela.
          </Typography>

          <Typography variant="body1" gutterBottom>
            Token gerado para o equipamento: <strong>{selectedEquipment?.nome}</strong>.
          </Typography>
        </DialogContentText>

        <Stack direction="column" width={'100%'} justifyContent="center" alignItems="center" spacing={2}>
          <TextField
            id="outlined-read-only-input"
            label="Token do Equipamento"
            defaultValue={selectedEquipment?.token}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip arrow title="Copiar Token">
                    <IconButton onClick={() => copyTokenToClipboard(selectedEquipment?.token)}>
                      {!isCopied ? <ContentCopy color='info' /> : <CheckCircleOutlineOutlined color='success' />}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
