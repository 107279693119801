import React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Star, StarBorder } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';

import { Link } from 'react-router-dom';
import { HelpIcon } from './Icons';
import { yellow } from '@mui/material/colors';


function CardOption({ title, description, url = ".", isFavorite = false }) {
    return (
        <Card sx={{
            height: "100%",
            borderTopWidth: '0.3rem',
            borderTopColor: "primary.main",
            borderTopStyle: "solid",
            mx: {
                xs: 1,
                md: 0
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between"
        }}>

            <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent>
            <CardActions>
                <Tooltip title={isFavorite ? "Remover dos favoritos" : "Adicionar aos favoritos"}>
                    <IconButton>
                        {isFavorite ? <Star sx={{ color: yellow[700] }} /> : <StarBorder />}
                    </IconButton>
                </Tooltip>
                <Tooltip title="Acessar ajuda">
                    <IconButton><HelpIcon /></IconButton>
                </Tooltip>
                <Box sx={{ flexGrow: 1 }} />
                <Link to={url}><Button variant='contained'>Gerenciar</Button></Link>
            </CardActions>
        </Card>);
}

export default CardOption;