import { Stack, Typography } from '@mui/material';
import { CheckCircle, Error, Help } from '@mui/icons-material';

const modeOptions = [
  { value: 'online', label: 'Conectado' },
  { value: 'offline', label: 'Desconectado' },
  { value: 'unknown', label: 'Desconhecido' },
];

const getIconByMode = (mode, status) => {
  if (status === 0) return <Help color="disabled" />;

  switch (mode) {
    case 'online':
      return <CheckCircle color="success" />;
    case 'offline':
      return <Error color="error" />;
    case 'unknown':
    default:
      return <Help color="warning" />;
  }
};

export const EquipmentConnectionStatus = ({ mode, status }) => {
  const modeLabel = modeOptions.find((option) => option.value === mode)?.label || 'Desconhecido';
  const textColor = status === 1 ? 'text.primary' : 'text.disabled';

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {getIconByMode(mode, status)}
      <Typography variant="body2" color={textColor}>
        {modeLabel}
      </Typography>
    </Stack>
  );
};