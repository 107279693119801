import { TableRow, TableCell, Box, CircularProgress, Typography } from '@mui/material';

export function LoadingTableRow({ colSpan = 6 }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <CircularProgress size={20} />

          <Typography variant="body1">
            Carregando...
          </Typography>
        </Box>
      </TableCell>
    </TableRow>
  )
}