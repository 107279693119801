import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { FilterAlt, Restore } from '@mui/icons-material';
import { useAppContext } from '../../../../../contexts/AppContext';

export function ProductsTableFilter({ isOpenFilters, isLoadingList, empresas }) {
  const { setEmpresaIdSession } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams()

  const nome = searchParams.get('nome');
  const ca = searchParams.get('ca');
  const id_empresa = searchParams.get('id_empresa');

  const perPage = searchParams.get('perPage');

  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset({
      nome: nome ?? '',
      ca: ca ?? '',
      id_empresa: id_empresa ?? '',
    });
  }, [ca, id_empresa, nome, reset])

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);

    if (data.id_empresa) {
      setEmpresaIdSession(data.id_empresa);
    }
  }

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <Controller
                name="ca"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="CA do Produto"
                    fullWidth
                    type="number"
                    size="small"
                    inputProps={{ min: 0 }}
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="nome"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome do Produto"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="id_empresa"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Empresa"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    {empresas.length > 0 && empresas.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nomeFantasia}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={3} lg={4}>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
                  <Button
                    fullWidth
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={isLoadingList}
                    startIcon={isLoadingList ? <CircularProgress size={16} sx={{ color: 'textSecondary' }} /> : <FilterAlt />}
                  >
                    Filtrar
                  </Button>

                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<Restore />}
                    onClick={() => {
                      setSearchParams((state) => {
                        state.delete('nome')
                        state.delete('ca')
                        // state.delete('id_empresa') // dont delete id_empresa, because it saved in session
                        state.set('page', '1')

                        return state
                      })

                      reset({
                        nome: '',
                        ca: '',
                        id_empresa: id_empresa ?? '',
                      });
                    }}
                  >
                    Limpar Filtros
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </Grid >
  );
}
