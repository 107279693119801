import { Box, Stack, Typography } from '@mui/material';

export function Legend({ items }) {
  return (
    <Stack direction="row" mt={2} justifyContent='space-around' alignItems='center'>
      {items.map((item, index) => (
        <Stack key={index} direction="row" spacing={1} alignItems='center'>
          <Box sx={{ backgroundColor: item.color, width: 15, height: 15, borderRadius: 1 }}></Box>
          <Typography variant='caption'>{item.label}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
