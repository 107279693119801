import React from 'react';
import { Box, Stack } from '@mui/material';

export function RetiradaExtraStatusCell({ status }) {
  let color;
  switch (status) {
    case 'aceito':
      color = '#2196f3';
      break;
    case 'negado':
      color = 'red';
      break;
    case 'pendente':
      color = 'orange';
      break;
    case 'retirado':
      color = 'green';
      break;
    case 'inativado':
      color = '#ccc';
      break;
    default:
      color = '#ccc';
      break;
  }

  return (
    <Stack direction='row' spacing={1} gap={1} justifyContent='flex-start' alignItems='center'>
      <Box
        sx={{
          width: 14,
          height: 14,
          borderRadius: '50%',
          backgroundColor: color,
          border: '1px solid #f1f1f1'
        }}
      />
      {status ? status.charAt(0).toUpperCase() + status.slice(1) : 'N/A'}
    </Stack>
  );
};
