import {
  Typography,
  TableRow,
  TableCell,
  Switch,
  IconButton,
  Tooltip,
  Stack,
} from '@mui/material';
import { hasPermission } from '../../../../../configs/functions';
import {
  EditIcon,
  TrashIcon,
  FacialRecognitionIconChecked,
  FacialRecognitionIconWarning,
  LockIconChecked,
  LockIconWarning,
  FacialRecognitionIconError
} from '../../../../common/Icons';
import { AddModerator, LockReset, Visibility } from '@mui/icons-material';

export function CollaboratorRow({ user, handleOpenViewModal, handleOpenEditForm, handleUpdateStatus, handleOpenDeleteDialog, handleOpenDialogResetUser, handleOpenEditTurnAdminForm }) {
  const userIsPrimeiroAcesso = user?.pendencias.includes('primeiro_acesso');

  return (
    <TableRow key={user.id}>
      <TableCell>{user.matricula}</TableCell>
      <TableCell><Typography noWrap variant='body2'>{user?.nome}</Typography></TableCell>
      <TableCell>
        <Typography variant='subtitle2' key={user.empresa.id_empresa}>{user.empresa.fantasyName}</Typography>
      </TableCell>
      <TableCell>
        <Switch checked={!!user.status} onChange={handleUpdateStatus} disabled={!hasPermission(["admin", "admin_pessoas", "update_pessoas"])} />
      </TableCell>
      <TableCell align='right'>
        <Stack direction="row" justifyContent='space-around'>
          {user.biofaceIdentifier && user.biofaceIdentifier.includes('/9j/') ? (
            <Tooltip title="Biometria facial cadastrada" placement="top">
              <IconButton sx={{ cursor: 'default', width: 44 }}>
                <FacialRecognitionIconChecked />
              </IconButton>
            </Tooltip>
          ) : (
            // se existe biometria, mas a mesma não é uma imagem válida em base64, então exibir como erro
            !user.biofaceIdentifier
              ?
              <Tooltip title='Biometria facial pendente' placement="top">
                <IconButton onClick={() => handleOpenEditForm()} sx={{ width: 44 }}>
                  <FacialRecognitionIconWarning />
                </IconButton>
              </Tooltip>
              : <Tooltip title='Biometria facial incorreta' placement="top">
                <IconButton onClick={() => handleOpenEditForm()} sx={{ width: 44 }}>
                  <FacialRecognitionIconError />
                </IconButton>
              </Tooltip>
          )}

          {(!user.senha) || (user.senha && userIsPrimeiroAcesso) ? (
            <Tooltip title="Senha pendente" placement="top">
              <IconButton sx={{ cursor: 'default' }}>
                <LockIconWarning />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Senha cadastrada" placement="top">
              <IconButton sx={{ cursor: 'default' }}>
                <LockIconChecked />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      </TableCell>
      <TableCell sx={{ minWidth: 225 }} align='right'>
        {hasPermission(["admin", "admin_pessoas", "view_pessoas"]) &&
          <IconButton
            aria-label="Visualizar"
            onClick={() => handleOpenViewModal()}
          >
            <Visibility color="primary" />
          </IconButton>
        }

        {hasPermission(["admin", "admin_pessoas", "update_pessoas"]) &&
          <>
            <IconButton
              aria-label="Editar"
              onClick={() => handleOpenEditForm()}
            >
              <EditIcon />
            </IconButton>

            <Tooltip title={userIsPrimeiroAcesso ? 'Usuário ainda não cadastrou Credenciais' : 'Resetar Credenciais'}>
              <IconButton
                aria-label="Resetar Credenciais"
                onClick={() => handleOpenDialogResetUser(true)}
              >
                <LockReset color={userIsPrimeiroAcesso ? 'disabled' : 'primary'} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Alterar para Administrador">
              <IconButton
                aria-label="Alterar para Administrador"
                onClick={() => handleOpenEditTurnAdminForm()}
              >
                <AddModerator color="primary" />
              </IconButton>
            </Tooltip>
          </>
        }

        {hasPermission(["admin", "admin_pessoas", "delete_pessoas"]) &&
          <IconButton
            aria-label="Excluir"
            onClick={() => handleOpenDeleteDialog()}
            disabled={!user.status}
            sx={{ filter: !user.status ? "grayscale(1) brightness(1.4)" : "" }}
          >
            <TrashIcon />
          </IconButton>
        }
      </TableCell>
    </TableRow>
  )
}