import { TableRow, TableCell, Typography } from '@mui/material';

export function EmptyTableRow({ infoType = 'dado', colSpan = 6 }) {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        <Typography variant="body1">
          Nenhum {infoType} encontrado.
        </Typography>
      </TableCell>
    </TableRow>
  )
}