import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { DepartamentoIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import { atualizaDepartamento, deletaDepartamento, listaDepartamentos } from './configs/functions';
import { DepartmentForm } from './DepartmentForm';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { listaEmpresas } from '../companies/configs/functions';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';

const DepartmentRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_departamentos", "update_departamentos"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaDepartamento(data.id, { status: !active })
            if (res) {
                const message = active ? 'Departamento inativado com sucesso' : 'Departamento ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteDepartment = () => {
        const id = data.id;
        deletaDepartamento(id)
            .then((response) => {
                const message = response.data.message ?? 'Departamento excluído com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>{data.titulo}</TableCell>
                <TableCell>{data.codExterno}</TableCell>
                <TableCell>{data.coordenador.nome}</TableCell>
                <TableCell>{data.descricao}</TableCell>

                <TableCell>
                    <Switch checked={active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_departamentos", "update_departamentos"])} />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_departamentos", "update_departamentos"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    }

                    {hasPermission(["admin", "admin_departamentos", "delete_departamentos"]) &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir este Departamento?'}
                title={`Excluir Departamento ${data.nome}`}
                goAction={deleteDepartment}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function Departments() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [departamentos, setDepartamentos] = useState(null);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    const carregaDepartamentos = async () => {
        setIsLoading(true);
        try {
            const filterAllPaginated = {
                id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId],
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaDepartamentos(filterAllPaginated);
            setDepartamentos(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            console.error('Erro ao carregar Departamentos:', error);
            exibirAlerta('Ops', 'Erro ao carregar Departamentos, tente novamente mais tarde.', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function handleEditDepartment(item) {
        setSelectedDepartment(item);
        setOpenForm(true);
    }

    const updateDepartmentStatus = (categoryId, status) => setDepartamentos(
        departamentos.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaDepartamentos();
            }
            setSelectedDepartment(null);
        }
    }, [selectedEmpresaId, openForm, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_departamentos", "list_departamentos"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            }));
    }, []);

    return (<>
        <Helmet title="Departamentos" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to="/">
                        <HomeIcon fontSize="small" />
                    </LinkNavegacao>
                    <Typography variant='span'>Gerenciamento</Typography>
                    <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
                    <Typography variant='span'>Departamentos</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<DepartamentoIcon fontSize='large' />}
                    title="Departamentos"
                    description='Administre os departamentos da sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        id_empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />

                    {hasPermission(["admin", "admin_departamentos", "create_departamentos"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar departamento
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Departamentos">
                        <TableHead>
                            <TableRow>
                                <TableCell>Departamento</TableCell>
                                <TableCell>Cód. Externo</TableCell>
                                <TableCell>Coordenador(a)</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : departamentos && departamentos?.length > 0
                                    ? departamentos.map((item) =>
                                        <DepartmentRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditDepartment}
                                            handleUpdateStatus={updateDepartmentStatus}
                                            updateData={carregaDepartamentos}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <DepartmentForm
            open={openForm}
            setOpen={setOpenForm}
            selectedDepartment={selectedDepartment}
            empresas={empresas}
        />
    </>);
}

export default Departments;