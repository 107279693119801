import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import {
  Collapse,
  Card,
  Grid,
  TextField,
  MenuItem,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import { FilterAlt, Restore } from '@mui/icons-material';

export function ContractsTableFilter({ isOpenFilters, isLoadingList, empresas }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const nome_contrato = searchParams.get('nome_contrato');
  const mode_equipamento = searchParams.get('mode_equipamento');

  const perPage = searchParams.get('perPage');

  const { handleSubmit, reset, control } = useForm();

  useEffect(() => {
    reset({
      nome_contrato: nome_contrato ?? '',
      mode_equipamento: mode_equipamento ?? '',
    });
  }, [mode_equipamento, nome_contrato, reset])

  function handleSubmitFilter(data) {
    for (const key in data) {
      // remove campos vazios
      if (data[key] === '') {
        delete data[key];
      }
    }

    const queryFilters = {
      ...data,
      page: '1',
      perPage: perPage ?? '10'
    }

    // fill url params with filters
    setSearchParams(queryFilters);
  }

  return (
    <Grid item xs={12} component="form" onSubmit={handleSubmit(handleSubmitFilter)}>
      <Collapse in={isOpenFilters}>
        <Card variant="outlined" sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Controller
                name="nome_contrato"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    label="Nome do Contrato"
                    fullWidth
                    size="small"
                    {...field}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Controller
                name="mode_equipamento"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    select
                    label="Status do Equipamento"
                    fullWidth
                    size="small"
                    {...field}
                  >
                    <MenuItem value=''>Todos</MenuItem>
                    <MenuItem value='online'>Online</MenuItem>
                    <MenuItem value='offline'>Offline</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={3} lg={4}>
                <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} justifyContent={'space-between'}>
                  <Button
                    fullWidth
                    type='submit'
                    color='primary'
                    variant='contained'
                    disabled={isLoadingList}
                    startIcon={isLoadingList ? <CircularProgress size={16} sx={{ color: 'textSecondary' }} /> : <FilterAlt />}
                  >
                    Filtrar
                  </Button>

                  <Button
                    fullWidth
                    variant="outlined"
                    startIcon={<Restore />}
                    onClick={() => {
                      setSearchParams((state) => {
                        state.delete('nome_contrato')
                        state.delete('mode_equipamento')
                        state.set('page', '1')
                        return state
                      })

                      reset({
                        nome_contrato: '',
                        mode_equipamento: '',
                      });
                    }}
                  >
                    Limpar Filtros
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Collapse>
    </Grid >
  );
}
