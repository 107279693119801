import { useState, useEffect } from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form"
import { Helmet } from 'react-helmet-async';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    FormControl,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Email } from '@mui/icons-material';
import Logotipo from "../../../assets/images/stw_logo_preto.svg"
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { getFormErrorMessage } from '../../../configs/functions';

export function ForgotPassword() {
    const { exibirAlerta } = useCommonItems();

    const [disabledSubmitBtn, setDisabledSubmitBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(0);
    const [isResend, setIsResend] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = (data) => {
        setIsLoading(true);
        axios.post('/sendReset', data)
            .then(function (response) {
                exibirAlerta('Email enviado com sucesso!', 'Acesse sua caixa de email', 'success');
                setTimer(120); // timer de 2 minutos (120 seg.)
                setDisabledSubmitBtn(true);
                setIsResend(true);
                localStorage.setItem("@STW:forgot_password_email", data.email);
            })
            .catch(function (error) {
                const messageError = error.response?.data?.error ?? 'Erro na conexão com o servidor.';
                exibirAlerta(messageError, '', 'error')
            }).finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        let intervalId;

        if (timer > 0) {
            intervalId = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setDisabledSubmitBtn(false);
        }

        return () => clearInterval(intervalId); // Limpar intervalo ao desmontar
    }, [timer]);

    return (<>
        <Helmet title="Esqueceu a senha" defer={false} />

        <Container maxWidth={'sm'}>
            <Paper sx={{ p: 3, mt: 8, borderTopColor: "primary.main", borderTopStyle: 'solid', borderTopWidth: 8 }} elevation={4}>
                <Box
                    component={'img'}
                    src={Logotipo}
                    sx={{
                        height: 'auto',
                        width: '36%',
                        margin: "0 auto",
                        display: "block",
                        mb: 2
                    }}
                />

                <Typography variant='h2' component={'h1'} textAlign={'center'}>
                    Esqueceu a senha?
                </Typography>

                <Divider variant='middle' sx={{ my: 2 }} />

                <Typography p textAlign={'center'}>
                    Digite seu email e receba as instruções.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl variant="standard" fullWidth sx={{
                        px: {
                            xs: 1,
                            lg: 7
                        },
                        my: 3
                    }}>
                        <TextField
                            margin='dense'
                            placeholder='Insira seu email'
                            id="email"
                            name='email'
                            type='email'
                            error={!!errors.email}
                            helperText={getFormErrorMessage(errors, 'email')}
                            fullWidth
                            {...register("email", { required: true })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {timer > 0 &&
                            <Typography variant="body2" color="text.secondary" >
                                Você pode solicitar novamente em {' '}
                                <Typography variant="span" sx={{ color: "primary.main", fontWeight: 'bold' }}>
                                    {Math.floor(timer / 60)}:{timer % 60 < 10 ? `0${timer % 60}` : timer % 60}
                                </Typography>
                            </Typography>
                        }
                    </FormControl>

                    <Stack
                        sx={{ mt: 2 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Link to="/" style={{ textDecoration: 'none', color: '#000' }}>
                            <Button variant='contained' color='background'>
                                Voltar
                            </Button>
                        </Link>

                        <Button
                            variant='contained'
                            type='submit'
                            disabled={isLoading || disabledSubmitBtn}
                        >
                            {!isLoading
                                ? isResend ? 'Reenviar' : 'Enviar'
                                : <CircularProgress size={24} sx={{ color: '#fff' }} />
                            }
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </Container>
    </>);
}