import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { RequestService2Icon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, renderDay } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { RetiradaExtraRow } from './components/RetiradaExtraRow';
import { ApproveRetiradaExtraModal } from './components/ApproveRetiradaExtraModal';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { listaRetiradasExtra } from './functions';
import { PaginatedUserAutocomplete } from '../../../common/PaginatedUserAutocomplete';

export function ListOfRetiradasExtras() {
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [retiradasExtra, setRetiradasExtra] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const [pessoas, setPessoas] = useState([]);
  const [pagePessoas, setPagePessoas] = useState(0);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [hasMorePessoas, setHasMorePessoas] = useState(true);

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  async function loadReportData(id_empresas, id_pessoa, statusRetirada, data_inicio, data_fim) {
    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        data_inicio,
        data_fim,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };

      if (id_empresas === 'all') {
        filter.empresa = empresas.map((empresa) => empresa.id);
      } else {
        filter.id_empresas = [id_empresas];
      }

      if (id_pessoa) filter.id_pessoa = id_pessoa;
      if (statusRetirada) filter.status = statusRetirada;

      const res = await listaRetiradasExtra(filter);
      setRetiradasExtra(res.data.data);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  }

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      setPage(0);
      loadReportData(data.id_empresas, data.id_pessoa, data.statusRetirada, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  const listaColaboradores = async (currentPage = 0, initialPageValue = false, filterName = "") => {
    if (!selectedEmpresaId) return;

    if (initialPageValue) {
      setPessoas([]);
      setPagePessoas(0);
      setHasMorePessoas(true);
    }

    try {
      setIsLoadingPessoas(true);

      const filters = {
        id_empresa: selectedEmpresaId,
        nome: filterName,
        limit: 10,
        offset: (initialPageValue ? 0 : currentPage) * 10,
      };

      const response = await listaUsuariosFilter(filters);
      const newOptions = response.data.data;

      setPessoas((prevOptions) => [...prevOptions, ...newOptions]);
      setHasMorePessoas(newOptions.length > 0);
      setPagePessoas((prevPage) => prevPage + 1);
    } catch (error) {
      console.error("Erro ao carregar colaboradores:", error);
    } finally {
      setIsLoadingPessoas(false);
    }
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_retiradaExtra", "list_retiradaExtra"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    let defaultEmpresaId = null;
    const selectedEmpresaIdSession = getEmpresaIdSession();
    if (selectedEmpresaIdSession) {
      defaultEmpresaId = selectedEmpresaIdSession;
      setSelectedEmpresaId(selectedEmpresaIdSession);
    }

    listaEmpresas()
      .then((response => {
        exibirDialog('Carregando dados da Empresa...');
        const empresasData = response?.data.data;
        setEmpresas(empresasData);

        if (!selectedEmpresaIdSession) {
          defaultEmpresaId = empresasData[0].id;
          setSelectedEmpresaId(empresasData[0].id);
          setEmpresaIdSession(empresasData[0].id);
        }

        const defaultValues = {
          id_empresas: defaultEmpresaId,
          id_pessoa: '',
          statusRetirada: '',
          data_inicio: dayjs().subtract(1, 'month'),
          data_fim: dayjs(),
        };
        reset(defaultValues);

        loadReportData(defaultEmpresaId, null, null, defaultValues.data_inicio, defaultValues.data_fim);
      }))
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        handleCloseDialog();
      });

    return () => {
      reset();
    }
  }, []);

  useEffect(() => {
    listaColaboradores(0, true, "");
  }, [selectedEmpresaId]);

  useEffect(() => {
    if (!openForm) {
      const id_empresas = getValues('id_empresas');
      const id_pessoa = getValues('id_pessoa');
      const statusRetirada = getValues('statusRetirada');
      const data_inicio = getValues('data_inicio')
      const data_fim = getValues('data_fim')

      if (!id_empresas || !data_inicio || !data_fim) {
        return;
      }

      loadReportData(id_empresas, id_pessoa, statusRetirada, data_inicio, data_fim);
    }
  }, [page, rowsPerPage, openForm]);

  return (
    <>
      <Helmet title="Retiradas Extras" defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
            <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
            <LinkNavegacao to='/epis/retiradas'>Retiradas</LinkNavegacao>
            <Typography variant='span'>Retiradas Extras</Typography>
          </Breadcrumbs>
          <PageTitle icon={<RequestService2Icon fontSize='large' />} title="Retiradas Extras" />
        </Grid>

        <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container item xs={12} md={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <SelectEmpresaFilter
                empresas={empresas}
                id_empresas={empresas}
                defaultValue={selectedEmpresaId}
                enableOptionAll={true}
                onChangeValue={(value) => {
                  setIsSearched(false);
                  setValue('id_empresas', value, { shouldDirty: true });
                  setValue('id_pessoa', null, { shouldDirty: true })
                  if (value !== 'all') {
                    setSelectedEmpresaId(value);
                    setEmpresaIdSession(value);
                  } else {
                    setSelectedEmpresaId(null);
                    // setEmpresaIdSession(null);
                  }
                }}
                textHelper={false}
              />
            </Grid>

            {pessoas &&
              <Grid item xs={12} md={4}>
                <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                  <PaginatedUserAutocomplete
                    name="id_pessoa"
                    control={control}
                    label="Responsável Entrega"
                    disabled={!selectedEmpresaId}
                    options={pessoas}
                    getOptionLabel={(option) => {
                      // verify if the user exists in pessoas
                      const pessoa = pessoas.find(p => p.id === option);
                      return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                    }}
                    renderOption={(props, option) => (
                      <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                        {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                      </MenuItem>
                    )}
                    onChange={(event, value) => {
                      setIsSearched(false);
                      setValue('id_pessoa', value ? value.id : null, { shouldDirty: true });
                      if (value) {
                        clearErrors('id_pessoa')
                      } else {
                        listaColaboradores(0, true);
                      }
                    }}
                    onInput={(event) => {
                      setPessoas([]);
                      setPagePessoas(0);
                      setHasMorePessoas(true);
                      const filterValue = event.target.value ?? "";
                      listaColaboradores(0, true, filterValue);
                    }}
                    hasMore={hasMorePessoas}
                    loadOptionsFn={() => listaColaboradores(pagePessoas, false, "")}
                    helperText={getFormErrorMessage(errors, 'id_pessoa')}
                    error={!!errors.id_pessoa}
                    loading={isLoadingPessoas}
                    textFieldSize="small"
                    textFieldStyle={{ backgroundColor: '#fff' }}
                  />
                </TooltipSelectDisabled>
              </Grid>
            }

            <Grid item xs={12} md={4}>
              <Controller
                name="statusRetirada"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Status"
                    size="small"
                    onChange={(event) => {
                      setIsSearched(false);
                      field.onChange(event);
                    }}
                    InputProps={{ style: { backgroundColor: '#fff' } }}
                  >
                    <MenuItem value="">Todos</MenuItem>
                    <MenuItem value="aceito">Aceito</MenuItem>
                    <MenuItem value="negado">Negado</MenuItem>
                    <MenuItem value="pendente">Pendente</MenuItem>
                    <MenuItem value="retirado">Retirado</MenuItem>
                    <MenuItem value="inativado">Inativado</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={12} spacing={3}>
            <Grid item xs={12} md={4}>
              <DatePicker
                sx={{ width: '100%' }}
                label="Data de Início"
                size='small'
                format="DD/MM/YYYY"
                openTo="day"
                maxDate={dayjs()}
                dayOfWeekFormatter={renderDay}
                defaultValue={dayjs().subtract(1, 'month')}
                onChange={(data) => {
                  setIsSearched(false);
                  setValue('data_inicio', data, { shouldDirty: true })
                  if (data && data.isBefore(dayjs())) {
                    clearErrors('data_inicio');
                  }
                }}
                onError={(error) => {
                  if (error) {
                    const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                    setError("data_inicio", { type: "invalid", message: errorMessage })
                  }
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_inicio,
                    helperText: errors.data_inicio ? errors.data_inicio.message : null,
                    size: 'small',
                    style: {
                      backgroundColor: '#fff',
                      borderRadius: 4,
                    }
                  }
                }}
                TextField={(params) => (
                  <TextField
                    size='small'
                    autoComplete='off'
                    fullWidth
                    type="date"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <DatePicker
                sx={{ width: '100%' }}
                label="Data de Fim"
                size='small'
                format="DD/MM/YYYY"
                openTo="day"
                maxDate={dayjs().add(1, 'day')}
                dayOfWeekFormatter={renderDay}
                defaultValue={dayjs()}
                onChange={(data) => {
                  setIsSearched(false);
                  setValue('data_fim', data, { shouldDirty: true })
                  if (data && data.isBefore(dayjs().add(1, 'day'))) {
                    clearErrors('data_fim');
                  }
                }}
                onError={(error) => {
                  if (error) {
                    const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                    setError("data_fim", { type: "invalid", message: errorMessage })
                  }
                }}
                slotProps={{
                  textField: {
                    error: !!errors.data_fim,
                    helperText: errors.data_fim ? errors.data_fim.message : null,
                    size: 'small',
                    style: {
                      backgroundColor: '#fff',
                      borderRadius: 4,
                    }
                  },
                }}
                TextField={(params) => (
                  <TextField
                    size='small'
                    autoComplete='off'
                    fullWidth
                    type="date"
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disabled={isLoading || Object.keys(errors).length > 0 || isSearched}
                startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
              >
                Filtrar
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Retiradas Extra">
              <TableHead>
                <TableRow sx={{ display: 'flex' }}>
                  <TableCell sx={{ flex: 2 }}>Funcionário</TableCell>
                  <TableCell sx={{ flex: 2, minWidth: 160 }}>Produto</TableCell>
                  <TableCell sx={{ flex: 3 }}>Justificativa</TableCell>
                  <TableCell sx={{ flex: 1, minWidth: 115 }}>Status</TableCell>
                  <TableCell sx={{ flex: 2 }}>Responsável Entrega</TableCell>
                  <TableCell sx={{ flex: 2 }}>Data</TableCell>
                  <TableCell sx={{ flex: 1 }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading ? <LoadingTableRow />
                  : retiradasExtra && retiradasExtra?.length > 0
                    ? retiradasExtra.map((item) =>
                      <RetiradaExtraRow
                        key={item.id}
                        data={item}
                        handleOpenEditForm={(item) => {
                          setSelectedItem(item);
                          setOpenForm(true);
                        }}
                      />
                    )
                    : <EmptyTableRow />
                }
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <ApproveRetiradaExtraModal
        infoRetirada={selectedItem}
        open={openForm}
        setOpen={(event) => {
          setOpenForm(event)
          if (!event) setSelectedItem(null);
        }}
      />
    </>
  );
}