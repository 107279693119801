import { Grid, Typography } from '@mui/material';
import CompaniesCheckbox from '../../../../common/CompaniesCheckbox';

export function Step03({ empresasAssociadas, GHEs, register, getValues, setValue, errors, setError, clearErrors }) {
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h2" component={'h2'}>
          Cadastro Produto: {getValues('nome')}
        </Typography>

        <Typography variant="h6" component={'h6'} color="textSecondary" mt={1}>Empresas relacionadas a categoria selecionada:</Typography>
      </Grid>
      <Grid item xs={12}>
        <CompaniesCheckbox
          empresasAssociadas={empresasAssociadas}
          GHEs={GHEs}
          setValue={setValue}
          getValues={getValues}
          register={register}
          setError={setError}
          errors={errors}
          clearErrors={clearErrors}
        />
      </Grid>
    </Grid>
  );
};
