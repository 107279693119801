import { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { Button, Card, CardContent, CircularProgress, Grid, List, TextField, Typography } from '@mui/material';
import { green, grey, yellow } from '@mui/material/colors';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { getFormErrorMessage } from '../../../../configs/functions';
import { RelatoriosExcelImport } from '../configs/functions';
import { renderValue } from '../../epis/alerts/utils/formatDetailsAlert';

export function SpreadSheetImportStep({ file, activeStep, steps, isLoading, setIsLoading, createdIdEmpresa, setCreatedIdEmpresa }) {
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [insertedFileUpload, setInsertedFileUpload] = useState([]);
  const [duplicatesFileUpload, setDuplicatesFileUpload] = useState([]);
  const [errorsFileUpload, setErrorsFileUpload] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { reset, register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();

    for (const key in data) {
      if (data[key] === '') continue;

      if (Array.isArray(data[key])) {
        data[key].forEach((item, index) => {
          formData.append(`${key}[${index}]`, item);
        });
      } else {
        formData.append(key, data[key]);
      }
    }

    try {
      setIsLoading(true);
      exibirDialog('Enviando. Aguarde...');
      const response = await RelatoriosExcelImport(data);
      const { erros, duplicados, inseridos } = response.data;

      if (activeStep === 0) {
        const idEmpresa = response.data.inseridos.Empresa[1];
        setCreatedIdEmpresa(idEmpresa);
      }

      const currentModel = steps[activeStep];
      setErrorsFileUpload(prev => ({ ...prev, [activeStep]: erros[currentModel] || [] }));
      setDuplicatesFileUpload(prev => ({ ...prev, [activeStep]: duplicados[currentModel] || [] }));
      setInsertedFileUpload(prev => ({ ...prev, [activeStep]: inseridos[currentModel] || [] }));

      if (erros[currentModel]?.length > 0) {
        exibirAlerta('Erro', `Itens com Erro na aba ${currentModel}`, 'error');
      } else if (duplicados[currentModel]?.length > 0) {
        exibirAlerta('Duplicados', `Itens Duplicados na aba ${currentModel}`, 'warning');
      } else {
        exibirAlerta('Sucesso', `Aba ${currentModel} enviada com sucesso.`, 'success');
        setIsSubmitted(true);
      }
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data?.error || error.response?.data?.message || "Erro ao enviar Planilha.";
      exibirAlerta('Erro', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      handleCloseDialog();
    }
  };

  useEffect(() => {
    const defaultValues = {
      excel: file,
      model: steps[activeStep] || '',
      id_empresa: createdIdEmpresa || null,
    };
    reset(defaultValues);
  }, [activeStep, createdIdEmpresa, file, reset, steps]);

  useEffect(() => {
    setIsSubmitted(false);
  }, [activeStep]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <TextField
            InputLabelProps={{ shrink: true }}
            margin='dense'
            label='Model'
            placeholder='Model'
            disabled
            fullWidth
            {...register("model", { required: true })}
            error={!!errors.model}
            helperText={getFormErrorMessage(errors, 'model')}
          />
        </Grid>

        <Grid item xs={12} md={8}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={{ marginTop: 2 }}
            disabled={isLoading || isSubmitted}
            startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
          >
            Cadastrar Dados dessa aba
          </Button>
        </Grid>

        <Grid item xs={12} md={4}>
          <ResultsFileUploadCard
            title="Inseridos"
            items={insertedFileUpload[activeStep]}
            color={green[500]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ResultsFileUploadCard
            title="Duplicados"
            items={duplicatesFileUpload[activeStep]}
            color={yellow[700]}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <ResultsFileUploadCard
            title="Erros"
            items={errorsFileUpload[activeStep]}
            color="error"
          />
        </Grid>
      </Grid>
    </form>
  );
}

function ResultsFileUploadCard({ title, items, color, emptyMessage = 'Nenhum item encontrado' }) {
  return (
    <Card sx={{ marginTop: 1 }}>
      <CardContent>
        <Typography
          variant='h3'
          color={items?.length > 0 ? color : 'textSecondary'}
          sx={{ marginBottom: 1 }}
        >
          {title}
        </Typography>

        <List dense sx={{ listStyleType: 'circle', paddingTop: 0.2, paddingBottom: 0.5, paddingLeft: 1 }}>
          {items?.length > 0 ? (
            items.map((item, index) => (
              <Typography variant='body2' key={index} sx={{ marginBottom: 0.5 }}>
                {renderValue(item)}
              </Typography>
            ))
          ) : (
            <Typography variant='body2' color='textSecondary'>
              {emptyMessage}
            </Typography>
          )}
        </List>
      </CardContent>
    </Card>
  );
}
