import React from 'react';
import PageTitle from '../../../common/PageTitle';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import CardOption from '../../../common/CardOption';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import { ProtectiveGearAIcon, ProtectiveGearBIcon } from '../../../common/Icons';

function Products() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <Typography variant='span'>Produtos</Typography>
                </Breadcrumbs>
                <PageTitle icon={<ProtectiveGearAIcon fontSize='large' />} title='Produtos' />
                <Typography p>Nesta seção de Gestão de Produtos, cadastre, organize e adapte Equipamentos de Proteção Individual (EPIs). Detalhes, categorias, tamanhos e medidas, tudo em uma solução integrada para uma administração eficiente e personalizada.</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Cadastro de Produtos" description="Cadastre e gerencie EPIs com facilidade. Adicione detalhes como CA, nome, imagem e informações essenciais para um controle eficaz." url='listadeprodutos' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Categorias de Produtos" description="Organize seus EPIs de forma intuitiva. Crie e gerencie categorias para agrupar produtos relacionados, simplificando a busca e organização." url='listadecategorias' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Tamanhos e Variações" description="Registre tamanhos e variações dos EPIs. Simplifique o controle de estoque e a distribuição, garantindo o ajuste adequado para cada usuário." url='listadetamanhos' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Unidades de Medidas" description="Defina unidades para uma gestão precisa. Cadastre diferentes medidas como pares, peças ou caixas, adaptando-se às peculiaridades de seus EPIs" url='listadeunidadesdemedidas' />
            </Grid>
        </Grid>
    </>);
}

export default Products;