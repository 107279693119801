import { Typography, CircularProgress, Box, Skeleton, } from '@mui/material';
import { circularProgressClasses } from '@mui/material/CircularProgress';
import { green, red, yellow } from '@mui/material/colors';

export function ProgressCounter({ value, limit, isLoading, ...props }) {
    return (
        <Box sx={{ position: 'relative' }}>
            <CircularProgress
                variant="determinate"
                sx={{ color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800] }}
                size={60}
                thickness={4}
                value={100}
                {...props}
            />

            <Box>
                <CircularProgress
                    variant='determinate'
                    value={value > limit ? 100 : (value / limit) * 100}
                    disableShrink
                    sx={{
                        color: value >= limit ? red[500] : value > (limit * 3 / 4) ? yellow[700] : green[500],
                        animationDuration: '550ms',
                        [`& .${circularProgressClasses.circle}`]: {
                            strokeLinecap: 'round',
                        },
                        top: 0,
                        left: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    size={60}
                    thickness={4}
                    {...props}
                />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 5,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {isLoading ? (
                            <Skeleton variant="text" width={40} />
                        ) : (
                            <>{value}/{limit}</>
                        )}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
}
