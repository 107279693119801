import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import SemFoto from '../../assets/images/produto_sem_foto.svg';

function ProductImageUpload({ getValues, setValue }) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [imgError, setImgError] = useState(false);

    useEffect(() => {
        // Se o valor da imagem estiver presente no registro, define-a como a imagem selecionada
        const storedImage = getValues('productImage');
        if (storedImage) {
            if (typeof storedImage === 'string') {
                setSelectedImage(storedImage);
            } else if (storedImage instanceof File) {
                setSelectedImage(URL.createObjectURL(storedImage));
            }
        }
    }, [getValues]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (e) => {
                setSelectedImage(e.target.result);
                setValue('productImage', file); // Adiciona a imagem ao react-hook-form
                setValue('deleteImg', false); // Define que a imagem não deve ser deletada
            };

            reader.readAsDataURL(file);
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setValue('productImage', null);
        setValue('deleteImg', true);
        setImgError(false);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
            <Box
                component={'img'}
                src={(selectedImage && !imgError) ? selectedImage : SemFoto}
                sx={{
                    width: '90%',
                    height: 'auto',
                    maxHeight: 300,
                    borderRadius: 2,
                    objectFit: 'cover',
                }}
                onError={() => setImgError(true)}
            />
            {selectedImage ? (
                <Button onClick={handleRemoveImage} variant="outlined">
                    Remover Imagem
                </Button>
            ) : (
                <>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        id="productImage"
                        style={{ display: 'none' }}
                    />
                    <label htmlFor="productImage">
                        <Button variant="outlined" component="span">
                            Adicionar Imagem
                        </Button>
                    </label>
                </>
            )}
        </Box>
    );
}

export default ProductImageUpload;
