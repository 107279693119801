import { Link } from 'react-router-dom';

function LinkNavegacao({ children, to }) {
    return (
        <Link to={to} style={{
            color: 'inherit'
        }}>
            {children}
        </Link>
    );
}

export default LinkNavegacao;