import { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaJustificativaDevolucao } from './config/functions';
import { getDefaultValueSelectField } from '../../../../utils';

export function JustificativaDevolucaoForm({ open, setOpen, selectedJustificativa, empresas }) {
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const [isLoading, setIsLoading] = useState(false);

  const isEditAction = !!selectedJustificativa;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const onSubmit = async (data) => {
    let formDataToSend = {};
    setIsLoading(true);
    exibirDialog('Salvando. Aguarde...');

    if (isEditAction) {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      formDataToSend = dirtyData;

      formDataToSend.id = selectedJustificativa.id;

      if (dirtyData?.id_empresas) {
        // no caso de edição, o campo é id_empresa
        formDataToSend.id_empresa = dirtyData.id_empresas;
        delete formDataToSend.id_empresas;
      }
    } else {
      delete data.id;
      formDataToSend = data;
      formDataToSend.id_empresas = [data.id_empresas];
    }

    try {
      const res = await manipulaJustificativaDevolucao(formDataToSend, isEditAction);
      exibirAlerta('Sucesso', res.data.message, 'success');
      setOpen(false);
    } catch (error) {
      console.error(error);
      let errorMessage = "Erro ao cadastrar Justificativa.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      handleCloseDialog();
    }
  };

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: selectedJustificativa ? selectedJustificativa.id : '',
        nome: selectedJustificativa ? selectedJustificativa.nome : '',
        status: selectedJustificativa ? selectedJustificativa.status : 1,
        descricao: selectedJustificativa ? selectedJustificativa.descricao : '',
        observacao: selectedJustificativa ? selectedJustificativa.observacao : '',
        id_empresas: getDefaultValueSelectField(selectedJustificativa, selectedJustificativa?.empresa?.id, empresas),
      };
      reset(defaultValues);
    } else {
      reset();
    }
  }, [empresas, open, reset, selectedJustificativa]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Justificativa <strong>{selectedJustificativa.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Justificativa</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Justificativa.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nome'
                    placeholder='Digite o nome do Justificativa'
                    name='nome'
                    fullWidth
                    {...register("nome", { required: true })}
                    error={!!errors.nome}
                    helperText={getFormErrorMessage(errors, 'nome')}
                    variant="outlined"
                  />
                </Grid>

                {empresas &&
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='id_empresas'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={selectedJustificativa ? selectedJustificativa.id_empresas : ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Empresa"
                          onChange={(e) => {
                            setValue('id_empresas', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors("id_empresas");
                            }
                          }}
                          error={!!errors.id_empresas}
                          helperText={getFormErrorMessage(errors, 'id_empresas')}
                        >
                          {empresas.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.nomeFantasia}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao")}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Observação'
                    placeholder='Digite a Observação'
                    name='observacao'
                    fullWidth
                    {...register("observacao")}
                    variant="outlined"
                    multiline
                    rows={2}
                    error={!!errors.observacao}
                    helperText={getFormErrorMessage(errors, 'observacao')}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}