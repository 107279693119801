import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
    Box,
    Tooltip,
    CircularProgress,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { EditIcon, NotificationIcon } from '../../../common/Icons';
import { atualizaConfigAlarme, listaConfigAlertas, sendEmailAlerta } from './configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { ConfigAlertForm } from './ConfigAlertForm';
import { ForwardToInboxTwoTone, Info } from '@mui/icons-material';
import { blue } from '@mui/material/colors';

const ConfigAlertRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus }) => {
    const [active, setActive] = useState(data.status);
    const [isSendingEmail, setIsSendingEmail] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_configAlarme", "update_configAlarme"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaConfigAlarme({ id: data.id, status: !active })
            if (res) {
                const message = active ? 'Alarme inativado com sucesso' : 'Alarme ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const handleTestSendEmail = async (data) => {
        try {
            console.log(data);
            setIsSendingEmail(true);
            // const idAlarme = data.alarme.id;
            const idConfigAlarme = data.id;
            const res = await sendEmailAlerta(idConfigAlarme);
            if (res) {
                handleExibirAlerta('Sucesso', 'E-mail de teste enviado com sucesso', 'success');
            }
        }
        catch (error) {
            handleExibirAlerta('Ops', 'Erro ao enviar e-mail de teste', 'error');
            console.log(error);
        } finally {
            setIsSendingEmail(false);
        }
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>
                    <Stack direction='row' spacing={1} justifyContent='flex-start' gap={2} alignItems='center'>
                        <Box
                            sx={{
                                width: 18,
                                height: 18,
                                minWidth: 18,
                                minHeight: 18,
                                borderRadius: '50%',
                                backgroundColor: data.hexadecimalColor ? data.hexadecimalColor : 'transparent', border: '1px solid #ccc'
                            }}
                        />

                        {data.alarme.tipo}

                        <Tooltip title={data.alarme.descricao} placement="top">
                            <IconButton>
                                <Info sx={{ color: blue[500], cursor: 'pointer' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </TableCell>

                <TableCell>{data.tipoAtivacao[0].toUpperCase() + data.tipoAtivacao.slice(1).toLowerCase()}</TableCell>

                <TableCell>
                    {data.emailResponsavel.length <= 3 ? (
                        data.emailResponsavel.map((email, index) => (
                            <Typography key={index} variant="subtitle2">
                                {email}
                            </Typography>
                        ))
                    ) : (
                        <Tooltip
                            title={
                                <>
                                    {data.emailResponsavel.map((email, index) => (
                                        <Typography key={index} variant="body2">{email}</Typography>
                                    ))}
                                </>
                            }
                            placement="bottom"
                        >
                            <Box sx={{ cursor: 'pointer' }}>
                                {data.emailResponsavel.slice(0, 3).map((email, index) => (
                                    <Typography key={index} variant='subtitle2'>{email}</Typography>
                                ))}
                                <Typography variant='subtitle2'>+{data.emailResponsavel.length - 3}</Typography>
                            </Box>
                        </Tooltip>
                    )}
                </TableCell>

                <TableCell>
                    <Switch checked={active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_configAlarme", "update_configAlarme"])} />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 150 }}>
                    {hasPermission(["admin", "admin_configAlarme", "update_configAlarme"]) &&
                        <Tooltip title="Editar Configuração" placement="top">
                            <IconButton
                                aria-label="Editar"
                                onClick={() => handleOpenEditForm(data)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                    }

                    <Tooltip title="Enviar e-mail de teste" placement="top">
                        <IconButton
                            aria-label="Teste de envio de e-mail"
                            onClick={() => handleTestSendEmail(data)}
                            disabled={isSendingEmail}
                        >
                            {isSendingEmail
                                ? <CircularProgress size={20} />
                                : <ForwardToInboxTwoTone color='primary' htmlColor='primary' />
                            }
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
        </>
    )
}

export function ConfigAlerts() {
    const { exibirAlerta } = useCommonItems();
    const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [configAlarmes, setConfigAlarmes] = useState([]);
    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedItem, setSelectedItem] = useState(null);

    const carregaAlertas = async () => {
        setIsLoading(true);
        try {
            const filterAllPaginated = {
                id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId],
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaConfigAlertas(filterAllPaginated);
            setConfigAlarmes(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar Alarmes', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function handleEdit(item) {
        setSelectedItem(item);
        setOpenForm(true);
    }

    const updateAlertStatus = (itemId, status) => setConfigAlarmes(
        configAlarmes.map((item) => {
            if (item.id === itemId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaAlertas();
            }
            setSelectedItem(null);
        }
    }, [selectedEmpresaId, openForm, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_configAlarme", "list_configAlarme"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            }));
    }, []);

    return (<>
        <Helmet title="Configuração dos Alarmes" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <LinkNavegacao to='/epis/alertas'>Alertas</LinkNavegacao>
                    <Typography variant='span'>Configuração dos Alarmes</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<NotificationIcon fontSize='large' />}
                    title='Configuração dos Alarmes'
                    description='Administre os alarmes da sua(s) empresa(s).'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        id_empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Alarmes">
                        <TableHead>
                            <TableRow>
                                <TableCell>Alarme</TableCell>
                                <TableCell>Tipo Disparo</TableCell>
                                <TableCell>Destinatários</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : configAlarmes && configAlarmes?.length > 0
                                    ? configAlarmes.map((item) =>
                                        <ConfigAlertRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEdit}
                                            handleUpdateStatus={updateAlertStatus}
                                            updateData={carregaAlertas}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <ConfigAlertForm
            open={openForm}
            setOpen={setOpenForm}
            selectedConfig={selectedItem}
            contratoId={dadosUsuario.id_contrato}
        />
    </>);
}