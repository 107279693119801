import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AlertTitle, Box, Dialog, DialogTitle } from '@mui/material';
import AnimatedProgress from '../components/common/AnimatedProgress';

const CommonItensContext = createContext();

const CommonItensProvider = ({ children }) => {
    const [snackbarState, setSnackbarState] = useState({
        open: false,
        title: '',
        message: '',
        type: 'success',
    });

    const [dialog, setDialog] = useState({
        open: false,
        message: '',
    });

    const exibirAlerta = useCallback((title, message = '', type = 'success') => {
        setSnackbarState({ open: true, title, message, type });
    }, []);

    const setAlertInLocalStorage = (title, message, type) => {
        localStorage.setItem("@STW:alert", JSON.stringify({ title, message, type }));
    }

    const handleCloseSnackbar = (_event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarState((prevState) => ({ ...prevState, open: false }));
    };

    const exibirDialog = (message) => {
        setDialog({ open: true, message });
    };

    const handleCloseDialog = () => {
        setDialog((prevState) => ({ ...prevState, open: false }));
    };

    const contextValue = {
        exibirAlerta,
        setAlertInLocalStorage,
        exibirDialog,
        handleCloseDialog
    };

    // verificar se existe um alerta no localStorage
    useEffect(() => {
        const alert = JSON.parse(localStorage.getItem("@STW:alert"));
        if (alert) {
            exibirAlerta(alert.title, alert.message, alert.type);
            localStorage.removeItem("@STW:alert");
        }
    }, []);

    return (
        <CommonItensContext.Provider value={contextValue}>
            {children}
            <Dialog open={dialog.open}>
                <DialogTitle>{dialog.message}</DialogTitle>
            </Dialog>
            <Snackbar
                open={snackbarState.open}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Box sx={{ boxShadow: 3 }} >
                    <Alert
                        severity={snackbarState.type}
                        onClose={handleCloseSnackbar}
                        sx={{ py: 2, borderRadius: 0 }}
                    >
                        <AlertTitle sx={{ mb: snackbarState.message ? 1 : 0 }}>{snackbarState.title}</AlertTitle>
                        {snackbarState.message && snackbarState.message}
                    </Alert>
                    <AnimatedProgress duration={6000} />
                </Box>
            </Snackbar>
        </CommonItensContext.Provider>
    );
};

const useCommonItems = () => {
    const context = useContext(CommonItensContext);

    if (!context) {
        throw new Error('useCommonItems must be used within an CommonItensProvider');
    }

    return context;
};

export { CommonItensProvider, useCommonItems };