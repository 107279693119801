import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { Box, Button, CircularProgress, Container, Divider, FormControl, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material';
import Logotipo from "../../../assets/images/stw_logo_preto.svg"
import { AccountCircle, Lock } from '@mui/icons-material';
import { grey } from '@mui/material/colors';
import { useAppContext } from '../../../contexts/AppContext';
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { getUA } from 'react-device-detect';
import { useForm } from "react-hook-form"
import axios from 'axios'
import os from 'os-browserify'
import { urlLogin } from '../../../constants/endpoints';
import useReCaptchaV3 from '../../../hooks/reCaptchaV3';


function Signin() {
    const executeReCaptcha = useReCaptchaV3(process.env.REACT_APP_RECAPTCHA_SITE_KEY);

    const { updateDadosUsuarioLocalStorage } = useAppContext();
    const { exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            email: "",
            senha: "",
            device_name: `${os.hostname()}|${os.platform()}|${(getUA)}}`
        }
    })

    const onSubmit = async (data) => {
        try {
            setIsLoading(true);
            const recaptchaToken = await executeReCaptcha('login');

            if (!recaptchaToken) {
                exibirAlerta('Erro durante a validação do reCaptcha.', '', 'error');
                setIsLoading(false);
                return;
            }

            data.grecaptcha = recaptchaToken;
            const response = await axios.post(urlLogin, data);
            updateDadosUsuarioLocalStorage(response.data);

            removeRecaptchaWidget();
        } catch (error) {
            if (error?.response?.data?.erro?.grecaptcha) {
                exibirAlerta('Erro durante a validação do reCaptcha.', '', 'error');
                return;
            }

            const messageError = error?.response ? 'E-mail ou senha inválidos.' : 'Erro na conexão com o servidor.';
            exibirAlerta(messageError, '', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    // remover o widget do reCAPTCHA
    const removeRecaptchaWidget = () => {
        const recaptchaBadge = document.querySelector('.grecaptcha-badge');
        if (recaptchaBadge) {
            recaptchaBadge.style.display = 'none';
        }

        const recaptchaScript = document.getElementById('recaptcha');
        if (recaptchaScript) {
            document.body.removeChild(recaptchaScript);
        }
    };

    useEffect(() => {
        return () => {
            removeRecaptchaWidget();
        };
    }, []);

    return (<>
        <Helmet title="Login" defer={false} />

        <Container maxWidth={'sm'}>
            <Paper sx={{ p: 3, mt: 8, borderTopColor: "primary.main", borderTopStyle: 'solid', borderTopWidth: 8 }} elevation={4}>
                <Box
                    component={'img'}
                    src={Logotipo}
                    sx={{
                        height: 'auto',
                        width: '36%',
                        margin: "0 auto",
                        display: "block",
                        mb: 2
                    }}
                    loading='lazy'
                />

                <Typography variant='h2' component={'h1'} textAlign={'center'}>Sistema de Gestão de Segurança do Trabalho</Typography>
                <Divider variant='middle' sx={{ my: 2 }} />
                <Typography p>Seja bem-vindo! <br />Informe seu usuário e senha para ter acesso ao sistema.</Typography>


                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl variant="standard" fullWidth sx={{
                        px: {
                            xs: 1,
                            lg: 7
                        }, my: 3
                    }}>
                        <TextField
                            margin='dense'
                            placeholder='Informe seu usuário'
                            id="email"
                            name='email'
                            error={errors.email && true}
                            helperText={errors.email && 'O usuário é obrigatório.'}
                            fullWidth
                            {...register("email", { required: true })}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AccountCircle />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            sx={{ mb: 4 }}
                        />

                        <TextField
                            margin='dense'
                            placeholder='Informe sua senha'
                            id="senha"
                            name='senha'
                            error={errors.senha && true}
                            helperText={errors.senha && 'A senha é obrigatória.'}
                            fullWidth
                            {...register("senha", { required: true })}
                            InputProps={{
                                type: 'password',
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>
                                ),
                            }}
                            variant="standard"
                            sx={{ mb: 4 }}
                        />
                        <input type='hidden'  {...register("device_name")} />
                    </FormControl>

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={1}
                        divider={<Divider orientation="vertical" flexItem />}
                    >
                        <Link to="/esqueceu-senha" style={{ textDecoration: 'none', color: '#000' }}>
                            <Typography variant='caption'>
                                Esqueci minha senha
                            </Typography>
                        </Link>

                        <Button
                            type='submit'
                            variant='contained'
                            disabled={Object.keys(errors).length > 0 || isLoading}
                            startIcon={isLoading && <CircularProgress size={16} sx={{ color: grey[400] }} />}
                        >
                            Acessar
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </Container>
    </>);
}

export default Signin;