import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { useForm } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaUnidadesDeMedida } from './configs/functions';
import { useAppContext } from '../../../../contexts/AppContext';

export function UnitsOfMeasureForm({ open, setOpen, selectedUnit }) {
    const { dadosUsuario } = useAppContext();
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);

    const isEditAction = !!selectedUnit;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedUnit.id;
        } else {
            formDataToSend = data;
            formDataToSend.id_contratos = [dadosUsuario.id_contrato];
        }

        try {
            const res = await manipulaUnidadesDeMedida(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Unidade de Medida.";
            if (error.response) {
                errorMessage = error.response.data.error
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            const defaultValues = {
                id: selectedUnit ? selectedUnit.id : '',
                nome: selectedUnit ? selectedUnit.nome : '',
                status: selectedUnit ? selectedUnit.status : 1,
                descricao: selectedUnit ? selectedUnit.descricao : '',
                observacao: selectedUnit ? selectedUnit.observacao : '',
                id_contratos: selectedUnit ? selectedUnit.contrato.id : '',
            };
            reset(defaultValues);
        } else {
            reset();
        }
    }, [open, reset]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Unidade de Medida: <strong>{selectedUnit.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar nova Unidade de Medida</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar uma nova Unidade de Medida.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Nome'
                                        placeholder='Digite o nome da Unidade de Medida'
                                        name='nome'
                                        fullWidth
                                        {...register("nome", { required: true })}
                                        error={!!errors.nome}
                                        helperText={getFormErrorMessage(errors, 'nome')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Observação'
                                        placeholder='Digite a Observação'
                                        name='observacao'
                                        fullWidth
                                        {...register("observacao")}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!errors.observacao}
                                        helperText={getFormErrorMessage(errors, 'observacao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}