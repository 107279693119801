import { Box, Container, Grid, useTheme, } from '@mui/material';
import PrimaryAppBar from '../components/common/AppBar';
import GeneralMenu from '../components/common/GeneralMenu';
import { useAppContext } from '../contexts/AppContext';

export function AppLayout({ children }) {
  const theme = useTheme();
  const { drawerOpen } = useAppContext();

  return (
    <Container disableGutters maxWidth={false} sx={{ maxWidth: 1920 }}>
      <PrimaryAppBar />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={{ xs: 2, md: 3 }}
        sx={{ mt: { xs: 5, md: 4 }, paddingLeft: { xs: 2, md: 0 }, width: { xs: '100%', md: 'auto' } }}
      >
        <Grid
          item
          xs={12}
          lg={drawerOpen ? 3 : 0.6}
          xl={drawerOpen ? 2 : 0.6}
          sx={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            })
          }}
        >
          <GeneralMenu />
        </Grid>

        <Grid
          item
          xs={12}
          lg={drawerOpen ? 9 : 11.4}
          xl={drawerOpen ? 10 : 11.4}
          sx={{
            transition: theme.transitions.create("all", {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen
            }),
            mt: {
              xs: 0,
              md: 3,
              lg: 4
            },
            pr: {
              xs: 0,
              md: 2,
              lg: 3
            },
            mx: {
              xl: 'auto'
            }
          }}
        >
          <Box sx={{ pl: { md: 2, lg: 2, }, pb: 3 }}>
            {children}
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
