import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    FormControlLabel,
    Tooltip,
    IconButton,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    InputAdornment,
    Typography,
    Checkbox,
    Stack,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { Info as InfoIcon, Notifications as NotificationsIcon } from '@mui/icons-material';
import { blue, green, grey } from '@mui/material/colors';
import { listaTamanhosEVariacoes, manipulaCategoria } from "./configs/functions"
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { categoryIcons } from '../../../../configs/constants';

function CategoryForm({ open, setOpen, category, id_empresas }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState(null);
    const [variacoes, setVariacoes] = useState([]);

    const isEditAction = !!category;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const carregaVariacoes = async () => {
        try {
            exibirDialog('Carregando Variações. Aguarde...');
            const res = await listaTamanhosEVariacoes();
            setVariacoes(res.data.data);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar Variações', 'error');
        } finally {
            handleCloseDialog();
        }
    }

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            // se for edição, pegar o id categoria (vem como array)
            formDataToSend.id_categoria = category.categorias_id[0];
        } else {
            delete data.id_categoria;
            formDataToSend = data;
        }

        formDataToSend.icon_name = selectedIcon;

        manipulaCategoria(formDataToSend, isEditAction)
            .then((res) => {
                exibirAlerta('Sucesso', res.data.message, 'success');
                setOpen(false)
            })
            .catch((error) => {
                console.error(error);
                let errorMessage = "Erro ao cadastrar categoria";
                if (error.response) {
                    errorMessage = error.response.data.message
                    fillInputErrors(error, setError);
                }
                exibirAlerta('Erro', errorMessage, 'error');
            }).finally(() => {
                setIsLoading(false);
                handleCloseDialog();
            });
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            // set default values to form
            const defaultValues = {
                id_categoria: category ? category.categorias_id[0] : '',
                nome: category ? category.nome : '',
                status: category ? category.status : 1,
                descricao: category ? category.descricao : '',
                observacao: category ? category.observacao : '',
                quantidade_maxima: category ? category.quantidade_maxima : 1,
                periodo: category ? category.periodo : 1,
                ponto_alerta: category ? category.ponto_alerta : 1,
                id_empresas: category ? category.empresas_id : [],
                id_variacoes: category ? category.variacoes_id : [],
            };
            reset(defaultValues);
            setSelectedIcon(category ? category.icon_name : null);
        } else {
            // limpa o formulário ao sair
            reset();
            setSelectedIcon(null);
        }
    }, [open, reset]);

    useEffect(() => {
        carregaVariacoes();
    }, []);

    return (<>
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando categoria <strong>{category.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar nova categoria</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar uma nova categoria.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12}>
                                    <TextField
                                        autoFocus
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Nome'
                                        placeholder='Digite o nome da Categoria'
                                        name='nome'
                                        fullWidth
                                        {...register("nome", { required: true })}
                                        error={!!errors.nome}
                                        helperText={getFormErrorMessage(errors, 'nome')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Observação'
                                        placeholder='Digite a Observação'
                                        name='observacao'
                                        fullWidth
                                        {...register("observacao")}
                                        variant="outlined"
                                        multiline
                                        rows={2}
                                        error={!!errors.observacao}
                                        helperText={getFormErrorMessage(errors, 'observacao')}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        label="Quantidade Máxima"
                                        fullWidth
                                        margin='dense'
                                        name='quantidade_maxima'
                                        type='number'
                                        inputProps={{ min: 1 }}
                                        {...register("quantidade_maxima", { required: true })}
                                        helperText={getFormErrorMessage(errors, 'quantidade_maxima')}
                                        error={!!errors.quantidade_maxima}
                                        // helperText="Quantidade de EPIs que podem ser usados/entregues"
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position="end">
                                                    <Tooltip title="Quantidade de EPIs que podem ser usados/entregues" placement="top">
                                                        <InfoIcon sx={{ color: blue[500], cursor: 'pointer' }} />
                                                    </Tooltip>
                                                </InputAdornment>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        margin='dense'
                                        label='Período de uso'
                                        name='periodo'
                                        fullWidth
                                        {...register("periodo", { required: true })}
                                        type='number'
                                        inputProps={{ min: 1 }}
                                        helperText={getFormErrorMessage(errors, 'periodo')}
                                        error={!!errors.periodo}
                                        // helperText="Período em dias que os EPIs podem ser usados/entregues."
                                        InputProps={{
                                            endAdornment:
                                                <>
                                                    <Typography sx={{ mx: 1 }}>
                                                        dia(s)
                                                    </Typography>

                                                    <InputAdornment position="end">
                                                        <Tooltip title="Período em dias que os EPIs podem ser usados/entregues." placement="top">
                                                            <InfoIcon sx={{ color: blue[500], cursor: 'pointer' }} />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                </>,
                                        }}
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        fullWidth
                                        type='number'
                                        margin='dense'
                                        label='Ponto de Alerta'
                                        name='ponto_alerta'
                                        inputProps={{ min: 0 }}
                                        {...register("ponto_alerta", { required: true })}
                                        error={!!errors.ponto_alerta}
                                        helperText={getFormErrorMessage(errors, 'ponto_alerta')}
                                        // helperText="Quantidade de dias que avisa antes do vencimento do produto."
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                    <NotificationsIcon sx={{ color: grey[500] }} />
                                                </InputAdornment>,
                                            endAdornment:
                                                <>
                                                    <Typography sx={{ mx: 1 }}>
                                                        dia(s)
                                                    </Typography>

                                                    <InputAdornment position="end">
                                                        <Tooltip title="Quantidade de dias que avisa antes do vencimento do produto." placement="top">
                                                            <InfoIcon sx={{ color: blue[500], cursor: 'pointer' }} />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                </>,
                                        }}
                                    />
                                </Grid>

                                {/* <CompaniesCheckbox register={register} setValue={setValue} getValues={getValues} /> */}
                                {(!isEditAction && id_empresas) &&
                                    <Grid container item>
                                        <Grid item xs={12} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                            <Stack direction='row' spacing={1} alignItems='center' flexWrap="wrap">
                                                <Typography color={errors.id_empresas && 'error'}>
                                                    Empresas Relacionadas
                                                </Typography>

                                                <Typography component="span" color={grey[700]}>
                                                    - Selecione quais empresas essa categoria irá pertencer
                                                </Typography>
                                            </Stack>

                                            {errors.id_empresas && (
                                                <Typography variant="subtitle2" component="caption" color='error'>
                                                    - {getFormErrorMessage(errors, 'id_empresas')}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid container py={1} item xs={12}>
                                            <Controller
                                                name='id_empresas'
                                                control={control}
                                                defaultValue={[]}
                                                rules={{ validate: value => value.length > 0 || "Selecione no mínimo uma empresa." }}
                                                render={({ field: { onChange, value } }) => (
                                                    id_empresas.map((empresa) => (
                                                        <Grid item xs={12} md={6} key={empresa.id}>
                                                            <FormControlLabel
                                                                label={
                                                                    <>
                                                                        <Typography>
                                                                            {empresa.nomeFantasia}

                                                                            <Typography component="span" style={{ color: '#7a7a7a', marginLeft: '0.2rem' }}>
                                                                                ({empresa.tipo.tituloTipo})
                                                                            </Typography>
                                                                        </Typography>
                                                                    </>
                                                                }
                                                                control={
                                                                    <Checkbox
                                                                        defaultChecked={category && category?.empresas_id.includes(empresa.id)}
                                                                        onChange={(e, isChecked) => {
                                                                            let updatedEmpresas = [...value];
                                                                            if (isChecked) {
                                                                                updatedEmpresas.push(empresa.id);
                                                                            } else {
                                                                                updatedEmpresas = updatedEmpresas.filter((id) => id !== empresa.id);
                                                                            }
                                                                            setValue('id_empresas', updatedEmpresas, { shouldDirty: true });
                                                                            onChange(updatedEmpresas);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                            />
                                        </Grid>

                                        <DialogContentText sx={{ mt: 1, color: grey[700] }}>
                                            Será criada uma nova categoria para cada empresa selecionada.
                                        </DialogContentText>
                                    </Grid>
                                }

                                {(variacoes.length > 0) &&
                                    <Grid container item>
                                        <Grid item xs={12} sx={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
                                            <Stack direction='row' spacing={1} alignItems='center' flexWrap="wrap">
                                                <Typography color={errors.id_variacoes && 'error'}>
                                                    Variações Relacionadas
                                                </Typography>

                                                <Typography component="span" color={grey[700]}>
                                                    - Selecione as variações
                                                </Typography>
                                            </Stack>

                                            {errors.id_variacoes && (
                                                <Typography variant="subtitle2" component="caption" color='error'>
                                                    - {getFormErrorMessage(errors, 'id_variacoes')}
                                                </Typography>
                                            )}
                                        </Grid>

                                        <Grid container py={1} item xs={12}>
                                            <Controller
                                                name='id_variacoes'
                                                control={control}
                                                defaultValue={[]}
                                                render={({ field: { onChange, value } }) => (
                                                    variacoes.map((item) => (
                                                        <Grid item xs={6} md={4} key={item.id}>
                                                            <FormControlLabel
                                                                label={item.tipo + (item.status == 0 ? ' - (Inativa)' : '')}
                                                                control={
                                                                    <Checkbox
                                                                        disabled={item.status == 0}
                                                                        defaultChecked={category && category?.variacoes_id.includes(item.id)}
                                                                        onChange={(e, isChecked) => {
                                                                            let selectedVariacoesId = [...value];
                                                                            if (isChecked) {
                                                                                selectedVariacoesId.push(item.id);
                                                                            } else {
                                                                                selectedVariacoesId = selectedVariacoesId.filter((id) => id !== item.id);
                                                                            }
                                                                            setValue('id_variacoes', selectedVariacoesId, { shouldDirty: true });
                                                                            onChange(selectedVariacoesId);
                                                                        }}
                                                                    />
                                                                }
                                                            />
                                                        </Grid>
                                                    ))
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                }

                                <Grid container item xs={12}>
                                    <Grid item xs={12}>
                                        <Stack direction='row' spacing={1} alignItems='center' sx={{ mb: 1 }}>
                                            <Typography>
                                                Escolha o ícone da Categoria
                                            </Typography>

                                            <Tooltip title="O Ícone será utilizado apenas para exibição de informações visuais." placement="top">
                                                <InfoIcon sx={{ color: blue[500], cursor: 'pointer' }} />
                                            </Tooltip>
                                        </Stack>
                                    </Grid>

                                    {categoryIcons.map((icon, index) => (
                                        <Grid key={index} item xs={3} md={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Tooltip title={icon.name_PtBr} placement="top">
                                                <IconButton size="large" color="inherit" onClick={() => setSelectedIcon(icon.name)}>
                                                    <Box sx={{
                                                        width: '3rem',
                                                        height: '3rem',
                                                        pl: 0.3,
                                                        border: selectedIcon == icon.name ? `3px solid ${green[800]}` : 'none',
                                                        borderRadius: '50%',
                                                        backgroundColor: selectedIcon == icon.name ? green[100] : 'transparent',
                                                    }}>
                                                        <icon.component sx={{ width: '100%', height: '100%' }} />
                                                    </Box>
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    </>);
}

export default CategoryForm;