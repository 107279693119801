import { Box, Divider, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import ChartJSLine, { CategoryScale, LinearScale, PointElement, LineElement, Tooltip } from 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import { generateColors } from '../../../../../configs/functions';

ChartJSLine.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Tooltip
);

function MonthlyConsumptionChart({ monthlyAverageConsumptionData, isLoading }) {
    const isEmpty = monthlyAverageConsumptionData == null || monthlyAverageConsumptionData.length === 0;

    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

    const colors = generateColors();

    const labels = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

    const options = {
        responsive: true,
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => context.dataset.label + ': ' + context.parsed.y,
                },
            },
            legend: {
                position: 'bottom'
            }
        },
        scales: {
            x: {
                type: 'category',
                labels,
            },
            y: {
                min: 0,
            },
        },
        animation: {
            duration: 1200,
            easing: 'easeOutQuart', // Efeito de easing da animação
        },
    };

    const data = {
        labels,
        datasets: monthlyAverageConsumptionData.map((data, index) => ({
            label: data.year,
            data: data.data.map((item) => item.average),
            borderColor: `${colors[index]}`,
            backgroundColor: `${colors[index]}99`, // hex color c/ opacity
        })),
        // mock data
        // datasets: [
        //     {
        //         label: '2022',
        //         data: [0, 3, 4, 5, 6, 7, 18, 9, 20, 11, 12],
        //         borderColor: `${colors[0]}`,
        //         backgroundColor: `${colors[0]}99`,
        //     },
        //     {
        //         label: '2023',
        //         data: [0, 6, 5, 4, 7, 3, 12, 10, 11, 12, 17],
        //         borderColor: `${colors[1]}`,
        //         backgroundColor: `${colors[1]}99`,
        //     },
        //     {
        //         label: '2024',
        //         data: [0, 5, 6, 7, 8, 9, 10, 11, 12, 13, 8],
        //         borderColor: `${colors[2]}`,
        //         backgroundColor: `${colors[2]}99`,
        //     },
        // ],
    };

    return (
        <>
            <Typography variant='h2' component={'h2'}>
                Consumo Médio Mensal de EPI's por funcionário
            </Typography>

            <Divider />

            {!isLoading
                ?
                <div style={{ position: 'relative' }}>
                    {isEmpty && (
                        <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, background: grey[300], opacity: 0.4 }}>
                            <Typography sx={{ color: '#000', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 3 }}>
                                Nenhum dado disponível
                            </Typography>
                        </Box>
                    )}

                    <Line
                        options={options}
                        data={data}
                        height={isSmallScreen && 240}
                    />
                </div>
                : <Skeleton variant="rectangular" width={'100%'} height={200} sx={{ mt: 1 }} />
            }
        </>
    );
}

export default MonthlyConsumptionChart;
