import React, { useState } from 'react';
import {
  Badge,
  IconButton,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import {
  ErrorIcon,
  FacialRecognitionIcon,
  ManualIcon,
  SafetyHatIcon,
  StockIcon,
  VendingMachineIcon
} from '../../common/Icons';
import { AlertDetailsError } from './components/AlertDetailsError';
import { MachineStatusDialog } from './components/MachineStatusDialog';

export function IndicatorsTableCell({ alertas, equipamentos, totalEquipDesconectados, totalEquipEstoqueBaixo, totalEpisVencidos, totalCAsVencidos, totalCAsVencer }) {
  const [openAlertsDialog, setOpenAlertsDialog] = useState(false);
  const [openMachinesStatusDialog, setOpenMachinesStatusDialog] = useState(false);

  return (
    <TableCell align="right" sx={{ minWidth: 225 }}>
      {/* Equipamentos Desconectados */}
      <Tooltip
        placement="left"
        title={
          equipamentos.length === 0 ? 'Nenhum equipamento cadastrado' :
            totalEquipDesconectados > 0
              ? `${totalEquipDesconectados} Equipamento(s) offline:`
              : 'Todas máquinas estão online'
        }
      >
        <IconButton onClick={() => equipamentos.length > 0 && setOpenMachinesStatusDialog(true)}>
          <Badge
            showZero
            badgeContent={totalEquipDesconectados}
            variant={totalEquipDesconectados > 0 ? 'standard' : 'dot'}
            color={totalEquipDesconectados > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: equipamentos.length === 0 ? grey[400] : totalEquipDesconectados > 0 ? 'error' : green[300],
              }
            }}
          >
            <VendingMachineIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <MachineStatusDialog
        equipamentos={equipamentos ?? []}
        open={openMachinesStatusDialog}
        setOpen={setOpenMachinesStatusDialog}
      />

      {/* Alertas Erro de Entrega */}
      <Tooltip
        placement="top"
        title={
          alertas?.length > 0
            ? <Typography variant="body3">{alertas?.length} Alerta(s) de Erros de Entrega
            </Typography>
            : 'Nenhum alerta de Erro de Entrega'
        }
      >
        <IconButton onClick={() => alertas?.length > 0 && setOpenAlertsDialog(true)}>
          <Badge
            showZero
            badgeContent={alertas?.length}
            variant={alertas?.length > 0 ? 'standard' : 'dot'}
            color={alertas?.length > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: alertas?.length > 0 ? 'error' : green[300],
              }
            }}
          >
            <FacialRecognitionIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      <AlertDetailsError
        alertas={alertas}
        open={openAlertsDialog}
        setOpen={setOpenAlertsDialog}
      />

      {/* Equipamentos com estoque baixo */}
      <Tooltip
        placement="top"
        title={
          totalEquipEstoqueBaixo > 0
            ? <Typography variant="body3">{totalEquipEstoqueBaixo} Equipamento(s) com estoque baixo
            </Typography>
            : 'Nenhum equipamento com estoque baixo'
        }
      >
        <IconButton>
          <Badge
            showZero
            badgeContent={totalEquipEstoqueBaixo}
            variant={totalEquipEstoqueBaixo > 0 ? 'standard' : 'dot'}
            color={totalEquipEstoqueBaixo > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: totalEquipEstoqueBaixo > 0 ? 'error' : green[300],
              }
            }}
          >
            <StockIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {/* EPIs vencidos */}
      <Tooltip
        placement="top"
        title={
          totalEpisVencidos > 0
            ? <Typography variant="body3">{totalEpisVencidos} EPI(s) vencido(s)</Typography>
            : 'Nenhum EPI vencido'
        }
      >
        <IconButton>
          <Badge
            showZero
            badgeContent={totalEpisVencidos}
            variant={totalEpisVencidos > 0 ? 'standard' : 'dot'}
            color={totalEpisVencidos > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: totalEpisVencidos > 0 ? 'error' : green[300],
              }
            }}
          >
            <SafetyHatIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {/* CAs vencidos */}
      <Tooltip
        placement="top"
        title={
          totalCAsVencidos > 0
            ? <Typography variant="body3">{totalCAsVencidos} CA(s) vencido(s)</Typography>
            : 'Nenhum CA vencido'
        }
      >
        <IconButton>
          <Badge
            showZero
            badgeContent={totalCAsVencidos}
            variant={totalCAsVencidos > 0 ? 'standard' : 'dot'}
            color={totalCAsVencidos > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: totalCAsVencidos > 0 ? 'error' : green[300],
              }
            }}
          >
            <ManualIcon />
          </Badge>
        </IconButton>
      </Tooltip>

      {/* CAs a vencer */}
      <Tooltip
        placement="top"
        title={
          totalCAsVencer > 0
            ? <Typography variant="body3">{totalCAsVencer} CA(s) a vencer nos próximos 30 dias</Typography>
            : 'Nenhum CA a vencer'
        }
      >
        <IconButton>
          <Badge
            showZero
            badgeContent={totalCAsVencer}
            variant={totalCAsVencer > 0 ? 'standard' : 'dot'}
            color={totalCAsVencer > 0 ? 'error' : 'success'}
            sx={{
              "& .MuiBadge-badge": {
                backgroundColor: totalCAsVencer > 0 ? 'error' : green[300],
              }
            }}
          >
            <ErrorIcon />
          </Badge>
        </IconButton>
      </Tooltip>
    </TableCell>
  )
}