import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaSetor } from './configs/functions';
import { listaUsuariosFilter } from '../people/configs/functions';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { getDefaultValueSelectField } from '../../../../utils';

export function SectorForm({ open, setOpen, selectedSector, empresas }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);
    const [pessoas, setPessoas] = useState([]);
    // const [idCentrosCustos, setIdCentrosCustos] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const isEditAction = !!selectedSector;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    // function getCentroCustos(filterByEmpresaId) {
    //     listaCentroCustos(filterByEmpresaId)
    //         .then((response) => setIdCentrosCustos(response?.data.data))
    //         .catch((error) => {
    //             let errorMessage = "Erro ao carregar centros de custos";
    //             if (error.response.data.error) {
    //                 errorMessage = error.response.data.error;
    //             }
    //             exibirAlerta('Erro', errorMessage, 'error');
    //         });
    // }

    const onSubmit = async (data) => {
        let formDataToSend = {};

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedSector.setores_id[0];

            if (dirtyData?.id_empresas) {
                formDataToSend.id_empresas = Array.isArray(dirtyData.id_empresas) ? dirtyData.id_empresas : [dirtyData.id_empresas];
            }
        } else {
            formDataToSend = data;
            formDataToSend.id_empresas = Array.isArray(data.id_empresas) ? data.id_empresas : [data.id_empresas];
        }

        try {
            setIsLoading(true);
            exibirDialog('Salvando. Aguarde...');
            const res = await manipulaSetor(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Setor.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            const defaultValueIdEmpresa = getDefaultValueSelectField(selectedSector, selectedSector?.empresas_id[0], empresas);

            const defaultValues = {
                id: selectedSector ? selectedSector.setores_id[0] : '',
                titulo: selectedSector ? selectedSector.titulo : '',
                status: selectedSector ? selectedSector.status[0] : 1,
                descricao: selectedSector ? selectedSector.descricao : '',
                cod_externo: selectedSector ? selectedSector.cod_externo : '',
                id_centro_custo: selectedSector ? selectedSector.centroCustos_id[0] : '',
                id_lider: selectedSector ? selectedSector.lideres_id[0] : '',
                id_empresas: defaultValueIdEmpresa,
            };

            if (defaultValueIdEmpresa) {
                setSelectedEmpresaId(defaultValueIdEmpresa);
            }

            reset(defaultValues);
        } else {
            reset();
            setSelectedEmpresaId(null);
        }
    }, [empresas, open, reset, selectedSector]);

    useEffect(() => {
        if (!selectedEmpresaId) return;
        const filterByEmpresaId = { id_empresa: selectedEmpresaId }
        listaUsuariosFilter(filterByEmpresaId).then((res) => setPessoas(res?.data.data));
        // getCentroCustos(filterByEmpresaId);
    }, [selectedEmpresaId]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Setor <strong>{selectedSector.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar novo Setor</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Setor.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Título'
                                        placeholder='Digite o título do Setor'
                                        name='titulo'
                                        fullWidth
                                        {...register("titulo", { required: true })}
                                        error={!!errors.titulo}
                                        helperText={getFormErrorMessage(errors, 'titulo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Código Externo'
                                        placeholder='Digite o código externo'
                                        name='cod_externo'
                                        fullWidth
                                        {...register("cod_externo", { required: false })}
                                        error={!!errors.cod_externo}
                                        helperText={getFormErrorMessage(errors, 'cod_externo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                {empresas &&
                                    // <Grid container item xs={12} mr={2}>
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled
                                            isDisabled={!(!!selectedSector)}
                                            text="Não é possível alterar a Empresa de um Setor já cadastrado"
                                        >
                                            <Controller
                                                name='id_empresas'
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={selectedSector ? selectedSector.empresas_id[0] : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Empresa"
                                                        disabled={!!selectedSector}
                                                        onChange={(e) => {
                                                            setSelectedEmpresaId(e.target.value);
                                                            setValue('id_empresas', e.target.value, { shouldDirty: true })
                                                            setValue('id_lider', '', { shouldDirty: true })
                                                            setValue('id_centro_custo', '', { shouldDirty: true })
                                                            if (e.target.value) clearErrors("id_empresas");
                                                        }}
                                                        error={!!errors.id_empresas}
                                                        helperText={getFormErrorMessage(errors, 'id_empresas')}
                                                    >
                                                        {empresas.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.nomeFantasia}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                    // </Grid>
                                }

                                {pessoas &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                                            <Controller
                                                name='id_lider'
                                                control={control}
                                                // rules={{ required: true }}
                                                defaultValue={selectedSector ? selectedSector.lideres_id[0] : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Líder do Setor"
                                                        disabled={!selectedEmpresaId}
                                                        onChange={(e) => {
                                                            setValue('id_lider', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) {
                                                                clearErrors("id_lider");
                                                            }
                                                        }}
                                                        error={!!errors.id_lider}
                                                        helperText={getFormErrorMessage(errors, 'id_lider')}
                                                    >
                                                        {pessoas.length > 0 ? (
                                                            [
                                                                ...pessoas.filter(option => option.status !== 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.matricula} - {option.nome}
                                                                    </MenuItem>
                                                                )),
                                                                ...pessoas.filter(option => option.status === 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id} disabled>
                                                                        {option.matricula} - {option.nome} - (Inativo)
                                                                    </MenuItem>
                                                                ))
                                                            ]
                                                        ) : (
                                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                                        )}
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                }

                                {/* {idCentrosCustos &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled isDisabled={selectedEmpresaId || (selectedSector && selectedSector.centroCustos_id[0])}>
                                            <Controller
                                                name='id_centro_custo'
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={selectedSector ? selectedSector.centroCustos_id[0] : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Centro de Custo"
                                                        disabled={!selectedEmpresaId || (selectedSector && !selectedSector.centroCustos_id[0])}
                                                        onChange={(e) => {
                                                            setValue('id_centro_custo', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) {
                                                                clearErrors('id_centro_custo');
                                                            }
                                                        }}
                                                        error={!!errors.id_centro_custo}
                                                        helperText={getFormErrorMessage(errors, 'id_centro_custo')}
                                                    >
                                                        {idCentrosCustos.length > 0 ? (
                                                            // Separar itens habilitados e desabilitados
                                                            [
                                                                ...idCentrosCustos.filter(option => option.status !== 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id}>
                                                                        {option.nome}
                                                                    </MenuItem>
                                                                )),
                                                                ...idCentrosCustos.filter(option => option.status === 0).map(option => (
                                                                    <MenuItem key={option.id} value={option.id} disabled>
                                                                        {option.nome} - (Inativo)
                                                                    </MenuItem>
                                                                ))
                                                            ]
                                                        ) : (
                                                            <MenuItem disabled>Nenhum disponível</MenuItem>
                                                        )}
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                } */}

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}