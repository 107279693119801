import { Grid, Typography } from '@mui/material';
import VariationsCheckbox from '../../../../common/VariationsCheckbox';
import { useRef } from 'react';

export function Step02({ getValues, register, setValue, errors, clearErrors }) {
  //para bloquear a step02 quando a busca assincrono do produto pelo código ocorrer
  const travaStep02 = useRef(false);

  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h2" component={'h2'}>
          Cadastro Produto: {getValues('nome')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <VariationsCheckbox
          codigoExistente={travaStep02}
          register={register}
          setValue={setValue}
          getValues={getValues}
          errors={errors}
          clearErrors={clearErrors}
        />
      </Grid>
    </Grid>
  );
};
