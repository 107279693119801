import { useState } from 'react';
import axios from 'axios';
import {
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Typography,
} from '@mui/material';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';

export function AbilitiesRole({ abilities, handleCarregaFiliais, handleOpenModalFilial }) {
    const { exibirAlerta } = useCommonItems();

    const [deletingFilial, setDeletingFilial] = useState(null);

    const deleteEmpresa = (id) => {
        axios.delete(`empresas/${id}`)
            .then((response) => {
                const message = response.data.message ? response.data.message : 'Empresa excluída com sucesso'
                exibirAlerta('Empresa excluída', message, 'success')
                handleCarregaFiliais();
            }).catch((error) => {
                console.log(error);
                exibirAlerta('Erro ao alterar status', '', 'error')
            })

        setDeletingFilial(null);
    }

    const handleEmpresa = (filial) => {
        const novoStatus = (filial.status === 'ativa') ? 'inativa' : 'ativa'

        axios.patch(`empresas/${filial.id}`, { status: novoStatus })
            .then((response) => {
                const message = filial.status === 'ativa' ? 'Filial inativada com sucesso' : 'Filial ativada com sucesso'
                exibirAlerta('Sucesso', message, 'success')
                handleCarregaFiliais();
            }).catch((error) => {
                console.log(error)
                exibirAlerta('Erro', 'Não foi possível executar a ação.', 'error')
            })
    }

    return (
        <Grid item xs={12} sx={{ backgroundColor: "#f0f0f0", p: 2, borderRadius: 1 }}>
            <Grid item xs={12} md={9}>
                <Typography variant='h6'>
                    Habilidades da Função
                </Typography>
            </Grid>

            <TableContainer>
                <Table aria-label="Habilidades">
                    <TableBody>
                        {abilities.map((ability) =>
                            <TableRow hover key={ability.id} sx={{ backgroundColor: '#f1f1f1' }}>
                                <TableCell>&#8226; {ability.nome} {ability?.descricao}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {deletingFilial &&
                <ConfirmDialog
                    description={`Tem certeza que deseja a habilidade "${deletingFilial.nome}"?`}
                    title={'Excluir Habilidade'}
                    goAction={() => deleteEmpresa(deletingFilial.id)}
                    handleClose={() => setDeletingFilial(null)}
                    state={deletingFilial}
                />
            }
        </Grid>
    );
}