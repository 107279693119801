import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    TextField,
    CircularProgress,
    Box,
    Autocomplete,
} from '@mui/material';
import { Home, FilterAlt } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { NotificationIcon } from '../../../common/Icons';
import { listaAlarmes, listaAlertas } from './configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { useAppContext } from '../../../../contexts/AppContext';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { checkPermissionsAndRedirect, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { AlertRow } from './AlertRow';
import { queryKeyListAlertsMenu, queryKeyListUnreadAlerts } from '../../../common/AppBar';

export function ListOfAlerts() {
    const queryClient = useQueryClient();
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [filters, setFilters] = useState({
        id_empresas: null,
        id_alarme: 'all',
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending: isPendingEmpresasData } = useQuery({
        queryKey: ['listaEmpresas'],
        queryFn: async () => {
            const response = await listaEmpresas();
            const empresasData = response.data.data;

            const selectedEmpresaId = getEmpresaIdSession() || empresasData[0]?.id;

            setEmpresaIdSession(selectedEmpresaId);
            setSelectedEmpresaId(selectedEmpresaId);

            const defaultValues = {
                id_empresas: selectedEmpresaId,
                id_alarme: filters.id_alarme,
                data_inicio: filters.data_inicio ?? dayjs().subtract(1, 'month'),
                data_fim: filters.data_fim ?? dayjs(),
            };

            reset(defaultValues);
            setFilters(defaultValues);

            return response;
        },
        refetchOnWindowFocus: false,
    });

    const isLoadingEmpresas = isLoadingEmpresasData || isPendingEmpresasData;

    const queryFilters = {
        id_empresas: [filters.id_empresas],
        data_inicio: dayjs(filters.data_inicio).format('YYYY-MM-DD'),
        data_fim: dayjs(filters.data_fim).format('YYYY-MM-DD'),
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        ...((filters.id_alarme && filters.id_alarme !== 'all') && { id_alarme: filters.id_alarme })
    };

    const queryKeyAlerts = ['listOfAlerts', queryFilters];
    const { data: alertsData, isLoading, isPending, refetch: refetchAlertas, isError: isErrorAlertas } = useQuery({
        queryKey: queryKeyAlerts,
        queryFn: () => listaAlertas(queryFilters),
        enabled: !!filters.id_empresas
    });

    const alertas = alertsData?.data?.data || [];
    const numTotalItems = alertsData?.data?.numero_total || 0;
    const isLoadingAlerts = isLoading || isPending;

    const { data: alarmesData, isError: isErrorAlarmes } = useQuery({
        queryKey: ['listOfAlarms'],
        queryFn: () => listaAlarmes(),
        enabled: !!filters.id_empresas
    });

    const alarmes = alarmesData?.data?.data || [];

    const updateAlertStatus = (alertId, status) => {
        // percorre o array de alertas e atualiza o status do alerta
        const cache = queryClient.getQueryData(queryKeyAlerts);
        const cachedData = cache?.data?.data || [];

        const updatedData = cachedData.map((item) => {
            if (item.id === alertId) {
                return { ...item, status: status };
            }
            return item;
        });

        queryClient.setQueryData(queryKeyAlerts, {
            ...cache,
            data: {
                ...cache?.data,
                data: updatedData
            }
        });

        // invalidate e refetch para atualizar os dados
        queryClient.invalidateQueries({ queryKey: queryKeyListAlertsMenu });
        queryClient.invalidateQueries({ queryKey: queryKeyListUnreadAlerts });
    }

    const onSubmit = (data) => {
        setFilters({
            id_empresas: data.id_empresas,
            id_alarme: data.id_alarme,
            data_inicio: data.data_inicio,
            data_fim: data.data_fim,
        });
        setPage(0);
        refetchAlertas();
    };

    useEffect(() => {
        if (filters.id_empresas) {
            refetchAlertas();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filters.id_empresas]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_alerta", "list_alerta"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        return () => {
            reset();
        }
    }, [reset]);

    useEffect(() => {
        if (listaEmpresasError) {
            exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
        } else if (isErrorAlertas) {
            exibirAlerta('Erro', 'Erro ao carregar alertas', 'error');
        } else if (isErrorAlarmes) {
            exibirAlerta('Erro', 'Erro ao carregar alarmes', 'error');
        }
    }, [exibirAlerta, listaEmpresasError, isErrorAlertas, isErrorAlarmes])

    return (<>
        <Helmet title='Alertas' defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <LinkNavegacao to='/epis/alertas'>Alertas</LinkNavegacao>
                    <Typography variant='span'>Alertas Disparados</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<NotificationIcon fontSize='large' />}
                    title='Alertas Disparados'
                    description='Visualize os Alertas que foram disparados.'
                />
            </Grid>

            <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Grid container item xs={12} md={12} spacing={3}>
                    <Grid item xs={12} md={6}>
                        <SelectEmpresaFilter
                            empresas={empresasData?.data?.data || []}
                            defaultValue={selectedEmpresaId}
                            onChangeValue={(value) => {
                                setSelectedEmpresaId(value);
                                setEmpresaIdSession(value);
                                setValue('id_empresas', value, { shouldDirty: true });
                            }}
                            textHelper={false}
                            isLoading={isLoadingEmpresas}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Autocomplete
                                options={alarmes}
                                getOptionLabel={(option) => option.tipo || ''}
                                isOptionEqualToValue={(option, value) => option.id === value}
                                onChange={(event, value) => {
                                    setValue("id_alarme", value?.id || 'all');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Tipo de Alarme"
                                        fullWidth
                                        size='small'
                                        error={!!errors.id_alarme}
                                        InputProps={{ ...params.InputProps, style: { backgroundColor: '#fff' } }}
                                    />
                                )}
                                noOptionsText="Nenhum alarme encontrado"
                            />
                        </Box>
                    </Grid>
                </Grid>

                <Grid container item xs={12} md={12} spacing={3}>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            sx={{ width: '100%' }}
                            label="Data de Início"
                            size='small'
                            format="DD/MM/YYYY"
                            openTo="day"
                            maxDate={dayjs()}
                            dayOfWeekFormatter={renderDay}
                            defaultValue={dayjs().subtract(1, 'month')}
                            onChange={(data) => {
                                setValue('data_inicio', data, { shouldDirty: true })
                                if (data && data.isBefore(dayjs())) {
                                    clearErrors('data_inicio');
                                }
                            }}
                            onError={(error) => {
                                if (error) {
                                    const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                                    setError("data_inicio", { type: "invalid", message: errorMessage })
                                }
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.data_inicio,
                                    helperText: errors.data_inicio ? errors.data_inicio.message : null,
                                    size: 'small',
                                    style: {
                                        backgroundColor: '#fff',
                                        borderRadius: 4,
                                    }
                                }
                            }}
                            TextField={(params) => (
                                <TextField
                                    size='small'
                                    autoComplete='off'
                                    fullWidth
                                    type="date"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <DatePicker
                            sx={{ width: '100%' }}
                            label="Data de Fim"
                            size='small'
                            format="DD/MM/YYYY"
                            openTo="day"
                            maxDate={dayjs().add(1, 'day')}
                            dayOfWeekFormatter={renderDay}
                            defaultValue={dayjs()}
                            onChange={(data) => {
                                setValue('data_fim', data, { shouldDirty: true })
                                if (data && data.isBefore(dayjs().add(1, 'day'))) {
                                    clearErrors('data_fim');
                                }
                            }}
                            onError={(error) => {
                                if (error) {
                                    const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                                    setError("data_fim", { type: "invalid", message: errorMessage })
                                }
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.data_fim,
                                    helperText: errors.data_fim ? errors.data_fim.message : null,
                                    size: 'small',
                                    style: {
                                        backgroundColor: '#fff',
                                        borderRadius: 4,
                                    }
                                },
                            }}
                            TextField={(params) => (
                                <TextField
                                    size='small'
                                    autoComplete='off'
                                    fullWidth
                                    type="date"
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} md={1}>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            disabled={
                                isLoadingAlerts ||
                                Object.keys(errors).length > 0 ||
                                !verifyFieldsAreFilled([getValues('id_empresas'), getValues('data_inicio'), getValues('data_fim')])
                            }
                            startIcon={isLoadingAlerts ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
                        >
                            Filtrar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Alertas">
                        <TableHead>
                            <TableRow>
                                <TableCell>Empresa</TableCell>
                                <TableCell>Evento</TableCell>
                                <TableCell>Data</TableCell>
                                <TableCell>Envio</TableCell>
                                <TableCell>Lido/Resolvido</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoadingAlerts ? <LoadingTableRow />
                                : alertas && alertas?.length > 0
                                    ? alertas.map((item) =>
                                        <AlertRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleUpdateStatus={updateAlertStatus}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>
    </>);
}
