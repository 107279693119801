import { useState } from 'react';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { FolderOffOutlined, UploadFile } from '@mui/icons-material';
import { DocumentItem } from './DocumentItem';
import ConfirmDialog from '../../../../common/ConfirmDialog';
import { deletaProntuario } from '../../organization/configs/functions';

export function DocumentsUploader({ prontuarios, setProntuarios, tiposDocumento, setValue, register, clearErrors, errors, setError, selectedEmpresaId, handleExibirAlerta, disableActions }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProntuarioId, setSelectedProntuarioId] = useState(null);

  const deleteProntuario = async (deletedProntuario) => {
    try {
      const prontuarioId = deletedProntuario.id;

      if (!deletedProntuario.isNew) {
        await deletaProntuario(prontuarioId);
      }

      const newProntuarios = prontuarios.filter((prontuario) => prontuario.id !== prontuarioId);
      setProntuarios(newProntuarios);
      handleExibirAlerta('Sucesso', 'Documento excluído com sucesso!', 'success');
    } catch (error) {
      handleExibirAlerta('Ops', 'Ocorreu um erro ao excluir Documento!', 'error');
    } finally {
      setOpenDialog(false);
      setSelectedProntuarioId(null);
    }
  };

  const createProntuario = async (files) => {
    // verify exists file
    if (!files.length) return;

    const file = files[0];
    const newProntuario = { id: Date.now(), isNew: true, is_dirty: true, arquivo: file, ponto_alerta: 0 };
    setProntuarios([...prontuarios, newProntuario]);
  };

  function updateDocumentData(updatedData, prontuarioId) {
    const updatedProntuarios = [...prontuarios];
    const prontuarioIndex = updatedProntuarios.findIndex(item => item.id === prontuarioId);

    if (prontuarioIndex !== -1) {
      updatedProntuarios[prontuarioIndex] = {
        ...updatedProntuarios[prontuarioIndex],
        is_dirty: true,
        ...updatedData
      };

      setValue(`prontuarios[${prontuarioIndex}]`, updatedProntuarios[prontuarioIndex]);
      setValue('prontuarios', updatedProntuarios);
      clearErrors('prontuarios');
    }

    setProntuarios(updatedProntuarios);
    setValue('prontuarios', updatedProntuarios);
  }

  return (
    <>
      <Grid item xs={12} md={8}>
        <Typography>
          Documentos do Colaborador
        </Typography>
      </Grid>

      <input
        type="file"
        onChange={(event) => createProntuario(event.target.files)}
        style={{ display: 'none' }}
        id="company-logo-upload"
      />

      <Grid item xs={12} md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!disableActions && (
          <label htmlFor="company-logo-upload" style={{ width: 'fit-content' }}>
            <Button variant="outlined" component="span" startIcon={<UploadFile fontSize='small' />}>
              Adicionar Documento
            </Button>
          </label>
        )}
      </Grid>

      <Stack direction='column' spacing={2}>
        {prontuarios.map((prontuario) => (
          <DocumentItem
            key={prontuario.id}
            prontuario={prontuario}
            register={register}
            clearErrors={clearErrors}
            errors={errors}
            setError={setError}
            tiposDocumento={tiposDocumento}
            handleUpdateDocumentData={(updatedData) => updateDocumentData(updatedData, prontuario.id)}
            handleDeleteProntuario={() => {
              setOpenDialog(true);
              setSelectedProntuarioId(prontuario);
            }}
            selectedEmpresaId={selectedEmpresaId}
            disableActions={disableActions}
          />
        ))}
      </Stack>

      {prontuarios.length === 0 && (
        <Grid item xs={12} md={12}>
          <Stack direction="row" spacing={1} alignItems="center" justifyContent='center' sx={{ py: 4 }}>
            <FolderOffOutlined fontSize="small" />
            <Typography variant="h6" color="textSecondary">
              Nenhum documento atribuído para este colaborador.
            </Typography>
          </Stack>
        </Grid>
      )}

      <ConfirmDialog
        description={'Tem certeza que deseja excluir este Documento?'}
        title={'Excluir Documento'}
        goAction={() => deleteProntuario(selectedProntuarioId)}
        handleClose={setOpenDialog}
        state={openDialog}
      />
    </>
  );
}
