import { consultaCA } from '../configs/functions';
import dayjs from 'dayjs';
import { Alert, Autocomplete, Checkbox, CircularProgress, Divider, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers';
import { getFormErrorMessage, renderDay } from '../../../../../configs/functions';
import ProductImageUpload from '../../../../common/ProductImageUpload';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';

// Etapa 01 do formulário
export function Step01({ control, register, setValue, getValues, errors, setError, clearErrors, exibiAlertaAutoPreenchimento, setExibiAlertaAutoPreenchimento, selectedProduct, categorias, unidadesDeMedida, setSelectedCategory, isEditAction }) {
  const { handleCloseDialog, exibirAlerta, exibirDialog } = useCommonItems();

  const buscaProdutoCA = async () => {
    try {
      clearErrors();
      exibirDialog('Pesquisando C.A. informado...');
      const result = await consultaCA(getValues('ca')).finally(() => {
        handleCloseDialog();
      });
      const produtoGOV = result.data[0];
      if (produtoGOV) {
        if (produtoGOV.situacao !== 'VÁLIDO') {
          setValue('nome', '');
          setValue('descricao', '');
          setValue('indicacao', '');
          setValue('precaucao', '');
          setValue('observacao', '');
          setValue('validade_ca', null);
          setValue('id_categorias', null);
          setValue('id_unidadeMedida', null);
          exibirAlerta('Problemas com o C.A.', `O status do C.A informado é ${produtoGOV.situacao}. Verifique os dados informados ou informe manualmente as informações.`, 'error')
          setExibiAlertaAutoPreenchimento(false);
        } else {
          setExibiAlertaAutoPreenchimento(true);
          setValue('nome', produtoGOV.nome);
          setValue('descricao', produtoGOV.descricao);
          setValue('indicacao', produtoGOV.aprovadoParaLaudo);
          setValue('precaucao', produtoGOV.restricaoLaudo);
          setValue('observacao', produtoGOV.observacaoLaudo);
          setValue('validade_ca', dayjs.utc(new Date(produtoGOV.validade)));
        }
      } else {
        exibirAlerta('Problemas com o C.A.', 'Nenhum produto encontrado com o C.A. informado!', 'error')
      }
    } catch (error) {
      return error;
    }
  };


  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      buscaProdutoCA();
    }
  };

  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={6}>
        <Grid item xs={12} md={8} >
          <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
            <Grid item xs={6}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                label="CA"
                placeholder="Digite o CA do Produto"
                autoComplete='off'
                error={errors.ca && true}
                helperText={errors.ca && 'O CA é obrigatória.'}
                fullWidth
                {...register('ca')}
                variant="outlined"
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Buscar produto pelo C.A." arrow>
                        <IconButton onClick={() => buscaProdutoCA()}>
                          <Search />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="validade_ca"
                control={control}
                render={({ field: { ref, value, onChange, ...field } }) => (
                  <DatePicker
                    sx={{ mt: 1 }}
                    label="Validade do CA"
                    format="DD/MM/YYYY"
                    disablePast
                    openTo="year"
                    views={['year', 'month', 'day']}
                    dayOfWeekFormatter={renderDay}
                    value={value || null}
                    onChange={(value) => {
                      setValue('validade_ca', value, { shouldDirty: true });
                      if (value && value > dayjs()) {
                        clearErrors('validade_ca');
                      }
                    }}
                    defaultValue={selectedProduct && selectedProduct.validadeCa && dayjs.utc(new Date(selectedProduct.validadeCa))}
                    onError={(error) => {
                      if (error === 'disablePast') {
                        setError('validade_ca', { type: 'invalid', message: 'Equipamento com CA vencido' });
                      } else if (error) {
                        setError('validade_ca', { type: 'invalid', message: 'Data inválida' });
                      }
                    }}
                    slotProps={{
                      textField: {
                        error: !!errors.validade_ca,
                        helperText: errors.validade_ca ? errors.validade_ca.message : null,
                        InputLabelProps: { shrink: true },
                      },
                    }}
                    TextField={(params) => (
                      <TextField
                        {...field}
                        {...params}
                        autoComplete='off'
                        fullWidth
                        type="date"
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                InputLabelProps={{ shrink: true }}
                margin="dense"
                label="Nome"
                required
                placeholder="Digite o nome do produtos"
                name="nome"
                autoComplete='off'
                error={errors.nome && true}
                helperText={errors.nome && 'O Nome é obrigatório.'}
                fullWidth
                {...register('nome', { required: true })}
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="descricao"
                control={control}
                defaultValue=""
                rules={{ required: 'A Descrição é obrigatória.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    label="Descrição"
                    required
                    placeholder="Digite a Descrição"
                    error={errors.descricao && true}
                    helperText={errors.descricao && 'A Descrição é obrigatória.'}
                    fullWidth
                    multiline
                    rows={2}
                    variant="outlined"
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="indicacao"
                control={control}
                defaultValue=""
                rules={{ required: 'A Indicação é obrigatória.' }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    label="Indicação"
                    required
                    placeholder="Digite a Indicação"
                    name="indicacao"
                    error={errors.indicacao && true}
                    helperText={errors.indicacao && 'A Indicação é obrigatória.'}
                    fullWidth
                    multiline
                    rows={2}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="precaucao"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    label="Precaução"
                    placeholder="Digite a Precaução"
                    name="precaucao"
                    fullWidth
                    multiline
                    rows={2}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="observacao"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    InputLabelProps={{ shrink: true }}
                    margin="dense"
                    label="Observação"
                    placeholder="Digite a Observação"
                    name="observacao"
                    fullWidth
                    multiline
                    rows={2}
                  />
                )}
              />
              <Divider variant="middle" sx={{ mt: 2, mb: 1 }} />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="id_categorias"
                control={control}
                rules={{ required: 'A Categoria é obrigatória.' }}
                render={({ field: { ref, onChange, value, ...field } }) => (
                  <>
                    {categorias.length > 0 ? (
                      <Autocomplete
                        options={categorias}
                        getOptionLabel={(option) => option.nome}
                        noOptionsText="Nenhuma Categoria encontrada"
                        isOptionEqualToValue={(option, value) => option.nome === value.nome}
                        value={categorias.find((option) => option.nome === value) || null}
                        onChange={(event, newValue) => {
                          if (newValue !== null) {
                            onChange(newValue.nome);
                            setSelectedCategory(newValue);
                            setValue('existsGheWithThisCategory', false);
                          } else {
                            onChange(null);
                            setSelectedCategory(null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Categoria"
                            required
                            autoComplete='off'
                            error={!!errors.id_categorias}
                            helperText={errors.id_categorias && 'A Categoria é obrigatória.'}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Selecione uma Categoria"
                          />
                        )}
                      />
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="id_unidadeMedida"
                control={control}
                render={({ field }) => (
                  <>
                    {unidadesDeMedida.length > 0 ? (
                      <Autocomplete
                        options={unidadesDeMedida}
                        getOptionLabel={(option) => option.nome}
                        noOptionsText="Nenhuma Unidade de Medida encontrada"
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        value={
                          unidadesDeMedida.find((option) => option.id === field.value) || null
                        }
                        onChange={(event, value) => {
                          if (value !== null) {
                            setValue('id_unidadeMedida', value.id);
                          } else {
                            setValue('id_unidadeMedida', null);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Unidade de Medida"
                            required
                            autoComplete='off'
                            error={!!errors.id_unidadeMedida}
                            helperText={errors.id_unidadeMedida && 'A Unidade de medida é obrigatória.'}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            placeholder="Selecione uma Unidade de Medida"
                          />
                        )}
                      />
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </>
                )}
                rules={{ required: 'A Unidade de medida é obrigatória.' }}
              />
            </Grid>

            {isEditAction && (
              <Grid item xs={12} md={6}>
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  margin='dense'
                  label='Vida Útil do EPI'
                  type='number'
                  inputProps={{ min: 1 }}
                  placeholder='Digite a Vida Útil do EPI'
                  error={!!errors.vida_util}
                  helperText={getFormErrorMessage(errors, 'vida_util')}
                  {...register("vida_util", { required: true })}
                  autoComplete='off'
                  InputProps={{
                    endAdornment: <Typography color="textSecondary">dias</Typography>,
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  {...register('exibirRelatorio')}
                  defaultChecked={getValues('exibirRelatorio')}
                  onChange={(event) => {
                    setValue('exibirRelatorio', event.target.checked);
                  }}
                />
              }
              label="Exibir Produto na Ficha de EPI"
            />

            <FormControlLabel
              control={
                <Checkbox
                  {...register('for_machine')}
                  defaultChecked={!!getValues('for_machine')}
                  onChange={(event) => {
                    setValue('for_machine', event.target.checked);
                  }}
                />
              }
              label="Produto utilizado em Máquina"
            />
          </FormGroup>
          <Typography p>Foto do Produto</Typography>
          <ProductImageUpload getValues={getValues} setValue={setValue} />
        </Grid>

        {exibiAlertaAutoPreenchimento && (
          <Grid item xs={12}>
            <Alert
              severity="warning" sx={{ mr: 3, fontSize: '0.7rem' }}>
              Esteja ciente de que as informações preenchidas automaticamente no formulário de cadastro do produto,
              utilizando o número de Certificação de Aprovação (CA), requerem revisão minuciosa antes da submissão final.
              Ressaltamos que a responsabilidade pela exatidão dos dados é da empresa contratante.
            </Alert>
          </Grid>
        )}
      </Grid>
    </>
  );
};