import axios from "axios";
import { urlConfigAlarme, urlAlertas, urlAlarmes } from "../../../../../constants/endpoints";

// configAlarme
export const listaAlarmes = (filter = null) => axios.get(urlAlarmes, { params: filter });

// configAlarme
export const listaConfigAlertas = (filter = null) => axios.get(urlConfigAlarme, { params: filter });
export const atualizaConfigAlarme = (data) => axios.patch(`${urlConfigAlarme}/${data.id}`, data);
export const cadastraConfigAlarme = (data) => axios.post(`${urlConfigAlarme}`, data);
export const manipulaConfigAlarme = (data, isEditAction) => isEditAction ? atualizaConfigAlarme(data.id, data) : cadastraConfigAlarme(data);
export const deletaConfigAlarme = (id) => axios.delete(`${urlConfigAlarme}/${id}`);

// alerta
export const listaAlertas = (filter = null) => axios.get(urlAlertas, { params: filter });
export const atualizaAlerta = (data) => axios.patch(`${urlAlertas}/${data.id}`, data);
export const cadastraAlerta = (data) => axios.post(`${urlAlertas}`, data);
export const manipulaAlerta = (data, isEditAction) => isEditAction ? atualizaAlerta(data.id, data) : cadastraAlerta(data);
export const deletaAlerta = (id) => axios.delete(`${urlAlertas}/${id}`);
export const sendEmailAlerta = (id_configAlarme) => axios.post(`${urlAlertas}/enviarEmail`, { id_configAlarme });