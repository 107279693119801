import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Breadcrumbs,
    Grid,
    MenuItem,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import PageTitle from '../../../common/PageTitle';
import { Assessment, Home as HomeIcon } from '@mui/icons-material';
import LinkNavegacao from '../../../common/Link';
import {
    getConsumoEpiFunc,
    getConsumoEpiPorCentroCusto,
    getProductsRanking,
    getRequisicoesDepartamento,
    getRetiradasRequisicoes,
    getTotalCAsVencer,
    getTotalCAsVencidos,
    getTotalEpisVencidos,
    getTotalEquipDesconectados,
    getTotalEquipEstoqueBaixo
} from './configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import MonthlyAverageConsumptionChart from '../reports/charts/monthlyAverageConsumptionChart';
import ConsumptionByCostCenter from '../reports/charts/consumptionByCostCenter';
import SummaryEpis from './summary';
import ProductsRanking from './productsRanking';
import RequestsByDepartment from '../reports/charts/requestsByDepartment';
import RequestsVsDeliveries from '../reports/charts/requestVsDeliveries';
import { checkPermissionsAndRedirect } from '../../../../configs/functions';

export function Dashboard() {
    const { exibirAlerta } = useCommonItems();

    const [empresas, setEmpresas] = useState([]);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
    const [idsEmpresas, setIdsEmpresas] = useState([]);
    const [summaryData, setSummaryData] = useState({
        caVencidos: 0,
        caAVencer: 0,
        funcEpisVencidos: 0,
        equipEstoqueBaixo: 0,
        equipDesconectados: 0,
    });
    const [epiPieChartData, setEpiPieChartData] = useState([]);
    const [requestsData, setRequestsData] = useState({});
    const [requestDepartment, setRequestDepartment] = useState([]);
    const [monthlyAverageConsumptionData, setMonthlyAverageConsumptionData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [productsRanking, setProductsRanking] = useState([]);

    const fetchConsumoEpiPorCentroCusto = async (companyId) => {
        const period = 30;
        const response = await getConsumoEpiPorCentroCusto(companyId, period);
        setEpiPieChartData(response.data);
    };

    const fetchRetiradasRequisicoes = async (companyId) => {
        const period = 180;
        const response = await getRetiradasRequisicoes(companyId, period);
        setRequestsData(response.data);
    };

    const fetchRequisicoesDepartamento = async (companyId) => {
        const response = await getRequisicoesDepartamento(companyId);
        setRequestDepartment(response.data);
    };

    const fetchConsumoEpiFunc = async (companyId) => {
        const period = 90;
        const response = await getConsumoEpiFunc(companyId, period);
        setMonthlyAverageConsumptionData(response.data);
    };

    const fetchTotalEpisVencidos = async (companyId) => {
        const response = await getTotalEpisVencidos(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            funcEpisVencidos: response.data.quantidade,
        }));
    }

    const fetchTotalCAsVencidos = async (companyId) => {
        const response = await getTotalCAsVencidos(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            caVencidos: response.data.quantidade,
        }));
    }

    const fetchTotalCAsVencer = async (companyId) => {
        const response = await getTotalCAsVencer(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            caAVencer: response.data.quantidade,
        }));
    }

    const fetchTotalEquipEstoqueBaixo = async (companyId) => {
        const response = await getTotalEquipEstoqueBaixo(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            equipEstoqueBaixo: response.data.quantidade,
        }));
    }

    const fetchTotalEquipDesconectados = async (companyId) => {
        const response = await getTotalEquipDesconectados(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            equipDesconectados: response.data.quantidade,
        }));
    }

    const fetchProductsRanking = async (companyId) => {
        const response = await getProductsRanking(companyId);
        setProductsRanking(response.data);
    }

    const getSummaryData = async (companyId) => {
        await fetchTotalEpisVencidos(companyId);
        await fetchTotalCAsVencidos(companyId);
        await fetchTotalCAsVencer(companyId);
        await fetchTotalEquipEstoqueBaixo(companyId);
        await fetchTotalEquipDesconectados(companyId);
    };

    const getInfoDashboard = async (companyId) => {
        setIsLoading(true);
        try {
            if (companyId) {
                await Promise.all([
                    getSummaryData(companyId),
                    fetchConsumoEpiPorCentroCusto(companyId),
                    fetchRetiradasRequisicoes(companyId),
                    fetchRequisicoesDepartamento(companyId),
                    fetchConsumoEpiFunc(companyId),
                    fetchProductsRanking(companyId),
                ]);
            }
        } catch (error) {
            exibirAlerta('Ops!', 'Ocorreu um erro ao atualizar as informações', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_sempher"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        listaEmpresas().then((response => {
            const data = response?.data.data;
            if (data && data.length > 0) {
                setEmpresas(data);
                let requestedIds = [];
                data.map((empresa) => requestedIds.push(empresa.id));
                setIdsEmpresas(requestedIds);
                setSelectedEmpresaId(requestedIds);
            }
        })).catch((error) => {
            exibirAlerta('Ops!', 'Ocorreu um erro ao carregar as empresas', 'error');
        });
    }, [exibirAlerta]);

    useEffect(() => {
        if (selectedEmpresaId) {
            getInfoDashboard(selectedEmpresaId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedEmpresaId]);

    return (<>
        <Helmet title="Dashboard" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <Typography variant='span'>Dashboard</Typography>
                </Breadcrumbs>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="baseline"
                    spacing={0}
                    sx={{ mb: 3 }}
                    flexWrap={'wrap'}
                >
                    <PageTitle icon={<Assessment fontSize='large' />} title='Dashboard' />

                    <TextField
                        select
                        label="Empresa"
                        name='id_empresa'
                        value={selectedEmpresaId === idsEmpresas
                            ? 'Todas as Empresas'
                            : selectedEmpresaId
                        }
                        onChange={(e) => {
                            if (e.target.value === 'Todas as Empresas') {
                                setSelectedEmpresaId(idsEmpresas);
                            } else {
                                setSelectedEmpresaId(e.target.value)
                            }
                        }}
                        sx={{ mt: { xs: 2, md: 1 }, ml: { xs: 0, md: 2 } }}
                        helperText="Selecione uma empresa"
                        InputLabelProps={{ shrink: true }}
                    >
                        <MenuItem value={'Todas as Empresas'}>Todas as empresas</MenuItem>
                        {empresas.length > 0 ? (
                            empresas.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.nomeFantasia}
                                </MenuItem>
                            ))
                        ) : (
                            <MenuItem disabled>Nenhuma empresa disponível</MenuItem>
                        )}
                    </TextField>
                </Stack>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    spacing={8}
                >
                    <Grid item xs={12} lg={3}>
                        <SummaryEpis summaryData={summaryData} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MonthlyAverageConsumptionChart monthlyAverageConsumptionData={monthlyAverageConsumptionData} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ConsumptionByCostCenter epiPieChartData={epiPieChartData} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <ProductsRanking productsRanking={productsRanking} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <RequestsByDepartment requestDepartment={requestDepartment} isLoading={isLoading} />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <RequestsVsDeliveries requestsData={requestsData} isLoading={isLoading} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </>);
}