// Mapping states to UTC offsets (simplified example)
export const stateToUTC = {
  'AC': '-5',
  'AL': '-3',
  'AP': '-3',
  'AM': '-4',
  'BA': '-3',
  'CE': '-3',
  'DF': '-3',
  'ES': '-3',
  'GO': '-3',
  'MA': '-3',
  'MT': '-4',
  'MS': '-4',
  'MG': '-3',
  'PA': '-3',
  'PB': '-3',
  'PR': '-3',
  'PE': '-3',
  'PI': '-3',
  'RJ': '-3',
  'RN': '-3',
  'RS': '-3',
  'RO': '-4',
  'RR': '-4',
  'SC': '-3',
  'SP': '-3',
  'SE': '-3',
  'TO': '-3'
};
