import React from 'react';
import { Divider, Skeleton, Stack, Typography } from '@mui/material';
import { CloseWindowIcon, DoNotDisturbIcon, ErrorIcon, ManualIcon, WorkersIcon } from '../../../common/Icons'

function SummaryEpis({ summaryData, isLoading }) {
    return (
        <>
            <Typography variant={'h2'} component={'h2'}>Resumo</Typography>

            <Divider />

            <Stack direction='column' spacing={1} sx={{ mt: 1 }}>
                {!isLoading ?
                    <>
                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <ErrorIcon size={'large'} />
                            <Typography >
                                <b>{summaryData.caAVencer}</b> CA's a vencer nos próximos 30 dias.
                            </Typography>
                        </Stack>

                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <ManualIcon size={'large'} />
                            <Typography>
                                <b>{summaryData.caVencidos}</b> CA's a vencidos.
                            </Typography>
                        </Stack>

                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <DoNotDisturbIcon size={'large'} />
                            <Typography>
                                <b>{summaryData.equipDesconectados}</b> Equipamentos desconectados.
                            </Typography>
                        </Stack>

                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <WorkersIcon size={'large'} />
                            <Typography>
                                <b>{summaryData.funcEpisVencidos}</b> funcionários com EPI's vencidos.
                            </Typography>
                        </Stack>

                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <CloseWindowIcon size={'large'} />
                            <Typography>
                                <b>{summaryData.equipEstoqueBaixo}</b> Equipamentos com estoque baixo/zerado.
                            </Typography>
                        </Stack>
                    </>
                    : Array.from({ length: 4 }).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={'100%'} height={60} />
                    ))
                }
            </Stack>
        </>
    );
}

export default SummaryEpis;