import { Box, MenuItem } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridToolbarFilterButton,
  useGridApiContext
} from '@mui/x-data-grid';
import { CsvIcon, PdfIcon, Xls502Icon } from './Icons';
import { exportToExcel } from '../screens/epis/reports/configs/utils';

export const CustomToolbarReports = ({
  reportName,
  pdfExport,
  customItem,
  showColumnsButton = true,
  showFilterButton = true,
  showDensitySelector = true,
  ...rest }) => {
  const apiRef = useGridApiContext();

  const handleExportCsv = (options) => apiRef.current.exportDataAsCsv(options);
  const handleExportExcel = () => exportToExcel(reportName, apiRef);
  const handleExportPdf = () => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    pdfExport(visibleColumns, reportName);
  }

  return (
    <GridToolbarContainer {...rest} sx={{ justifyContent: 'flex-end', pt: 1 }}>
      {showColumnsButton && <GridToolbarColumnsButton />}

      {showFilterButton && <GridToolbarFilterButton />}

      {showDensitySelector && <GridToolbarDensitySelector />}

      <Box sx={{ flexGrow: 1 }} />

      <GridToolbarExportContainer {...rest}>
        {/* export xls */}
        <MenuItem onClick={handleExportExcel}>
          <Xls502Icon sx={{ mr: 1 }} /> Exportar como Excel
        </MenuItem>

        {/* export csv */}
        <MenuItem onClick={handleExportCsv}>
          <CsvIcon sx={{ mr: 1 }} /> Exportar como CSV
        </MenuItem>

        {/* export pdf */}
        <MenuItem onClick={handleExportPdf}>
          <PdfIcon sx={{ mr: 1 }} /> Exportar como PDF
        </MenuItem>

        {customItem}
      </GridToolbarExportContainer>
    </GridToolbarContainer >
  );
};