import { useState } from 'react'
import { Lock, Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { getFormErrorMessage } from '../../../configs/functions';

export default function FirstStepGeneratePassword({ register, errors }) {
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);

  return (
    <>
      <TextField
        margin='dense'
        placeholder='Deve ter no mínimo 8 caracteres.'
        id="new_password"
        label='Nova Senha'
        type={newPasswordVisible ? 'text' : 'password'}
        error={!!errors.new_password}
        helperText={getFormErrorMessage(errors, 'new_password')}
        fullWidth
        minLength={8}
        {...register("new_password", { required: true })}
        InputProps={{
          startAdornment:
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>,
          endAdornment:
            <IconButton
              onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
              {newPasswordVisible
                ? <VisibilityOff />
                : <Visibility />
              }
            </IconButton>
        }}
        sx={{ mb: 2 }}
      />

      <TextField
        margin='dense'
        placeholder='Deve ter no mínimo 8 caracteres.'
        label='Confirme sua nova Senha'
        id="new_password_confirmation"
        type={newPasswordVisible ? 'text' : 'password'}
        error={!!errors.new_password_confirmation}
        helperText={getFormErrorMessage(errors, 'new_password_confirmation')}
        fullWidth
        {...register("new_password_confirmation", { required: true })}
        InputProps={{
          startAdornment:
            <InputAdornment position="start">
              <Lock />
            </InputAdornment>,
          endAdornment:
            <IconButton onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
              {newPasswordVisible
                ? <VisibilityOff />
                : <Visibility />
              }
            </IconButton>
        }}
        sx={{ mb: 2 }}
      />
    </>
  )
}

