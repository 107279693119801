import { useState, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    Stack
} from '@mui/material';
import { listaTamanhosEVariacoes } from '../../components/screens/epis/products/configs/functions';
import { useCommonItems } from '../../contexts/CommonItensProvider';

// Função para cada item de tamanho/variacao
const ItemTamanho = ({ data, register, setValue, getValues, errors, clearErrors }) => {
    const [ativo, setAtivo] = useState(getValues(`variacoes.${data.tipo}.ativo`) ? true : false);
    const [idValue, setIdValue] = useState(getValues(`variacoes.${data.tipo}.cod_externo`));
    const [errorMessage, setErrorMessage] = useState('');

    const hasError = (!!errors.variacoes && !!errors.variacoes[data.tipo] && !!errors.variacoes[data.tipo].cod_externo && (errorMessage !== '')) || !!errors.variacoes?.[data.tipo]?.cod_externo || errorMessage !== '';

    return (
        <Grid item xs={6}>
            <FormControlLabel
                control={
                    <Checkbox
                        {...register(`variacoes.${data.tipo}.ativo`)}
                        checked={!ativo ? false : true}
                        onChange={(e) => {
                            const isChecked = e.target.checked;
                            const value = isChecked ? data.id : false;
                            setValue(`variacoes.${data.tipo}.ativo`, value);
                            setValue(`variacoes.${data.tipo}.id_variacao`, data.id);
                            setValue(`variacoes.${data.tipo}.is_dirty`, true);
                            setAtivo(value);
                            if (!isChecked) {
                                setValue(`variacoes.${data.tipo}.cod_externo`, '');
                                setIdValue('');
                                clearErrors(`variacoes.${data.tipo}.cod_externo`);
                            }
                        }}
                    />
                }
                label={data.tipo.trim()}
            />
            <TextField
                label="Código"
                variant="outlined"
                value={idValue || ''}
                size="small"
                disabled={!ativo}
                onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    setValue(`variacoes.${data.tipo}.cod_externo`, value);
                    setValue(`variacoes.${data.tipo}.is_dirty`, true);
                    setIdValue(value);
                }}
                error={hasError}
                helperText={errorMessage || (hasError && errors.variacoes[data.tipo].cod_externo.message)}
            />
        </Grid>
    );
};

function VariationsCheckbox({ register, setValue, getValues, errors, clearErrors, codigoExistente, setError }) {
    const { exibirAlerta } = useCommonItems();

    const queryKeyListProducts = ['listOfVariations'];
    const { data: productsData, isLoading, isPending, error: listOfVariationsError } = useQuery({
        queryKey: queryKeyListProducts,
        queryFn: () => listaTamanhosEVariacoes(),
    });

    const variations = productsData?.data?.data ?? [];
    console.log('VariationsCheckbox ~ variations:', variations);

    const isLoadingList = isLoading || isPending;

    useEffect(() => {
        const handleError = (error, defaultMessage) => {
            const message = error?.response?.data?.message || defaultMessage;
            exibirAlerta('Erro', message, 'error');
        };

        if (listOfVariationsError) {
            handleError(listOfVariationsError, 'Erro ao carregar a lista de produtos');
        }
    }, [listOfVariationsError, exibirAlerta]);

    return (
        <Box>
            <FormControl component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ mb: 4 }}>Tamanhos/Variações do produto</FormLabel>
                <FormGroup>
                    <FormControl>
                        <FormGroup>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                {isLoadingList ?
                                    <Grid item xs={12}>
                                        <Stack spacing={2} width={'100%'} direction="row" alignItems="center" justifyContent='center' my={1} gap={1} sx={{ mt: 2 }}>
                                            <CircularProgress size={20} />
                                            <Typography variant='body2'>
                                                Carregando unidades de medida...
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                    : variations && variations.map((data) => (
                                        <ItemTamanho
                                            key={`itemtamanho${data.id}`}
                                            data={data}
                                            register={register}
                                            setValue={setValue}
                                            getValues={getValues}
                                            errors={errors}
                                            clearErrors={clearErrors}
                                        />
                                    ))
                                }
                            </Grid>
                        </FormGroup>
                    </FormControl>
                </FormGroup>
                {variations && (
                    <FormHelperText>Selecione as variações desejadas e digite os Códigos correspondentes</FormHelperText>
                )}
            </FormControl>
        </Box>
    );
}

export default VariationsCheckbox;
