import React from 'react';
import PageTitle from '../../../common/PageTitle';
import { Festival } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import CardOption from '../../../common/CardOption';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';

function Events() {
    return (<>

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/treinamentos'>Treinamentos</LinkNavegacao >
                    <LinkNavegacao to='/treinamentos/eventos'>Eventos</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<Festival fontSize='large' />} title='Eventos' />
                <Typography p>Agrupe treinamentos estratégicos em eventos. Simplifique a organização, acompanhamento e avaliação de múltiplos treinamentos ocorrendo simultaneamente, proporcionando eficiência e integração no processo de capacitação.</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Cadastro de Eventos" description="Registre e organize eventos de treinamentos. Simplifique a gestão e centralize informações para um planejamento eficiente e preciso." />
            </Grid>

        </Grid>

    </>);
}

export default Events;