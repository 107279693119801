import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { DoNotDisturbBlueIcon, WearSafetyHelmetIcon } from '../../../common/Icons';
import { getCategoryIconByName } from '../../../../configs/functions';

function ProductsRanking({ productsRanking, isLoading }) {
    const isEmpty = productsRanking == null || productsRanking.length === 0;

    return (
        <>
            <Typography variant='h2' component={'h2'}>Ranking de Produtos Consumidos</Typography>

            <Divider />

            <Stack direction='column' spacing={1} sx={{ mt: 1 }}>
                {!isLoading ?
                    !isEmpty ?
                        <>
                            {productsRanking.map((product, index) => (
                                <Stack key={product.idProduto} p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                                    {product.url
                                        ?
                                        // verify if is url or string
                                        product.url.includes('http') ?
                                            <img
                                                src={product.url}
                                                alt={product.nome}
                                                style={{ width: 26, height: 26, borderRadius: '50%' }}
                                            />
                                            : getCategoryIconByName(product.url)
                                        : <DoNotDisturbBlueIcon fontSize='large' />
                                    }
                                    <Stack>
                                        <Typography>{product.nome[0].toUpperCase() + product.nome.slice(1).toLowerCase()}</Typography>
                                        <Typography variant='body2' color={grey[700]}>CA {product.ca}</Typography>
                                    </Stack>
                                </Stack>
                            ))}
                        </>
                        :
                        <Stack p direction="row" spacing={1} sx={{ backgroundColor: '#fafafa' }} alignItems='center'>
                            <Box style={{ filter: "grayscale(1) brightness(1.6)" }}>
                                <WearSafetyHelmetIcon fontSize={'large'} color={"#ccc"} />
                            </Box>
                            <Typography variant='body1' component={'p'} color={grey[700]}>Nenhum dado disponível</Typography>
                        </Stack>
                    : Array.from({ length: 3 }).map((_, index) => (
                        <Skeleton key={index} variant="rectangular" width={'100%'} height={60} />
                    ))
                }
            </Stack>
        </>
    );
}

export default ProductsRanking;