import {
    Table,
    TableContainer,
    TableBody,
    TableRow,
    TableCell,
    Grid,
    Typography,
    TableHead,
    Paper,
} from '@mui/material';
import { EmptyTableRow } from '../../common/EmptyTableRow';
import { CompanyRow } from './CompanyRow';

export function CompaniesContractTable({ empresas }) {
    return (
        <>
            {empresas && empresas.length > 0
                ? <Grid item xs={12} sx={{ backgroundColor: "#f2f2f2", p: 2, borderRadius: 1 }}>
                    <Grid item xs={12} md={9}>
                        <Typography variant='h5' mb={2}>Empresas do Contrato</Typography>
                    </Grid>

                    <TableContainer component={Paper} sx={{ backgroundColor: '#fff', boxShadow: 1 }}>
                        <Table aria-label="Empresas">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Empresa</TableCell>
                                    <TableCell>Contato</TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {empresas.map((empresa) => (
                                    <CompanyRow
                                        key={empresa.id}
                                        empresa={empresa}
                                    />)
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                : <EmptyTableRow />
            }
        </>
    );
}
