import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Button,
  Stack
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { VendingMachineIcon, WacomTabletIcon } from '../../../../../common/Icons';

export function EquipmentTypeDialog({ open, setOpen, handleOpenModalEquipment }) {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <DialogTitle id="alert-dialog-title">
        Selecione
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Qual tipo de Equipamento deseja cadastrar?
        </DialogContentText>

        <Stack spacing={2} mt={2}>
          <Button
            onClick={() => {
              handleOpenModalEquipment('maquina');
              setOpen(false);
            }}
            autoFocus
            variant='contained'
            startIcon={<VendingMachineIcon />}
          >
            Máquina
          </Button>

          <Button
            variant="contained"
            startIcon={<WacomTabletIcon />}
            onClick={() => {
              handleOpenModalEquipment('balcao');
              setOpen(false);
            }}
          >
            Balcão
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}