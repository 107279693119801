import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function ConfirmDialog({
    title,
    description,
    state,
    handleClose,
    goAction,
    buttonColor = "error",
    confirmText = "OK",
    cancelText = "Cancelar"
}) {
    return (
        <Dialog
            open={state}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {description}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(false)} variant='outlined'>
                    {cancelText}
                </Button>
                <Button
                    color={buttonColor}
                    onClick={() => goAction(true)}
                    autoFocus
                    variant="contained"
                >
                    {confirmText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog;
