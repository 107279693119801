import axios from "axios"
import { urlGHEs, urlJustificativaRequisicao, urlJustificativaDevolucao, urlPermissao } from "../../../../../constants/endpoints"

const listaGHEs = (filter = null) => axios.get(urlGHEs, { params: filter })
const atualizaGHEs = (id, data) => axios.patch(`${urlGHEs}/${id}`, data)
const manipulaGHEs = (data, isEditAction) => isEditAction ? atualizaGHEs(data.id_grupo, data) : cadastraGHE(data);
const deletaGHEs = (id) => axios.delete(`${urlGHEs}/${id}`)
const cadastraGHE = (data) => axios.post(urlGHEs, data);

// permissoes (GHEs)
const listaPermissoes = (filter = null) => axios.get(urlPermissao, { params: filter });
const atualizaPermissao = (id, data) => axios.patch(`${urlPermissao}/${id}`, data);
const deletaPermissao = (id) => axios.delete(`${urlPermissao}/${id}`);
const cadastraPermissao = (data) => axios.post(urlPermissao, data);

// Justificativa Requisicao
const listaJustificativaRequisicao = (filter) => {
    return axios.get(urlJustificativaRequisicao, { params: filter })
}

const atualizaJustificativaRequisicao = (id, data) => axios.patch(`${urlJustificativaRequisicao}/${id}`, data)

const cadastraJustificativaRequisicao = (data) => axios.post(`${urlJustificativaRequisicao}`, data)

const manipulaJustificativaRequisicao = (data, isEditAction) =>
    isEditAction
        ? atualizaJustificativaRequisicao(data.id, data)
        : cadastraJustificativaRequisicao(data)

const deletaJustificativaRequisicao = (id) => axios.delete(`${urlJustificativaRequisicao}/${id}`);

// Justificativa Devolução
const listaJustificativaDevolucao = (filter) => {
    return axios.get(urlJustificativaDevolucao, { params: filter })
}

const atualizaJustificativaDevolucao = (id, data) => axios.patch(`${urlJustificativaDevolucao}/${id}`, data)

const cadastraJustificativaDevolucao = (data) => axios.post(`${urlJustificativaDevolucao}`, data)

const manipulaJustificativaDevolucao = (data, isEditAction) =>
    isEditAction
        ? atualizaJustificativaDevolucao(data.id, data)
        : cadastraJustificativaDevolucao(data)

const deletaJustificativaDevolucao = (id) => axios.delete(`${urlJustificativaDevolucao}/${id}`);

export {
    listaGHEs,
    atualizaGHEs,
    cadastraGHE,
    manipulaGHEs,
    deletaGHEs,

    listaPermissoes,
    atualizaPermissao,
    deletaPermissao,
    cadastraPermissao,

    listaJustificativaRequisicao,
    atualizaJustificativaRequisicao,
    cadastraJustificativaRequisicao,
    manipulaJustificativaRequisicao,
    deletaJustificativaRequisicao,

    listaJustificativaDevolucao,
    atualizaJustificativaDevolucao,
    cadastraJustificativaDevolucao,
    manipulaJustificativaDevolucao,
    deletaJustificativaDevolucao,
}