import { Box, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import ChartJS, { ArcElement, Tooltip, Legend } from 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { generateColors } from '../../../../../configs/functions';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
    plugins: {
        legend: {
            display: true,
            position: 'bottom',
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    const label = context.label || '';
                    const value = context.parsed || 0;
                    return `${label}: ${value}`;
                },
            },
        },
    },
    cutout: '60%', // Porcentagem de redução no meio do gráfico
    responsive: true,
};

function RequestsVsDeliveries({ requestsData, isLoading }) {
    const isEmpty = requestsData.total == 0;

    const colors = generateColors();

    const data = {
        labels: ['Requisições', 'Retiradas'],
        datasets: [
            {
                label: 'Retiradas x Requisições nos últimos 06 meses',
                data: [requestsData.totalRequisicoes, requestsData.totalRetiradas],
                borderWidth: 3,
                borderColor: colors,
                backgroundColor: colors.map((color) => `${color}99`),
            },
        ],
    };

    const totalValue = requestsData.total;

    return (<>
        <Typography variant='h2' component={'h2'}>
            Retiradas x Requisições efetivadas nos últimos 90 dias
        </Typography>

        <Divider sx={{ mb: 1 }} />

        {!isLoading ?
            (!isEmpty ?
                <div style={{ maxWidth: '250px', margin: 'auto', position: 'relative' }}>
                    <Doughnut data={data} options={options} />

                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: {
                                xs: 'translate(-50%, -100%)',
                                lg: 'translate(-50%, -150%)',
                                xl: 'translate(-50%, -100%)',
                            }
                        }}
                    >
                        <Typography sx={{ margin: 0 }}>Total: {totalValue}</Typography>
                    </Box>
                </div>
                : <Stack spacing={1} alignItems={'center'}>
                    <Skeleton variant="circular" width={140} height={140} animation={false} />
                    <Typography variant='body1' component={'p'} color={grey[700]}>Nenhum dado disponível</Typography>
                </Stack>
            )
            : <Stack spacing={1} alignItems={'center'}>
                <Skeleton variant="circular" width={140} height={140} />
                <Skeleton variant="rectangular" width={'80%'} height={16} />
                <Skeleton variant="rectangular" width={'80%'} height={16} />
            </Stack>
        }
    </>
    );
}

export default RequestsVsDeliveries;
