import { Typography, Box, Stack } from '@mui/material';

function PageTitle({ icon, title, description = '' }) {
    return (
        <Stack spacing={1} direction='column'>
            <Box display="flex" alignItems="center" gap={1}>
                {icon}
                <Typography variant='h1' component={'h1'}>{title}</Typography>
            </Box>

            {description && <Typography p>{description}</Typography>}
        </Stack>
    );
}

export default PageTitle;