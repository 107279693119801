import { useEffect, useState } from 'react';
import { Button, Grid, Typography, Box, Tooltip, Stack, useMediaQuery } from '@mui/material';
import { green, grey, amber } from '@mui/material/colors';
import { ConfigEquipmentForm } from '../ConfigEquipmentForm';
import { listaProdutos } from '../../../products/configs/functions';
import { useCommonItems } from '../../../../../../contexts/CommonItensProvider';
import { Legend } from '../../../../../common/Legend';

export function ThirdStepEquipmentForm({ values, equipamento, motores, setMotores, qtdMotores }) {
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  let colCount = 6;
  if (qtdMotores === 60) {
    colCount = 10;
  }

  const legendItems = [
    { label: 'Motor Livre', color: green[200] },
    { label: 'Motor Ocupado', color: green[900] },
    { label: 'Motor Agrupado', color: grey[400] },
  ];

  const [selectedConfigMotor, setSelectedConfigMotor] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [produtosFilho, setProdutosFilho] = useState([]);

  const loadProdutos = async (id_empresa) => {
    exibirDialog('Carregando produtos da Empresa...');
    try {
      const response = await listaProdutos({ id_empresa, for_machine: 1 });
      setProdutos(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Produtos', '', 'error');
    } finally {
      handleCloseDialog();
    }
  }

  useEffect(() => {
    if (values && values.id_empresa) {
      loadProdutos(values.id_empresa);
    }
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2}>
          <Typography variant='h5' gutterBottom textAlign='center'>Configurações do Equipamento</Typography>
        </Grid>

        <Grid container item direction="row" justifyContent="space-around" alignItems="start" spacing={3}>
          <Grid item xs={12} md={qtdMotores == 36 ? 6 : 5} sx={{ height: isSmallScreen ? 'auto' : '100%', mt: 2 }}>
            <ConfigEquipmentForm
              equipamento={equipamento}
              produtos={produtos}
              configEquipamento={selectedConfigMotor}
              motores={motores}
              setMotores={setMotores}
              produtosFilho={produtosFilho}
              setProdutosFilho={setProdutosFilho}
            />
          </Grid>

          <Grid item xs={12} md={qtdMotores == 36 ? 5 : 7}>
            <Box sx={{ border: 1, borderRadius: 2, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
              <Grid container>
                {motores.map((motor, index) => {
                  if (motor.agrupado_por) {
                    const pair = [motor, motores[motor.agrupado_por - 1]];

                    const indexMotorAgrupar = pair[1].numero_motor - 1;
                    const isGrouped = motores[indexMotorAgrupar - 1] && motores[indexMotorAgrupar - 1].agrupado_por;
                    const isSelected = selectedConfigMotor && selectedConfigMotor.numero_motor === pair[1].numero_motor;

                    return (
                      <Grid container item key={index} xs={12 / (colCount / 2)} justifyContent="center" alignItems="center" textAlign='center'>
                        <Button
                          sx={{
                            px: 0.8,
                            my: 0.1,
                            borderRadius: 2,
                            border: isGrouped ? 2 : 0,
                            boxShadow: isSelected ? `0 0 0 1px ${amber[700]}` : 'none',
                            borderColor: isSelected ? amber[700] : (isGrouped ? green[200] : 'rgba(0, 0, 0, 0.1)'),
                            cursor: isGrouped ? 'pointer' : 'default',
                            background: 'none',
                            '&:hover': { background: 'none' }
                          }}
                          onClick={() => {
                            if (isGrouped) {
                              setSelectedConfigMotor(motores[indexMotorAgrupar])
                            }
                          }}
                        >
                          <Tooltip title={isGrouped && 'Clique para configurar o motor agrupado'}>
                            <Stack direction='row' gap={qtdMotores === 36 ? (isSmallScreen ? 0.5 : 3) : (isSmallScreen ? 0.5 : 2)}>
                              {pair.map((motor) => (
                                <Box key={motor.numero_motor}>
                                  <Tooltip title={!isGrouped && 'Clique para configurar o motor'}>
                                    <Typography variant='span' sx={{ cursor: 'pointer' }}>
                                      <Button
                                        sx={{
                                          minWidth: isSmallScreen ? '32px' : '36px',
                                          maxWidth: isSmallScreen ? '32px' : '44px',
                                          paddingX: isSmallScreen ? 0 : 1,
                                          backgroundColor: motor.status === 'livre' ? green[200] : green[900],
                                          '&:hover': {
                                            backgroundColor: motor.status === 'livre' ? green[400] : green[800],
                                          },
                                        }}
                                        variant='contained'
                                        onClick={() => {
                                          if (!motor.agrupado_por) {
                                            setSelectedConfigMotor(motor)
                                          }
                                        }}
                                        disabled={motor.agrupado_por}
                                      >
                                        <Typography variant='caption' textAlign='center'>{motor.numero_motor}</Typography>
                                      </Button>
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              ))}
                            </Stack>
                          </Tooltip>
                        </Button>
                      </Grid>
                    )
                  } else {
                    // if previous motor is grouped, with the current motor, skip it
                    if (motores[index - 1] && motores[index - 1].agrupado_por === motor.numero_motor) {
                      return null;
                    }

                    const isSelected = selectedConfigMotor && selectedConfigMotor.numero_motor === motor.numero_motor;

                    return (
                      <Grid item key={index} xs={12 / colCount} justifyContent="center" alignItems="center" textAlign='center' sx={{ my: 1 }}>
                        <Tooltip title={motor.agrupado_por ? `Agrupado com o motor ${motor.agrupado_por}` : 'Clique para configurar o motor'}>
                          <Typography variant='span' sx={{ cursor: 'pointer' }}>
                            <Button
                              sx={{
                                // minWidth: '44px', maxWidth: '44px',
                                minWidth: isSmallScreen ? '32px' : '36px',
                                maxWidth: isSmallScreen ? '32px' : '44px',
                                my: 0.1,
                                boxShadow: isSelected ? `0 0 0 3px ${amber[700]}` : 'none',
                                backgroundColor: motor.status === 'livre' ? green[200] : green[900],
                                '&:hover': {
                                  backgroundColor: motor.status === 'livre' ? green[400] : green[800],
                                  boxShadow: isSelected ? `0 0 0 3px ${amber[700]}` : 'none',
                                },
                              }}
                              variant='contained'
                              onClick={() => {
                                if (!motores[index].agrupado_por) {
                                  setSelectedConfigMotor(motores[index])
                                }
                              }}
                              disabled={motor.agrupado_por}
                            >
                              <Typography variant='caption' textAlign='center'>{motor.numero_motor}</Typography>
                            </Button>
                          </Typography>
                        </Tooltip>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </Box>

            <Legend items={legendItems} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}