import { TextField, MenuItem, Box, CircularProgress, InputAdornment } from '@mui/material';

export function SelectEmpresaFilter({ defaultValue, empresas, onChangeValue, textHelper = null, enableOptionAll = false, isLoading = false }) {
  return (
    <Box
      sx={{
        // width: { xs: '100%', md: 'auto' },
        // paddingX: { xs: 2, md: 0 },
        // paddingBottom: { xs: 1, md: 0 },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <TextField
        select
        label="Empresa"
        placeholder='Selecione uma empresa'
        onChange={(e) => onChangeValue(e.target.value)}
        value={defaultValue ?? ''}
        helperText={textHelper !== false && "Selecione a empresa que deseja filtrar"}
        // helperText={`Selecione a empresa` + (textHelper ? ` que deseja visualizar ${isMasculino(textHelper) ? 'os' : 'as'} ${textHelper}` : '')}
        size='small'
        InputProps={{
          style: {
            backgroundColor: '#fff',
          },
          endAdornment: isLoading && (
            <InputAdornment position="end">
              <CircularProgress size={16} />
            </InputAdornment>
          )
        }}
        disabled={isLoading}
        sx={{ '& .MuiSelect-icon': { display: isLoading ? 'none' : 'block', } }}
      >
        {enableOptionAll && (
          <MenuItem value="all">Todas</MenuItem>
        )}

        {!isLoading ? (
          empresas.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.nomeFantasia}
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled>Carregando...</MenuItem>
        )}
      </TextField>
    </Box>
  )
}
