import { React } from 'react';
import {
    Groups,

} from '@mui/icons-material';
import MenuTitle from '../../../common/MenuTitle';


function MenuRH({ openMenuEpi, handleClick }) {


    return (<>
        <MenuTitle title="Controle de Pontos e Justificativas" icon={<Groups />} stateVar={openMenuEpi} fChangeState={() => { handleClick() }} />
    </>);
}

export default MenuRH;