import { useEffect, useState } from 'react';
import axios from 'axios'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form"
import {
    Box,
    Button,
    Container,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { Lock, Visibility, VisibilityOff, Email } from '@mui/icons-material';
import Logotipo from "../../../assets/images/stw_logo_preto.svg"
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { getFormErrorMessage, validatePasswordRegex } from '../../../configs/functions';

export function NewPassword() {
    const { exibirAlerta } = useCommonItems();

    const [newPasswordVisible, setNewPasswordVisible] = useState(false);
    const [storedEmail, setStoredEmail] = useState('');

    const {
        register,
        handleSubmit,
        setError,
        reset,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');

        if (token) {
            localStorage.setItem("@STW:forgot_password_token", token);
        }

        // Limpar os parâmetros da URL e redirecionar para uma URL limpa
        setTimeout(() => {
            navigate('/nova-senha');
        }, 500);
    }, [navigate, location.search]);

    const onSubmit = (data) => {
        const token = localStorage.getItem("@STW:forgot_password_token");

        if (!token) {
            exibirAlerta('Ops, ocorreu um erro', 'Token inválido.', 'error');
            return;
        }

        if (data.new_password.length < 8) {
            setError('new_password', { type: 'manual', message: 'A senha deve ter no mínimo 8 caracteres.' });
            return;
        }

        if (data.new_password !== data.new_password_confirmation) {
            setError('new_password_confirmation', { type: 'manual', message: 'As senhas não conferem.' });
            exibirAlerta('As senhas não conferem.', '', 'error');
            return;
        }

        if (validatePasswordRegex(data.new_password) === false) {
            setError('new_password', { type: 'manual', message: 'A senha deve conter ao menos uma letra e um número.' });
            return;
        }

        const dataToSend = {
            email: data.email,
            password: data.new_password
        };

        axios.post(`/newPass/token/${token}`, dataToSend)
            .then(function (response) {
                const message = response.data.message ?? 'Senha alterada com sucesso.';
                exibirAlerta(message, '', 'success');

                // delete token and email from sessionStorage
                localStorage.removeItem("@STW:forgot_password_token");
                localStorage.removeItem("@STW:forgot_password_email");

                // redirect to singIn
                setTimeout(() => {
                    navigate('/');
                }, 1000);
            })
            .catch(function (error) {
                const messageError = error.response.data.error ?? 'Ocorreu um erro.';
                exibirAlerta(messageError, '', 'error')
            });
    }

    useEffect(() => {
        let savedEmail = localStorage.getItem("@STW:forgot_password_email");
        setStoredEmail(savedEmail);
        const defaultValues = {
            email: savedEmail ?? '',
        };
        reset(defaultValues);
    }, []);

    return (
        <Container maxWidth={'sm'}>
            <Paper sx={{ p: 3, mt: 8, borderTopColor: "primary.main", borderTopStyle: 'solid', borderTopWidth: 8 }} elevation={4}>
                <Box
                    component={'img'}
                    src={Logotipo}
                    sx={{
                        height: 'auto',
                        width: '36%',
                        margin: "0 auto",
                        display: "block",
                        mb: 2
                    }}
                />

                <Typography variant='h2' component={'h1'} textAlign={'center'}>
                    Redefina sua senha
                </Typography>

                <Divider variant='middle' sx={{ mt: 2 }} />

                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth sx={{
                        px: {
                            xs: 1,
                            lg: 7
                        },
                        my: 3
                    }}>
                        <TextField
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            type='email'
                            name='email'
                            margin='dense'
                            label='Confirme seu Email'
                            placeholder='Confirme seu Email'
                            error={!!errors.email}
                            helperText={getFormErrorMessage(errors, 'email')}
                            disabled={storedEmail ? true : false}
                            {...register("email", { required: true })}
                            sx={{ mb: 2 }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Email />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <TextField
                            margin='dense'
                            placeholder='Deve ter no mínimo 8 caracteres.'
                            id="new_password"
                            label='Nova Senha'
                            name='new_password'
                            type={newPasswordVisible ? 'text' : 'password'}
                            error={!!errors.new_password}
                            helperText={getFormErrorMessage(errors, 'new_password')}
                            fullWidth
                            minLength={8}
                            {...register("new_password", { required: true })}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>,
                                endAdornment:
                                    <IconButton
                                        onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                                        {newPasswordVisible
                                            ? <VisibilityOff />
                                            : <Visibility />
                                        }
                                    </IconButton>
                            }}
                            sx={{ mb: 2 }}
                        />

                        <TextField
                            margin='dense'
                            placeholder='Deve ter no mínimo 8 caracteres.'
                            label='Confirme sua nova Senha'
                            id="new_password_confirmation"
                            name='new_password_confirmation'
                            type={newPasswordVisible ? 'text' : 'password'}
                            error={!!errors.new_password_confirmation}
                            helperText={getFormErrorMessage(errors, 'new_password_confirmation')}
                            fullWidth
                            {...register("new_password_confirmation", { required: true })}
                            InputProps={{
                                startAdornment:
                                    <InputAdornment position="start">
                                        <Lock />
                                    </InputAdornment>,
                                endAdornment:
                                    <IconButton onClick={() => setNewPasswordVisible(!newPasswordVisible)}>
                                        {newPasswordVisible
                                            ? <VisibilityOff />
                                            : <Visibility />
                                        }
                                    </IconButton>
                            }}
                            sx={{ mb: 2 }}
                        />
                    </FormControl>

                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Link to="/esqueceu-senha" style={{ textDecoration: 'none', color: '#000' }}>
                            <Button variant='contained' color='background'>
                                Voltar
                            </Button>
                        </Link>

                        <Button variant='contained' type='submit'>
                            Redefinir senha
                        </Button>
                    </Stack>
                </form>
            </Paper>
        </Container>
    );
}