import axios from "axios";
import {
    urlDepartamentos,
    urlAreas,
    urlSetores,
    urlCentroCustos,
    urlTipoDocumento,
    urlProntuarios,
    urlRegimeTrabalho
} from "../../../../../constants/endpoints";

// departamentos
const listaDepartamentos = (filter = null) => axios.get(urlDepartamentos, { params: filter })

const atualizaDepartamento = (id, data) => axios.patch(`${urlDepartamentos}/${id}`, data)

const cadastraDepartamento = (data) => axios.post(`${urlDepartamentos}`, data)

const manipulaDepartamento = (data, isEditAction) => isEditAction ? atualizaDepartamento(data.id, data) : cadastraDepartamento(data)

const deletaDepartamento = (id) => axios.delete(`${urlDepartamentos}/${id}`);

// areas
const listaAreas = (filter) => {
    return axios.get(urlAreas, { params: filter })
}

const atualizaArea = (id, data) => axios.patch(`${urlAreas}/${id}`, data)

const cadastraArea = (data) => axios.post(`${urlAreas}`, data)

const manipulaArea = (data, isEditAction) => isEditAction ? atualizaArea(data.id_area, data) : cadastraArea(data)

const deletaArea = (id) => axios.delete(`${urlAreas}/${id}`);

const listaAreasEmpresa = (data) => axios.get(urlAreas, {
    params: {
        id_empresa: data
    }
})

//setores
const listaSetores = (filter = null) => axios.get(urlSetores, { params: filter })

const atualizaSetor = (id, data) => axios.patch(`${urlSetores}/${id}`, data)

const cadastraSetor = (data) => axios.post(`${urlSetores}`, data)

const manipulaSetor = (data, isEditAction) => isEditAction ? atualizaSetor(data.id, data) : cadastraSetor(data)

const deletaSetor = (id) => axios.delete(`${urlSetores}/${id}`);

// centro de custos 
const listaCentroCustos = (filter = null) => axios.get(urlCentroCustos, { params: filter })

const atualizaCentroCusto = (id, data) => axios.patch(`${urlCentroCustos}/${id}`, data)

const cadastraCentroCusto = (data) => axios.post(`${urlCentroCustos}`, data)

const manipulaCentroCusto = (data, isEditAction) => isEditAction ? atualizaCentroCusto(data.id, data) : cadastraCentroCusto(data)

const deletaCentroCusto = (id) => axios.delete(`${urlCentroCustos}/${id}`);

// regimes
const listaRegimesTrabalho = (filter = null) => axios.get(urlRegimeTrabalho, { params: filter })
const atualizaRegimeTrabalho = (id, data) => axios.patch(`${urlRegimeTrabalho}/${id}`, data)
const cadastraRegimeTrabalho = (data) => axios.post(`${urlRegimeTrabalho}`, data)
const manipulaRegimeTrabalho = (data, isEditAction) => isEditAction ? atualizaRegimeTrabalho(data.id, data) : cadastraRegimeTrabalho(data)
const deletaRegimeTrabalho = (id) => axios.delete(`${urlRegimeTrabalho}/${id}`);

// tipoDocumento
const listaTiposDocumento = (filter = null) => axios.get(urlTipoDocumento, { params: filter })
const atualizaTipoDocumento = (id, data) => axios.patch(`${urlTipoDocumento}/${id}`, data)
const cadastraTipoDocumento = (data) => axios.post(`${urlTipoDocumento}`, data)
const manipulaTipoDocumento = (data, isEditAction) => isEditAction ? atualizaTipoDocumento(data.id, data) : cadastraTipoDocumento(data)
const deletaTipoDocumento = (id) => axios.delete(`${urlTipoDocumento}/${id}`);

// prontuarios
const listaProntuarios = (filter = null) => axios.get(urlProntuarios, { params: filter })
const atualizaProntuario = (id, data) => axios.post(`${urlProntuarios}/${id}`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
const cadastraProntuario = (data) => axios.post(urlProntuarios, data, { headers: { 'Content-Type': 'multipart/form-data' } })
const manipulaProntuario = (data, isEditAction) => isEditAction ? atualizaProntuario(data.id, data) : cadastraProntuario(data)
const deletaProntuario = (id) => axios.delete(`${urlProntuarios}/${id}`);

export {
    listaDepartamentos,
    atualizaDepartamento,
    manipulaDepartamento,
    deletaDepartamento,

    listaAreas,
    atualizaArea,
    manipulaArea,
    deletaArea,
    listaAreasEmpresa,

    listaSetores,
    atualizaSetor,
    cadastraSetor,
    manipulaSetor,
    deletaSetor,

    listaCentroCustos,
    atualizaCentroCusto,
    cadastraCentroCusto,
    manipulaCentroCusto,
    deletaCentroCusto,

    listaRegimesTrabalho,
    atualizaRegimeTrabalho,
    cadastraRegimeTrabalho,
    manipulaRegimeTrabalho,
    deletaRegimeTrabalho,

    listaTiposDocumento,
    atualizaTipoDocumento,
    cadastraTipoDocumento,
    manipulaTipoDocumento,
    deletaTipoDocumento,

    listaProntuarios,
    atualizaProntuario,
    cadastraProntuario,
    manipulaProntuario,
    deletaProntuario,
}
