import { Divider, List, Collapse } from '@mui/material';
import { LinkMenu } from '../../../common/LinkMenu';
import MenuTitle from '../../../common/MenuTitle';
import { useAppContext } from '../../../../contexts/AppContext';
import { CheckListIcon, ElectronicsIcon, HomePageIcon, NotificationIcon, PresentationIcon, ProtectiveGearAIcon, RequestServiceIcon, SafetyHatIcon, StatisticsIcon } from '../../../common/Icons';

function MenuEpis({ openMenuEpi, handleClick, isMobile }) {
    const { drawerOpen, handleCloseDrawer } = useAppContext();

    return (<>
        <Divider variant='middle' />
        <MenuTitle title="Gestão de EPI's" icon={<SafetyHatIcon />} stateVar={openMenuEpi} fChangeState={() => { handleClick() }} />
        <Collapse in={openMenuEpi} timeout="auto" unmountOnExit>
            <List>
                <LinkMenu
                    title='Página Inicial'
                    url='/'
                    icon={<HomePageIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Dashboard'
                    url='/epis'
                    icon={<PresentationIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Produtos'
                    url='/epis/produtos'
                    icon={<ProtectiveGearAIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Retiradas'
                    url='/epis/retiradas'
                    icon={<RequestServiceIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Diretrizes'
                    url='/epis/diretrizes'
                    icon={<CheckListIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Equipamentos'
                    url='/epis/equipamentos'
                    icon={<ElectronicsIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Alertas'
                    url='/epis/alertas'
                    icon={<NotificationIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />

                <LinkMenu
                    title='Relatórios'
                    url='/epis/relatorios'
                    icon={<StatisticsIcon />}
                    drawerOpen={drawerOpen}
                    handleCloseDrawer={handleCloseDrawer}
                    isMobileScreen={isMobile}
                />
            </List>
        </Collapse></>);
}

export default MenuEpis;