import React from 'react';
import PageTitle from '../../../common/PageTitle';
import { Book } from '@mui/icons-material';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import CardOption from '../../../common/CardOption';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';

function Trainings() {
    return (<>

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/treinamentos'>Treinamentos</LinkNavegacao >
                    <LinkNavegacao to='/treinamentos/treinamentos'>Treinamentos</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<Book fontSize='large' />} title='Treinamentos' />
                <Typography p>Nesta seção de Gestão de Produtos, cadastre, organize os treinamentos, seus instrutores e locais.</Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Cadastro de Treinamentos" description="Registre treinamentos essenciais e suas informações. Mantenha um histórico completo para garantir a conformidade e segurança nas atividades laborais." />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Categorias de Instrutores" description="Cadastre instrutores, sejam funcionários ou externos. Centralize informações para garantir a qualidade e a eficácia dos treinamentos oferecidos." />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Locais de Treinamentos" description="Cadastre locais para realização de treinamentos. Gerencie eficientemente os espaços disponíveis, proporcionando ambientes adequados para aprendizado e segurança." />
            </Grid>

        </Grid>

    </>);
}

export default Trainings;