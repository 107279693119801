import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaCentroCusto } from './configs/functions';
import { getDefaultValueSelectField } from '../../../../utils';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';

export function CostCenterForm({ open, setOpen, selectedCostCenter, empresas }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);

    const isEditAction = !!selectedCostCenter;

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedCostCenter.id;

            if (dirtyData?.id_empresas) {
                formDataToSend.id_empresas = Array.isArray(dirtyData.id_empresas) ? dirtyData.id_empresas : [dirtyData.id_empresas];
            }
        } else {
            delete data.id;
            formDataToSend = data;
            formDataToSend.id_empresas = Array.isArray(data.id_empresas) ? data.id_empresas : [data.id_empresas];
        }

        try {
            const res = await manipulaCentroCusto(formDataToSend, isEditAction);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.error(error);
            let errorMessage = "Erro ao cadastrar Centro de Custos.";
            if (error.response) {
                errorMessage = error.response.data.message
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        // preenche o formulário ao abrir p/ edicao
        if (open) {
            const defaultValues = {
                id: selectedCostCenter ? selectedCostCenter.id : '',
                nome: selectedCostCenter ? selectedCostCenter.nome : '',
                status: selectedCostCenter ? selectedCostCenter.status : 1,
                descricao: selectedCostCenter ? selectedCostCenter.descricao : '',
                codigo: selectedCostCenter ? selectedCostCenter.codigo : '',
                id_empresas: getDefaultValueSelectField(selectedCostCenter, selectedCostCenter?.empresa?.id, empresas),
            };

            reset(defaultValues);
        } else {
            reset();
        }
    }, [empresas, open, reset, selectedCostCenter]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Centro de Custos <strong>{selectedCostCenter.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar novo Centro de Custos</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Centro de Custos.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}
                            >
                                <Grid item xs={12} md={8}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Nome'
                                        placeholder='Digite o nome do Centro de Custos'
                                        name='nome'
                                        fullWidth
                                        {...register("nome", { required: true })}
                                        error={!!errors.nome}
                                        helperText={getFormErrorMessage(errors, 'nome')}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Código'
                                        placeholder='Digite o código'
                                        name='codigo'
                                        fullWidth
                                        {...register("codigo", { required: true })}
                                        error={!!errors.codigo}
                                        helperText={getFormErrorMessage(errors, 'codigo')}
                                        variant="outlined"
                                    />
                                </Grid>

                                {empresas &&
                                    <Grid item xs={12} md={6}>
                                        <TooltipSelectDisabled
                                            isDisabled={!(!!selectedCostCenter)}
                                            text="Não é possível alterar a Empresa de um Centro de Custo já cadastrado"
                                        >
                                            <Controller
                                                name='id_empresas'
                                                control={control}
                                                rules={{ required: true }}
                                                defaultValue={selectedCostCenter ? selectedCostCenter.empresa.id : ""}
                                                render={({ field: { ref, onChange, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        select
                                                        fullWidth
                                                        sx={{ mt: 1 }}
                                                        label="Empresa"
                                                        disabled={!!selectedCostCenter}
                                                        onChange={(e) => {
                                                            setValue('id_empresas', e.target.value, { shouldDirty: true })
                                                            if (e.target.value) clearErrors("id_empresas");
                                                        }}
                                                        error={!!errors.id_empresas}
                                                        helperText={getFormErrorMessage(errors, 'id_empresas')}
                                                    >
                                                        {empresas.map((option) => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.nomeFantasia}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </TooltipSelectDisabled>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <TextField
                                        InputLabelProps={{ shrink: true }}
                                        margin='dense'
                                        label='Descrição'
                                        placeholder='Digite a Descrição'
                                        name='descricao'
                                        fullWidth
                                        {...register("descricao")}
                                        variant="outlined"
                                        multiline
                                        rows={3}
                                        error={!!errors.descricao}
                                        helperText={getFormErrorMessage(errors, 'descricao')}
                                    />
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}