import { useEffect } from 'react';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { manipulaTipoDocumento } from './configs/functions';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getDefaultValueSelectField } from '../../../../utils';

export function DocumentTypeForm({ open, setOpen, documentType, empresas, queryKey }) {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const isEditAction = !!documentType;

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const { mutateAsync: manipulaTipoDocumentoFn, isPending } = useMutation({
    mutationFn: (data) => manipulaTipoDocumento(data, isEditAction),
  })

  async function handleFormSubmit(data) {
    try {
      let formDataToSend = {};

      if (isEditAction) {
        const dirtyFieldKeys = Object.keys(dirtyFields);

        const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
          if (data.hasOwnProperty(key)) {
            acc[key] = data[key];
          }
          return acc;
        }, {});

        if (Object.keys(dirtyData).length === 0) {
          exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
          setOpen(false);
          return;
        }

        formDataToSend = dirtyData;
        formDataToSend.id = documentType.id;
      } else {
        delete data.id;
        formDataToSend = data;
      }

      const response = await manipulaTipoDocumentoFn(formDataToSend);
      if (!response) {
        throw new Error('Erro ao cadastrar Tipo de Documento.');
      }

      // É necessário que o backend retorne o objeto criado/editado

      // buscar em cache os dados da requisição e atualizar
      // const cached = queryClient.getQueryData(queryKey);
      // const cachedData = cached.data;

      // if (cached) {
      //   if (isEditAction) {
      //     const updatedData = cachedData.data.map(item => {
      //       if (item.id === documentType.id) {
      //         for (const key in formDataToSend) {

      //           // if id_empresa change, remove item from list
      //           if (key === 'id_empresa') {
      //             if (documentType[key] !== formDataToSend[key]) {
      //               return null;
      //             }
      //           }

      //           item[key] = formDataToSend[key];
      //         }
      //       }
      //       return item;
      //     });

      //     queryClient.setQueryData(queryKey, {
      //       ...cached,
      //       data: {
      //         ...cachedData,
      //         data: updatedData.filter(item => item !== null)
      //       }
      //     });
      //   } else {
      //     const updatedData = [
      //       ...cachedData.data,
      //       {
      //         ...formDataToSend,

      //         // só será possível fazer corretamente quando backend retornar o objeto criado/editado

      //         // id: response.data.data.id,
      //         // createdAt: response.data.data.createdAt,
      //         // updatedAt: response.data.data.updatedAt,
      //       }
      //     ];

      //     const numTotalItems = cachedData.numero_total;

      //     queryClient.setQueryData(queryKey, {
      //       ...cached,
      //       data: {
      //         ...cachedData,
      //         data: updatedData,
      //         numero_total: numTotalItems + 1
      //       }
      //     });
      //   }
      // }

      // Enquanto o backend não retornar o objeto criado/editado, 
      // forçar o refetch para atualizar os dados

      // invalidate e refetch para atualizar os dados
      queryClient.invalidateQueries({ queryKey });

      exibirAlerta('Sucesso', response.data.message, 'success');
      setOpen(false);
    } catch (error) {
      console.error(error);
      let errorMessage = "Erro ao cadastrar Tipo de Documento.";
      if (error.response) {
        errorMessage = error.response.data.message
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    }
  }

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: documentType ? documentType.id : '',
        titulo: documentType ? documentType.titulo : '',
        observacao: documentType ? documentType.observacao : '',
        id_empresa: getDefaultValueSelectField(documentType, documentType?.id_empresa, empresas),
      };

      reset(defaultValues);
    } else {
      reset();
    }
  }, [documentType, empresas, open, reset]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Tipo de Documento <strong>{documentType.titulo}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Tipo de Documento</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Tipo de Documento.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Título'
                    placeholder='Digite o Título do Tipo de Documento'
                    fullWidth
                    {...register("titulo", { required: true })}
                    error={!!errors.titulo}
                    helperText={getFormErrorMessage(errors, 'titulo')}
                    variant="outlined"
                    autoComplete='off'
                    inputProps={{ form: { autoComplete: 'off' } }}
                  />
                </Grid>

                {empresas &&
                  <Grid item xs={12} md={6}>
                    <Controller
                      name='id_empresa'
                      control={control}
                      rules={{ required: true }}
                      defaultValue={documentType ? documentType.id_empresa : ""}
                      render={({ field: { ref, onChange, ...field } }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          sx={{ mt: 1 }}
                          label="Empresa"
                          onChange={(e) => {
                            setValue('id_empresa', e.target.value, { shouldDirty: true })
                            if (e.target.value) {
                              clearErrors("id_empresa");
                            }
                          }}
                          error={!!errors.id_empresa}
                          helperText={getFormErrorMessage(errors, 'id_empresa')}
                        >
                          {empresas.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.nomeFantasia}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                }

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Observação'
                    placeholder='Digite a Observação'
                    name='observacao'
                    fullWidth
                    {...register("observacao")}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.observacao}
                    helperText={getFormErrorMessage(errors, 'observacao')}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isPending} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}