import { Stack, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { blue } from '@mui/material/colors';

export const TutorialInstructions = ({ title, steps }) => {
  const renderSteps = (steps, listType = 'unordered') => {
    const ListComponent = listType === 'ordered' ? 'ol' : 'ul';

    return (
      <ListComponent>
        {steps.map((step, index) => (
          <li key={index}>
            {typeof step === 'string' ? (
              <Typography variant='body1' gutterBottom>{step}</Typography>
            ) : (
              <div>
                <Typography variant='body1' gutterBottom>{step[0]}</Typography>
                {renderSteps(step.slice(1), 'ordered')}
              </div>
            )}
          </li>
        ))}
      </ListComponent>
    );
  };

  return (
    <Stack spacing={2}>
      <Stack direction='row' spacing={1} alignItems='center'>
        <Info sx={{ color: blue[500] }} />
        <Typography variant='h5'>
          {title}
        </Typography>
      </Stack>
      {renderSteps(steps)}
    </Stack>
  );
};
