import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import { listaJustificativaDevolucao, atualizaJustificativaDevolucao, deletaJustificativaDevolucao, } from './config/functions';
import { EditIcon, TrashIcon, WritingDownIcon } from '../../../common/Icons';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { JustificativaDevolucaoForm } from './JustificativaDevolucaoForm';

const DevolucaoRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_justificativaDevolucao", "update_justificativaDevolucao"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const newStatus = active ? 0 : 1;
            const res = await atualizaJustificativaDevolucao(data.id, { status: newStatus })
            if (res) {
                const message = active ? 'Justificativa inativada com sucesso' : 'Justificativa ativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteItem = () => {
        const id = data.id;
        deletaJustificativaDevolucao(id)
            .then((response) => {
                const message = response.data.message ?? 'Justificativa inativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                <TableCell sx={{ flex: 2, border: 0 }}>{data.nome}</TableCell>
                <TableCell sx={{ flex: 2, border: 0 }}>{data.empresa.fantasyName}</TableCell>
                <TableCell sx={{ flex: 2, border: 0 }}>{data.descricao}</TableCell>
                <TableCell sx={{ flex: 2, border: 0 }}>{data.observacao}</TableCell>

                <TableCell sx={{ flex: 1, border: 0 }}>
                    <Switch checked={!!active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_justificativaDevolucao", "update_justificativaDevolucao"])} />
                </TableCell>

                <TableCell align='right' sx={{ flex: 1, border: 0, minWidth: 110 }}>
                    {hasPermission(["admin", "admin_justificativaDevolucao", "update_justificativaDevolucao"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>}

                    {hasPermission(["admin", "admin_justificativaDevolucao", "delete_justificativaDevolucao"]) &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>}
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir esta justificativa?'}
                title={`Excluir Justificativa ${data.nome}`}
                goAction={deleteItem}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

export function ListOfJustificativasDevolucao() {
    const { exibirAlerta } = useCommonItems();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [justificativas, setJustificativas] = useState([]);
    const [openForm, setOpenForm] = useState(false)
    const [empresas, setEmpresas] = useState([]);
    const [selectedJustificativa, setSelectedJustificativa] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const carregaJustificativas = async () => {
        const timer = setTimeout(() => setIsLoading(true), 500);
        try {
            const filterAllPaginated = {
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaJustificativaDevolucao(filterAllPaginated);
            setNumTotalItems(res?.data.numero_total);
            setJustificativas(res?.data.data);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar as Justificativas', 'error');
        } finally {
            clearTimeout(timer);
            setIsLoading(false);
        }
    }

    function handleEditArea(item) {
        setSelectedJustificativa(item);
        setOpenForm(true);
    }

    const updateCategoryStatus = (categoryId, status) => setJustificativas(
        justificativas.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            carregaJustificativas();
            setSelectedJustificativa(null);
        }
    }, [openForm, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_justificativaDevolucao", "list_justificativaDevolucao"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        listaEmpresas().then((response => setEmpresas(response?.data.data)));
    }, []);

    return (
        <>
            <Helmet title='Justificativas de Devolução' defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="end"
                spacing={3}
            >
                <Grid item xs={12} md={9}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                        <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                        <LinkNavegacao to='/epis/retiradas'>Retiradas</LinkNavegacao>
                        <Typography variant='span'>Justificativas de Devolução</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<WritingDownIcon fontSize='large' />}
                        title={'Justificativas de Devolução'}
                        description='Administre e Gerencie as justificativas de devolução das suas empresas.'
                    />
                </Grid>

                {hasPermission(["admin", "admin_justificativaDevolucao", "create_justificativaDevolucao"]) &&
                    <Grid item xs={12} md={3}>
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar Justificativa
                            </Button>
                        </Typography>
                    </Grid>
                }

                <Grid item xs={12}>
                    <TableContainer component={Paper}>
                        <Table aria-label="Justificativas de Devolução">
                            <TableHead>
                                <TableRow sx={{ display: 'flex' }}>
                                    <TableCell sx={{ flex: 2 }}>Nome</TableCell>
                                    <TableCell sx={{ flex: 2 }}>Empresa</TableCell>
                                    <TableCell sx={{ flex: 2 }}>Descrição</TableCell>
                                    <TableCell sx={{ flex: 2 }}>Observação</TableCell>
                                    <TableCell sx={{ flex: 1 }}>Status</TableCell>
                                    <TableCell sx={{ flex: 1, minWidth: 110 }} />
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {isLoading ? <LoadingTableRow />
                                    : justificativas && justificativas?.length > 0
                                        ? justificativas.map((item) =>
                                            <DevolucaoRow
                                                key={item.id}
                                                data={item}
                                                handleExibirAlerta={exibirAlerta}
                                                handleOpenEditForm={handleEditArea}
                                                handleUpdateStatus={updateCategoryStatus}
                                                updateData={carregaJustificativas}
                                            />
                                        )
                                        : <EmptyTableRow />
                                }
                            </TableBody>
                        </Table>

                        <CustomTablePagination
                            numTotalItems={numTotalItems}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            setPage={setPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableContainer>
                </Grid>
            </Grid>

            <JustificativaDevolucaoForm
                open={openForm}
                setOpen={setOpenForm}
                selectedJustificativa={selectedJustificativa}
                empresas={empresas}
            />
        </>
    );
}