import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Tooltip,
    IconButton,
    Card,
    CardContent,
    Box,
    Skeleton
} from '@mui/material';
import { Info, Home as HomeIcon } from '@mui/icons-material';
import { blue, grey, green } from '@mui/material/colors';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { ProgressCounter } from '../../../common/ProgressCounter';
import { getContrato } from '../contract/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { listaUsuarios } from '../people/configs/functions';
import { checkPermissionsAndRedirect } from '../../../../configs/functions';

function Details() {
    const { exibirAlerta } = useCommonItems();

    const { data: contratoData, isLoading: isLoadingContrato } = useQuery({
        queryKey: ['contrato'],
        queryFn: getContrato,
        onError: () => {
            exibirAlerta('Erro', 'Erro ao buscar contrato', 'error');
        },
    });

    const { data: colaboradoresData, isLoading: isLoadingCollaborators } = useQuery({
        queryKey: ['listOfCollaborators'],
        queryFn: () => listaUsuarios('colaborador'),
        select: (data) => data?.data?.numero_total || 0,
    });

    const { data: adminsData, isLoading: isLoadingAdmins } = useQuery({
        queryKey: ['listOfAdmins'],
        queryFn: () => listaUsuarios('administrador'),
        select: (data) => data?.data?.numero_total || 0,
    });

    const contrato = contratoData?.data?.data?.[contratoData?.data?.data.length - 1] ?? null;

    useEffect(() => {
        const requiredPermissionsView = ['admin'];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    return (
        <>
            <Helmet title="Detalhes do Contrato" defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
            >
                <Grid item xs={12}>
                    <Breadcrumbs>
                        <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                        <Typography variant='span'>Gerenciamento</Typography>
                        <Typography variant='span'>Detalhes do Contrato</Typography>
                    </Breadcrumbs>

                    <PageTitle
                        icon={<Info fontSize='large' color='primary' />}
                        title='Detalhes do Contrato'
                        description='Aqui você pode conferir os detalhes do seu contrato.'
                    />
                </Grid>

                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant='h6'>Meu Contrato:</Typography>
                    </Grid>

                    {isLoadingContrato || contrato ? (
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardContent>
                                    <Box sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography color={grey[700]}>
                                            Situação:
                                        </Typography>

                                        {isLoadingContrato
                                            ? <Skeleton variant="text" width={60} />
                                            : contrato.status
                                                ? <Typography color={green[500]} sx={{ fontWeight: 'bold' }}>Ativo</Typography>
                                                : <Typography color={grey[500]}>Inativo</Typography>
                                        }
                                    </Box>

                                    <Box sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography color={grey[700]}>Período:</Typography>

                                        {isLoadingContrato
                                            ? <Skeleton variant="text" width={180} />
                                            : (
                                                <Typography>
                                                    {dayjs(contrato.inicio_contrato).format('DD/MM/YYYY')}
                                                    <Typography variant='body' color={grey[500]}> - </Typography>
                                                    {dayjs(contrato.fim_contrato).format('DD/MM/YYYY')}
                                                </Typography>
                                            )}
                                    </Box>

                                    <Box sx={{ paddingY: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Typography color={grey[700]}>Mensalidade:</Typography>


                                        {isLoadingContrato
                                            ? <Skeleton variant="text" width={100} />
                                            : (
                                                <Typography>{Intl.NumberFormat('pt-BR', {
                                                    style: 'currency',
                                                    currency: 'BRL'
                                                }).format(Number(contrato.mensalidade))}
                                                </Typography>
                                            )}
                                    </Box>

                                    <Box sx={{ mt: 1, paddingTop: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography color={grey[700]}>
                                                Colaboradores:
                                            </Typography>

                                            <Tooltip
                                                title={
                                                    isLoadingContrato
                                                        ? ""
                                                        : `Limite de cadastro de colaboradores: ${contrato?.qtd_colaboradores}`
                                                }
                                            >
                                                <IconButton sx={{ marginLeft: 0.5, padding: 0 }}>
                                                    <Info sx={{ color: blue[500] }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>

                                        <ProgressCounter
                                            value={colaboradoresData || 0}
                                            limit={contrato?.qtd_colaboradores || 1000}
                                            isLoading={isLoadingCollaborators || isLoadingContrato}
                                        />
                                    </Box>

                                    <Box sx={{ mt: 1, paddingTop: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <Typography color={grey[700]}>
                                                Usuários Administradores:
                                            </Typography>
                                            <Tooltip
                                                title={
                                                    isLoadingContrato
                                                        ? ""
                                                        : `Limite de cadastro de usuários administrativos: ${contrato?.qtd_colaboradores_admin}`
                                                }
                                            >
                                                <IconButton sx={{ marginLeft: 0.5, padding: 0 }}><Info sx={{ color: blue[500] }} /></IconButton>
                                            </Tooltip>
                                        </Box>

                                        <ProgressCounter
                                            value={adminsData || 0}
                                            limit={contrato?.qtd_colaboradores_admin || 1000}
                                            isLoading={isLoadingAdmins || isLoadingContrato}
                                        />
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    ) :
                        <Grid item xs={12} alignContent={'center'}>
                            <Typography variant='h3'>
                                Nenhum contrato encontrado.
                            </Typography>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    );
}

export default Details;