import { Breadcrumbs, Grid } from '@mui/material';
import React from 'react';
import PageTitle from '../../../common/PageTitle';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import { VendingMachineIcon } from '../../../common/Icons';

function Equipments() {
    return (<>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}

        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/treinamentos'>Treinamentos</LinkNavegacao >
                    <LinkNavegacao to='/treinamentos/equipamentos'>Equipamentos</LinkNavegacao >
                </Breadcrumbs>
                <PageTitle icon={<VendingMachineIcon fontSize='large' />} title='Equipamentos' />
            </Grid>
        </Grid>
    </>);
}

export default Equipments;