import { useState, useEffect } from 'react';
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    FormHelperText,
    CircularProgress,
    Typography,
    TextField,
    Stack,
    Button,
    CardContent,
    Card,
    Collapse,
    Divider
} from '@mui/material';
import { CheckCircleOutlineTwoTone, WarningAmber } from '@mui/icons-material';
import { CalendarIcon } from '@mui/x-date-pickers';

function CompanyFields({ empresa, numEmpresas, GHEs, register, setValue, getValues, errors, clearErrors, replicateVidaUtil }) {
    const [isChecked, setIsChecked] = useState(!!getValues(`id_empresas.${empresa.id}`));

    const GHEsEmpresa = GHEs.filter((ghe) => ghe.id_empresa === empresa.id);

    function handleCheck(e) {
        const isChecked = e.target.checked;
        const value = isChecked ? empresa.id : false;
        setValue(`id_empresas.${empresa.id}`, value);
        setIsChecked(isChecked);

        if (!isChecked) {
            setValue(`vida_util.${empresa.id}`, '');
            GHEsEmpresa.forEach((ghe) => {
                setValue(`gruposSelecionadosCriarPermissao[${ghe.id}][status]`, false);
            });
        }
    }

    useEffect(() => {
        setIsChecked(!!getValues(`id_empresas.${empresa.id}`));
    }, [getValues, empresa.id]);

    return (
        <FormGroup>
            <Stack spacing={1} direction="row" alignItems="start" justifyContent="space-between" my={1} width={'100%'} flexWrap={'wrap'}>
                <Card variant='elevation' sx={{ width: '100%', paddingBottom: 0 }}>
                    <CardContent
                        sx={{
                            paddingX: 1,
                            paddingY: isChecked ? 1 : 0,
                            paddingBottom: 0,
                            "&:last-child": {
                                paddingBottom: isChecked ? 3 : 0,
                            }
                        }}
                    >
                        <Box sx={{ width: '100%', padding: 1, borderRadius: 1 }}>
                            <FormControlLabel
                                key={`check${empresa.id}`}
                                label={empresa.nomeFantasia}
                                control={
                                    <Checkbox
                                        defaultChecked={isChecked}
                                        {...register(`id_empresas.${empresa.id}`)}
                                        onChange={handleCheck}
                                    />
                                }
                                sx={{ width: '100%' }}
                            />
                        </Box>

                        <Collapse in={isChecked} timeout="auto" unmountOnExit>
                            <Divider sx={{ mb: 2 }} />

                            <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='stretch' gap={2} width={'100%'} flexWrap={'wrap'}>
                                {GHEsEmpresa.length > 0 && (
                                    <Card variant="outlined" sx={{ flex: 1 }}>
                                        <CardContent>
                                            <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                <Stack direction="row" alignItems="center">
                                                    <WarningAmber sx={{ mr: 1 }} color={isChecked ? 'warning' : 'disabled'} />
                                                    GHE's da empresa relacionados com a Categoria selecionada
                                                </Stack>
                                            </Typography>

                                            <Typography variant="body2" color="textSecondary" mt={1}>
                                                Selecione os grupos que deseja ativar a permissão ao cadastrar o produto.
                                            </Typography>

                                            <Stack direction="column" alignItems="start" justifyContent="space-between" width={'100%'} flexWrap={'wrap'}>
                                                {GHEsEmpresa.map((ghe) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`ghe${ghe.id}`}
                                                            label={ghe.nome}
                                                            disabled={!isChecked}
                                                            control={
                                                                <Checkbox
                                                                    {...register(`gruposSelecionadosCriarPermissao[${ghe.id}][status]`)}
                                                                    defaultChecked={getValues(`gruposSelecionadosCriarPermissao[${ghe.id}][status]`)}
                                                                    onChange={(e) => {
                                                                        setValue(`gruposSelecionadosCriarPermissao[${ghe.id}][status]`, !!e.target.checked);
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    );
                                                })}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                )}

                                <Card variant="outlined" sx={{ flex: GHEsEmpresa.length > 0 ? 1 : 'inherit' }}>
                                    <CardContent>
                                        <Typography sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Stack direction="row" alignItems="center">
                                                <CalendarIcon sx={{ mr: 1 }} color={isChecked ? 'info' : 'disabled'} />
                                                Digite a Vida Útil do EPI para a empresa
                                            </Stack>
                                        </Typography>

                                        <Typography variant="body2" color="textSecondary" mt={1} mb={3}>
                                            A vida útil do EPI é o tempo que o EPI pode ser utilizado antes de ser descartado.
                                        </Typography>

                                        <TextField
                                            label="Vida Útil do EPI"
                                            variant="outlined"
                                            size="small"
                                            {...register(`vida_util.${empresa.id}`, { required: isChecked })}
                                            onChange={(e) => {
                                                setValue(`vida_util.${empresa.id}`, e.target.value)
                                                clearErrors(`vida_util.${empresa.id}`);
                                            }}
                                            disabled={!isChecked}
                                            type="number"
                                            InputProps={{
                                                endAdornment: <Typography color="textSecondary">dias</Typography>,
                                            }}
                                            error={errors[`vida_util`] && !!errors[`vida_util`][empresa.id]}
                                            helperText={errors[`vida_util`] && errors[`vida_util`][empresa.id]?.message}
                                            inputProps={{ min: 1 }}
                                            InputLabelProps={{
                                                shrink: !!getValues(`vida_util.${empresa.id}`) || isChecked,
                                            }}
                                            sx={{ display: 'block', mb: 1 }}
                                        />

                                        {numEmpresas > 1 && (
                                            <Button
                                                variant="text"
                                                size="small"
                                                onClick={() => replicateVidaUtil(getValues(`vida_util.${empresa.id}`))}
                                                startIcon={<CheckCircleOutlineTwoTone />}
                                                disabled={!getValues(`vida_util.${empresa.id}`)}
                                            >
                                                Replicar valor para todas empresas
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Stack>
                        </Collapse>
                    </CardContent>
                </Card>
            </Stack>
        </FormGroup>
    )
}

function CompaniesCheckbox({ empresasAssociadas, GHEs, register, setValue, getValues, errors, clearErrors }) {

    const replicateVidaUtil = (vidaUtil) => {
        empresasAssociadas.forEach((empresa) => {
            const isChecked = getValues(`id_empresas.${empresa.id}`);
            if (isChecked) {
                setValue(`vida_util.${empresa.id}`, vidaUtil);
            }
        });
    };

    const numEmpresas = empresasAssociadas ? empresasAssociadas.length : 0;

    return (
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <FormControl sx={{ mx: 1 }} component="fieldset" variant="standard">
                <FormLabel component="legend" sx={{ mb: 1 }}>
                    Selecione as empresas as quais deverão ser relacionadas ao seu Produto:
                </FormLabel>
                <>
                    {empresasAssociadas ? empresasAssociadas.map((empresa) => (
                        <FormControl key={empresa.id}>
                            <CompanyFields
                                empresa={empresa}
                                numEmpresas={numEmpresas}
                                GHEs={GHEs}
                                register={register}
                                setValue={setValue}
                                getValues={getValues}
                                errors={errors}
                                clearErrors={clearErrors}
                                replicateVidaUtil={replicateVidaUtil}
                            />
                        </FormControl>
                    ))
                        : <Typography variant='body2' sx={{ mt: 2 }}><CircularProgress size={20} /> Carregando empresas...</Typography>
                    }
                </>
                <FormHelperText>Selecione ao menos uma empresa</FormHelperText>
            </FormControl>
        </Box>
    );
}

export default CompaniesCheckbox;
