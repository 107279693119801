import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
    Stack,
} from '@mui/material';
import { Add, Home as HomeIcon } from '@mui/icons-material';
import { atualizaCategoria, deletaCategoria, listaCategorias } from './configs/functions';
import { DiversityIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import { hasPermission, checkPermissionsAndRedirect } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import ConfirmDialog from '../../../common/ConfirmDialog';
import CategoryForm from './CategoryForm';
import { useAppContext } from '../../../../contexts/AppContext';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';

const Row = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status);
    const [openDialog, setOpenDialog] = useState(false);
    // const [open, setOpen] = useState(false)

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_categorias", "update_categorias"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaCategoria(data.categorias_id[0], { status: !active })
            if (res) {
                const message = active ? 'Categoria inativado com sucesso' : 'Categoria ativado com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.categorias_id[0], !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteCategory = () => {
        const id = data.categorias_id[0];
        deletaCategoria(id)
            .then((response) => {
                const message = response.data.message ?? 'Categoria inativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid rgba(224, 224, 224, 1)',
                }}
            >
                <TableCell sx={{ flex: 1, border: 0 }}>{data.categorias_id[0]}</TableCell>
                <TableCell sx={{ flex: 2, border: 0 }}>{data.nome}</TableCell>
                <TableCell sx={{ flex: 3, border: 0 }}>{data.descricao}</TableCell>

                <TableCell sx={{ flex: 1, border: 0 }}>
                    <Switch checked={!!active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_categorias", "update_categorias"])} />
                </TableCell>

                <TableCell align='right' sx={{ flex: 1, border: 0, minWidth: 110 }}>
                    {hasPermission(["admin", "admin_categorias", "update_categorias"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>}

                    {hasPermission(["admin", "admin_categorias", "delete_categorias"]) &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>}
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir esta categoria?'}
                title={'Excluir Categoria'}
                goAction={deleteCategory}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

function ListOfCategories() {
    const { exibirAlerta } = useCommonItems();
    const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [categorias, setCategorias] = useState([]);
    const [openForm, setOpenForm] = useState(false)
    const [category, setCategory] = useState(false);
    const [empresas, setEmpresas] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedEmpresaId, setSelectedEmpresaId] = useState('');

    const carregaCategorias = async () => {
        const timer = setTimeout(() => setIsLoading(true), 500);
        try {
            const res = await listaCategorias(selectedEmpresaId, page * rowsPerPage, rowsPerPage);
            setNumTotalItems(res?.data.numero_total);
            setCategorias(res?.data.data);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar as Categorias', 'error');
        } finally {
            clearTimeout(timer);
            setIsLoading(false);
        }
    }

    function handleEditCategory(item) {
        setCategory(item);
        setOpenForm(true);
    }

    const updateCategoryStatus = (categoryId, status) => setCategorias(
        categorias.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            if (selectedEmpresaId) {
                carregaCategorias();
            }
            setCategory(null);
        }
    }, [openForm, selectedEmpresaId, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_categorias", "list_categorias"];
        checkPermissionsAndRedirect(requiredPermissionsView);

        const selectedEmpresaIdSession = getEmpresaIdSession();
        if (selectedEmpresaIdSession) {
            setSelectedEmpresaId(selectedEmpresaIdSession);
        }

        listaEmpresas()
            .then((response => {
                const empresasData = response?.data.data;
                setEmpresas(empresasData);
                setIsLoading(true);

                if (!selectedEmpresaIdSession) {
                    setSelectedEmpresaId(empresasData[0].id);
                    setEmpresaIdSession(empresasData[0].id);
                }
            })).finally(() => {
                setIsLoading(false);
            });
    }, []);

    return (<>
        <Helmet title="Categorias" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <LinkNavegacao to='/epis/produtos'>Produtos</LinkNavegacao >
                    <Typography variant='span'>Categorias</Typography>
                </Breadcrumbs>

                <PageTitle
                    icon={<DiversityIcon fontSize='large' />}
                    title='Categorias'
                    description='Administre as categoriais disponíveis para seus produtos.'
                />
            </Grid>

            <Grid item xs={12}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
                    <SelectEmpresaFilter
                        empresas={empresas}
                        defaultValue={selectedEmpresaId}
                        onChangeValue={(value) => {
                            setSelectedEmpresaId(value);
                            setEmpresaIdSession(value);
                            setPage(0);
                        }}
                    />

                    {hasPermission(["admin", "admin_categorias", "create_categorias"]) &&
                        <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                            <Button
                                variant="contained"
                                startIcon={<Add />}
                                onClick={() => setOpenForm(true)}
                            >
                                Cadastrar nova categoria
                            </Button>
                        </Typography>
                    }
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Categorias">
                        <TableHead>
                            <TableRow sx={{ display: 'flex' }}>
                                <TableCell sx={{ flex: 1 }}>ID</TableCell>
                                <TableCell sx={{ flex: 2 }}>Categoria</TableCell>
                                <TableCell sx={{ flex: 3 }}>Descrição</TableCell>
                                <TableCell sx={{ flex: 1 }}>Status</TableCell>
                                <TableCell sx={{ flex: 1, minWidth: 110 }} />
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : categorias && categorias?.length > 0
                                    ? categorias.map((item) =>
                                        <Row
                                            key={item.categorias_id[0]}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditCategory}
                                            handleUpdateStatus={updateCategoryStatus}
                                            updateData={carregaCategorias}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>

        </Grid>

        <CategoryForm open={openForm} setOpen={setOpenForm} category={category} id_empresas={empresas} />
    </>);
}

export default ListOfCategories;
