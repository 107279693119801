import { useCallback, useEffect, useState } from 'react';


function useReCaptchaV3(siteKey) {
  const [isReCaptchaReady, setIsReCaptchaReady] = useState(false);

  useEffect(() => {
    if (window.grecaptcha) {
      setIsReCaptchaReady(true);
    } else {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
      script.async = true;
      script.defer = true;
      script.id = 'recaptcha';
      document.body.appendChild(script);
      script.onload = () => {
        setIsReCaptchaReady(true);
      };
    }
  }, [siteKey])

  const executeReCaptcha = useCallback(async action => {
    if (isReCaptchaReady && window.grecaptcha) {
      try {
        const response = await window.grecaptcha.execute(siteKey, { action });
        return response;
      } catch (error) {
        console.log('Error executing reCaptcha:', error);
        console.error('Error executing reCaptcha:', error);
        return null;
      }
    }
  }, [isReCaptchaReady, siteKey]);

  return executeReCaptcha;
}

export default useReCaptchaV3;