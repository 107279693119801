import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  Switch,
  IconButton,
  Stack
} from '@mui/material';
import { Add, Home } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { EditIcon, StockIcon, TrashIcon } from '../../../common/Icons';
import { atualizaEstoque, carregaEstoques, deletaEstoque } from './configs/functions';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { listaEmpresas, listaEmpresasHasStockModule } from '../../management/companies/configs/functions';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { StockForm } from './components/StockForm';
import { useAppContext } from '../../../../contexts/AppContext';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';

const StockRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData, empresaHasModule }) => {
  const [active, setActive] = useState(data.status[0]);
  const [openDialog, setOpenDialog] = useState(false);

  async function handleToggleStatus() {
    if (!hasPermission(["admin", "admin_estoques", "update_estoques"])) {
      handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
      return false;
    }

    try {
      const res = await atualizaEstoque(data.estoques_id[0], { status: !active })
      if (res) {
        const message = active ? 'Estoque inativado com sucesso' : 'Estoque ativado com sucesso'
        handleExibirAlerta('Sucesso', message, 'success')
        setActive(!active)
        handleUpdateStatus(data.estoques_id[0], !data.status[0])
      }
    } catch (error) {
      const messageError = error.response.data.error ?? 'Erro ao executar operação'
      handleExibirAlerta('Erro', messageError, 'error')
    }
  }

  const handleDelete = () => {
    const id = data.estoques_id[0];
    deletaEstoque(id)
      .then((response) => {
        const message = response.data.message ?? 'Estoque inativado com sucesso';
        handleExibirAlerta('Sucesso', message, 'success');
        handleUpdateStatus(id, false);
        setActive(false);
        updateData();
      }).catch((error) => {
        const message = error.response.data.message ?? 'Erro ao executar operação'
        handleExibirAlerta('Erro', message, 'error');
      })

    setOpenDialog(false)
  }

  return (
    <>
      <TableRow>
        <TableCell>{data.nome}</TableCell>
        <TableCell>{data.descricao}</TableCell>

        <TableCell>
          <Switch
            checked={!!active}
            onChange={handleToggleStatus}
            disabled={!hasPermission(["admin", "admin_estoques", "update_estoques"]) || !empresaHasModule}
          />
        </TableCell>

        {empresaHasModule &&
          <TableCell align='right' sx={{ minWidth: 110 }}>
            {hasPermission(["admin", "admin_estoques", "update_estoques"]) &&
              <IconButton
                aria-label="Editar"
                onClick={() => handleOpenEditForm(data)}
              >
                <EditIcon />
              </IconButton>}

            {hasPermission(["admin", "admin_estoques", "delete_estoques"]) &&
              <IconButton
                aria-label="Excluir"
                onClick={() => setOpenDialog(true)}
              >
                <TrashIcon />
              </IconButton>}
          </TableCell>
        }
      </TableRow>

      <ConfirmDialog
        description={'Tem certeza que deseja excluir esta estoque?'}
        title={'Excluir Estoque'}
        goAction={handleDelete}
        handleClose={setOpenDialog}
        state={openDialog}
      />
    </>
  )
}

export function ListOfStock() {
  const { exibirAlerta } = useCommonItems();
  const { getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(false);
  const [openForm, setOpenForm] = useState(false)
  const [empresas, setEmpresas] = useState([]);
  const [empresasHasModule, setEmpresasHasModule] = useState([]);
  const [empresaHasModule, setEmpresaHasModule] = useState(false);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

  async function loadStocks() {
    const timer = setTimeout(() => setIsLoading(true), 500);

    try {
      const filterAllPaginated = {
        limit: rowsPerPage,
        offset: page * rowsPerPage,
        id_empresas: Array.isArray(selectedEmpresaId) ? selectedEmpresaId : [selectedEmpresaId]
      };
      const response = await carregaEstoques(filterAllPaginated);
      setNumTotalItems(response.data.numero_total);
      setStocks(response.data.data);
    } catch (error) {
      const messageError = error.response.data.error ?? 'Erro ao carregar dados'
      exibirAlerta('Erro', messageError, 'error')
    } finally {
      clearTimeout(timer);
      setIsLoading(false);
    }
  }

  async function getEmpresasHasStockModule() {
    try {
      const response = await listaEmpresasHasStockModule();
      if (response.data) {
        setEmpresasHasModule(response.data.empresas_id);
      }
    } catch (error) {
      const messageError = error.response.data.error ?? 'Erro ao carregar dados'
      exibirAlerta('Erro', messageError, 'error')
    }
  }

  function handleEdit(item) {
    setSelectedStock(item);
    setOpenForm(true);
  }

  const updateStockStatus = (stockId, status) => setStocks(
    stocks.map((item) => {
      if (item.id === stockId) {
        item.status[0] = status;
      }
      return item;
    })
  );

  useEffect(() => {
    if (!openForm) {
      if (selectedEmpresaId) {
        loadStocks();
      }
      setSelectedStock(null);
    }
  }, [selectedEmpresaId, openForm, page, rowsPerPage]);

  useEffect(() => {
    setEmpresaHasModule(empresasHasModule.includes(parseInt(selectedEmpresaId)));
  }, [empresasHasModule, selectedEmpresaId]);

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_estoques", "list_estoques"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    const selectedEmpresaIdSession = getEmpresaIdSession();
    if (selectedEmpresaIdSession) {
      setSelectedEmpresaId(selectedEmpresaIdSession);
    }

    listaEmpresas()
      .then((response => {
        console.log('useEffect ~ response:', response);
        const empresasData = response?.data.data;
        setEmpresas(empresasData);
        setIsLoading(true);

        if (!selectedEmpresaIdSession) {
          setSelectedEmpresaId(empresasData[0].id);
          setEmpresaIdSession(empresasData[0].id);
        }
      })).finally(() => {
        setIsLoading(false);

        getEmpresasHasStockModule();
      });
  }, []);

  return (
    <>
      <Helmet title="Estoques" defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to="/"><Home fontSize="small" /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento</Typography>
            <LinkNavegacao to="/gerenciamento/organizacao">Organização</LinkNavegacao>
            <Typography variant='span'>Gestão de Estoque</Typography>
          </Breadcrumbs>
          <PageTitle icon={<StockIcon fontSize='large' />} title='Estoque' description='Administre os Estoque das empresas.' />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent={'space-between'}>
            <SelectEmpresaFilter
              empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
              }}
            />

            {hasPermission(["admin", "admin_estoques", "create_estoques"]) &&
              <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                <Button
                  variant="contained"
                  startIcon={<Add />}
                  onClick={() => setOpenForm(true)}
                >
                  Cadastrar Estoque
                </Button>
              </Typography>
            }
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Estoques">
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell>Status</TableCell>
                  {empresaHasModule && <TableCell sx={{ minWidth: { md: 120 } }}></TableCell>}
                </TableRow>
              </TableHead>

              <TableBody>
                {stocks?.length > 0
                  ? stocks.map((item) =>
                    <StockRow
                      key={item.id}
                      data={item}
                      handleExibirAlerta={exibirAlerta}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleUpdateStatus={updateStockStatus}
                      updateData={loadStocks}
                      empresaHasModule={empresaHasModule}
                    />
                  )
                  : isLoading ? <LoadingTableRow /> : <EmptyTableRow />
                }
              </TableBody>
            </Table>

            <CustomTablePagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              setPage={setPage}
              setRowsPerPage={setRowsPerPage}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <StockForm open={openForm} setOpen={setOpenForm} selectedStock={selectedStock} empresas={empresas} empresasHasModule={empresasHasModule} />
    </>
  );
}