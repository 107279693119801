import { Controller } from 'react-hook-form';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { debounceOnInputChange } from '../../utils';

export function PaginatedUserAutocomplete({
  name,
  control,
  rules,
  options,
  loading,
  label,
  helperText,
  error,
  onChange,
  onInput,
  loadOptionsFn,
  getOptionLabel,
  renderOption,
  hasMore,
  defaultValue = "",
  noOptionsText = "Nenhum resultado encontrado",
  loadingText = "Carregando...",
  marginTop = 0,
  disabled = false,
  textFieldSize = "medium",
  textFieldStyle = {},
}) {
  // Função para detectar o scroll no popup do Autocomplete
  const handleScrollSupervisorsAutoComplete = (event) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    if ((scrollTop + clientHeight) + 1 >= scrollHeight && hasMore) {
      loadOptionsFn();
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      disabled={disabled}
      defaultValue={defaultValue}
      render={({ field: { ref, value, onChange: controllerOnChange, ...field } }) => (
        <Autocomplete
          {...field}
          value={value}
          options={options}
          loading={loading}
          getOptionLabel={getOptionLabel}
          renderOption={renderOption}
          onChange={(event, newValue) => {
            onChange(event, newValue);
          }}
          onInput={debounceOnInputChange((event) => onInput(event), 500)}
          filterOptions={(options) => options} // Disable client-side filtering
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{ mt: marginTop }}
              size={textFieldSize}
              label={label}
              error={error}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                style: textFieldStyle,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{
            onScroll: handleScrollSupervisorsAutoComplete,
            style: { maxHeight: 250 }
          }}
          noOptionsText={noOptionsText}
          loadingText={loadingText}
        />
      )}
    />
  );
};
