import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Breadcrumbs,
    Typography,
    Button,
    TableCell,
    TableHead,
    TableRow,
    TableContainer,
    Table,
    Paper,
    TableBody,
    IconButton,
    Switch,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import LinkNavegacao from '../../../common/Link';
import PageTitle from '../../../common/PageTitle';
import { EditIcon, SafetyVestIcon, TrashIcon } from '../../../common/Icons';
import { atualizaTamanhoEVariacao, deletaTamanhoEVariacao, listaTamanhosEVariacoes } from './configs/functions';
import { checkPermissionsAndRedirect, hasPermission } from '../../../../configs/functions';
import { LoadingTableRow } from '../../../common/LoadingTableRow';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { CustomTablePagination } from '../../../common/CustomTablePagination';
import { SizeForm } from './SizeForm';

const SizeRow = ({ data, handleExibirAlerta, handleOpenEditForm, handleUpdateStatus, updateData }) => {
    const [active, setActive] = useState(data.status);
    const [openDialog, setOpenDialog] = useState(false);

    async function handleToggleStatus() {
        if (!hasPermission(["admin", "admin_variacoes", "update_variacoes"])) {
            handleExibirAlerta('Erro', 'Você não tem permissão para executar esta ação.', 'error')
            return false;
        }

        try {
            const res = await atualizaTamanhoEVariacao(data.id, { status: !active })
            if (res) {
                const message = active ? 'Variação inativada com sucesso' : 'Variação ativada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success')
                setActive(!active)
                handleUpdateStatus(data.id, !data.status)
            }
        } catch (error) {
            handleExibirAlerta('Erro', 'Erro ao alterar status', 'error')
            console.log(error)
        }
    }

    const deleteCostCenter = () => {
        const id = data.id;
        deletaTamanhoEVariacao(id)
            .then((response) => {
                const message = response.data.message ?? 'Variação deletada com sucesso'
                handleExibirAlerta('Sucesso', message, 'success');
                handleUpdateStatus(id, false);
                setActive(false);
                updateData();
            }).catch((error) => {
                const message = error.response.data.message ?? 'Erro ao executar operação'
                handleExibirAlerta('Erro', message, 'error');
            })

        setOpenDialog(false)
    }

    return (
        <>
            <TableRow key={data.id}>
                <TableCell>{data.tipo}</TableCell>
                <TableCell>{data.descricao}</TableCell>
                <TableCell>{data.observacao}</TableCell>

                <TableCell>
                    <Switch checked={active} onChange={handleToggleStatus} disabled={!hasPermission(["admin", "admin_variacoes", "update_variacoes"])} />
                </TableCell>

                <TableCell align='right' sx={{ minWidth: 110 }}>
                    {hasPermission(["admin", "admin_variacoes", "update_variacoes"]) &&
                        <IconButton
                            aria-label="Editar"
                            onClick={() => handleOpenEditForm(data)}
                        >
                            <EditIcon />
                        </IconButton>
                    }

                    {hasPermission(["admin", "admin_variacoes", "delete_variacoes"]) &&
                        <IconButton
                            aria-label="Excluir"
                            onClick={() => setOpenDialog(true)}
                        >
                            <TrashIcon />
                        </IconButton>
                    }
                </TableCell>
            </TableRow>

            <ConfirmDialog
                description={'Tem certeza que deseja excluir esta Variação?'}
                title={`Excluir Variação ${data.tipo}`}
                goAction={deleteCostCenter}
                handleClose={setOpenDialog}
                state={openDialog}
            />
        </>
    )
}

export function ListOfSizes() {
    const { exibirAlerta } = useCommonItems();

    // estados para controle de paginacao
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [numTotalItems, setNumTotalItems] = useState(0);

    const [sizes, setSizes] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedCostCenter, setSelectedCostCenter] = useState(null);

    const carregaVariacoes = async () => {
        setIsLoading(true);
        try {
            const filterAllPaginated = {
                limit: rowsPerPage,
                offset: page * rowsPerPage
            };
            const res = await listaTamanhosEVariacoes(filterAllPaginated);
            setSizes(res.data.data);
            setNumTotalItems(res.data.numero_total);
        } catch (error) {
            exibirAlerta('Erro', 'Erro ao carregar Variações', 'error');
        } finally {
            setIsLoading(false);
        }
    }

    function handleEditCostCenter(item) {
        setSelectedCostCenter(item);
        setOpenForm(true);
    }

    const updateCostCenterStatus = (categoryId, status) => setSizes(
        sizes.map((item) => {
            if (item.id === categoryId) {
                item.status = status;
            }
            return item;
        })
    );

    useEffect(() => {
        if (!openForm) {
            carregaVariacoes();
            setSelectedCostCenter(null);
        }
    }, [openForm, page, rowsPerPage]);

    useEffect(() => {
        const requiredPermissionsView = ["admin", "admin_variacoes", "list_variacoes"];
        checkPermissionsAndRedirect(requiredPermissionsView);
    }, []);

    return (<>
        <Helmet title="Tamanhos e Variações" defer={false} />

        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="end"
            spacing={3}
        >
            <Grid item xs={12} md={8}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao >
                    <LinkNavegacao to='/epis/produtos'>Produtos</LinkNavegacao >
                    <Typography variant='span'>Tamanhos e Variações</Typography>
                </Breadcrumbs>
                <PageTitle
                    icon={<SafetyVestIcon fontSize='large' />}
                    title='Tamanhos e Variações'
                    description='Administre os Tamanhos e Variações disponíveis para seus produtos.'
                />
            </Grid>

            <Grid item xs={4}>
                <Typography sx={{ textAlign: { xs: 'center', md: 'right' } }}>
                    <Button
                        variant="contained"
                        startIcon={<Add />}
                        onClick={() => setOpenForm(true)}
                        disabled={!hasPermission(["admin", "admin_variacoes", "create_variacoes"])}
                    >
                        Cadastrar Variação
                    </Button>
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="Variações">
                        <TableHead>
                            <TableRow>
                                <TableCell>Tamanho/Variação</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Observação</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isLoading ? <LoadingTableRow />
                                : sizes && sizes?.length > 0
                                    ? sizes.map((item) =>
                                        <SizeRow
                                            key={item.id}
                                            data={item}
                                            handleExibirAlerta={exibirAlerta}
                                            handleOpenEditForm={handleEditCostCenter}
                                            handleUpdateStatus={updateCostCenterStatus}
                                            updateData={carregaVariacoes}
                                        />
                                    )
                                    : <EmptyTableRow />
                            }
                        </TableBody>
                    </Table>

                    <CustomTablePagination
                        numTotalItems={numTotalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </TableContainer>
            </Grid>
        </Grid>

        <SizeForm
            open={openForm}
            setOpen={setOpenForm}
            selectedCostCenter={selectedCostCenter}
        />
    </>);
}