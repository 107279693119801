import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
    Grid,
    Typography,
    Stack,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import PageTitle from '../../../common/PageTitle';
import { HomePageIcon } from '../../../common/Icons';
import { useAppContext } from '../../../../contexts/AppContext';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { getTotalCAsVencer, getTotalCAsVencidos, getTotalEpisVencidos, getTotalEquipDesconectados, getTotalEquipEstoqueBaixo } from '../Dashboard/configs/functions';
import SummaryEpis from '../Dashboard/summary';
import CardOption from '../../../common/CardOption';
import { hasPermission } from '../../../../configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';

function Home() {
    const { dadosUsuario } = useAppContext();
    const { exibirAlerta } = useCommonItems();

    const isAdmin = hasPermission(["admin", "admin_sempher"]);
    const widthCard = isAdmin ? 6 : 4;

    const [isLoading, setIsLoading] = useState(true);
    const [summaryData, setSummaryData] = useState({
        caVencidos: 0,
        caAVencer: 0,
        funcEpisVencidos: 0,
        equipEstoqueBaixo: 0,
        equipDesconectados: 0,
    });
    const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);

    const fetchTotalEpisVencidos = async (companyId) => {
        const response = await getTotalEpisVencidos(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            funcEpisVencidos: response.data.quantidade,
        }));
    }

    const fetchTotalCAsVencidos = async (companyId) => {
        const response = await getTotalCAsVencidos(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            caVencidos: response.data.quantidade,
        }));
    }

    const fetchTotalCAsVencer = async (companyId) => {
        const response = await getTotalCAsVencer(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            caAVencer: response.data.quantidade,
        }));
    }

    const fetchTotalEquipEstoqueBaixo = async (companyId) => {
        const response = await getTotalEquipEstoqueBaixo(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            equipEstoqueBaixo: response.data.quantidade,
        }));
    }

    const fetchTotalEquipDesconectados = async (companyId) => {
        const response = await getTotalEquipDesconectados(companyId);
        setSummaryData(prevState => ({
            ...prevState,
            equipDesconectados: response.data.quantidade,
        }));
    }

    const getSummaryData = async (companyId) => {
        await fetchTotalEpisVencidos(companyId);
        await fetchTotalCAsVencidos(companyId);
        await fetchTotalCAsVencer(companyId);
        await fetchTotalEquipEstoqueBaixo(companyId);
        await fetchTotalEquipDesconectados(companyId);
    };

    const getInfoDashboard = async (companyId) => {
        setIsLoading(true);
        try {
            if (companyId) {
                await Promise.all([
                    getSummaryData(companyId),
                ]);
            }
        } catch (error) {
            exibirAlerta('Ops!', 'Ocorreu um erro ao atualizar as informações', 'error');
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (isAdmin) {
            if (selectedEmpresaId) {
                getInfoDashboard(selectedEmpresaId);
            }
        }
    }, [selectedEmpresaId]);

    useEffect(() => {
        if (hasPermission(["admin", "admin_empresas", "list_empresas"])) {
            listaEmpresas().then((response => {
                const data = response?.data.data;
                if (data && data.length > 0) {
                    let requestedIds = [];
                    data.map((empresa) => requestedIds.push(empresa.id));
                    setSelectedEmpresaId(requestedIds);
                }
            }));
        }
    }, []);

    return (
        <>
            <Helmet title='Página Inicial' defer={false} />

            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={3}
                padding={1}
            >
                <Grid item xs={12}>
                    {/* <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao >
                    <Typography variant='span'>Página Inicial</Typography>
                </Breadcrumbs> */}
                    <PageTitle icon={<HomePageIcon fontSize='large' />} title='Página Inicial' />
                </Grid>

                <Grid item xs={12}>
                    <Stack justifyContent='space-between' sx={{ md: { flexDirection: 'row', alignItems: 'center' } }}>
                        <Typography variant='h5'>Bem-vindo, {dadosUsuario?.pessoa?.nome}</Typography>
                        <Typography variant='body1' color={grey[700]}>{dadosUsuario?.pessoa?.empresa?.nome}</Typography>
                    </Stack>
                </Grid>

                {isAdmin && (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <SummaryEpis summaryData={summaryData} isLoading={isLoading} />
                    </Grid>
                )}

                <Grid
                    container
                    item
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    mt={1}
                    spacing={3}
                    xs={12}
                    md={isAdmin ? 8 : 12}
                    lg={isAdmin ? 9 : 12}
                >
                    <Grid item xs={12} sm={widthCard} xl={4}>
                        <CardOption
                            title="Cadastro de Equipamentos"
                            description="Gerencie os Equipamentos das empresas."
                            url='/epis/equipamentos'
                            isFavorite
                        />
                    </Grid>

                    <Grid item xs={12} sm={widthCard} xl={4}>
                        <CardOption
                            title="Alertas Disparados"
                            description="Visualize os Alertas que foram disparados."
                            url='/epis/alertas/lista_alertas'
                            isFavorite
                        />
                    </Grid>

                    <Grid item xs={12} sm={widthCard} xl={4}>
                        <CardOption
                            title="Cadastro de Produtos"
                            description="Cadastre e gerencie EPIs com facilidade."
                            url='/epis/produtos/listadeprodutos'
                            isFavorite
                        />
                    </Grid>

                    <Grid item xs={12} sm={widthCard} xl={4}>
                        <CardOption
                            title="Relatorios Ficha EPI"
                            description="Visualize os Alertas que foram disparados."
                            url='/epis/relatorios/relatorios_ficha_epi'
                            isFavorite
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default Home;