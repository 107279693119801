import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Breadcrumbs, Button, Grid, Stack, Typography } from '@mui/material';
import { Home, Publish } from '@mui/icons-material';
import LinkNavegacao from '../../common/Link';
import PageTitle from '../../common/PageTitle';
import { DatabaseRestoreIcon } from '../../common/Icons';
import { FileUpload } from './components/FileUpload';
import { SpreadsheetImportDialog } from './components/SpreadsheetImportDialog';

export function SpreadsheetImport() {
  const [spreadsheetFile, setSpreadsheetFile] = useState(null);
  const [formOpen, setFormOpen] = useState(false);

  const fileUploadProp = {
    accept: '.xls,.xlsx',
    onChange: (event) => {
      if (
        event.target.files !== null &&
        event.target?.files?.length > 0
      ) {
        setSpreadsheetFile(event.target.files[0]);
      }
    },
    onDrop: (event) => {
      setSpreadsheetFile(event.dataTransfer.files[0]);
    },
    onRemove: () => {
      setSpreadsheetFile(null);
    }
  }

  return (
    <>
      <Helmet title='Importação de Planilhas' defer={false} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento Admin</Typography>
            <Typography variant='span'>Importação</Typography>
          </Breadcrumbs>
          <PageTitle icon={<DatabaseRestoreIcon fontSize='large' />} title='Importação de Planilhas' />
        </Grid>

        <Grid container item justifyContent="center" alignItems="center">
          <Stack spacing={5} alignItems="center" justifyContent='center' sx={{ width: '100%', height: 'calc(100vh - 140px)', mt: '-5%', textAlign: 'center' }}>
            <FileUpload {...fileUploadProp} />

            <Button
              variant='contained'
              disabled={!spreadsheetFile}
              startIcon={<Publish style={{ filter: !spreadsheetFile ? "grayscale(1)" : "" }} />}
              onClick={() => setFormOpen(true)}
            >
              Iniciar Importação
            </Button>
          </Stack>
        </Grid>
      </Grid>

      <SpreadsheetImportDialog
        open={formOpen}
        setOpen={setFormOpen}
        spreadsheetFile={spreadsheetFile}
      />
    </>
  );
}