import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Badge,
    MenuItem,
    Menu,
    AppBar,
    Stack,
} from '@mui/material';
import { Help, LogoutOutlined, Warning } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';
import MoreIcon from '@mui/icons-material/MoreVert';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { listaAlertas } from '../screens/epis/alerts/configs/functions';
import { useAppContext } from '../../contexts/AppContext';
import { AlertsMenu } from './AlertsMenu';
import Logotipo from "../../assets/images/stw_logo.svg"
import { hasPermission } from '../../configs/functions';
import { encrypt } from '../../utils/cryptoUtils';

export const queryKeyListAlertsMenu = ['listOfAlertsMenu'];
export const queryKeyListUnreadAlerts = ['listOfUnreadAlerts'];

export default function PrimaryAppBar() {
    /** Controle do Drawer Mobile */
    const { dadosUsuario, handleToggleDrawer, signOut } = useAppContext();

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [anchorAlertEl, setAnchorAlertEl] = useState(null);
    const [hasPermissionToListAlerts, setHasPermissionToListAlerts] = useState(false);

    const { data: alertsData, isLoading: isLoadingAlertas, isPending: isPendingAlertas } = useQuery({
        queryKey: queryKeyListAlertsMenu,
        queryFn: () => listaAlertas({ limit: 10, offset: 0, status: 0 }),
        enabled: !!hasPermissionToListAlerts,
    });

    const alerts = alertsData?.data?.data || [];

    const { data: unreadAlertsData, isLoading: isLoadingUnreadAlerts, isPending: isPendingUnreadAlerts } = useQuery({
        queryKey: queryKeyListUnreadAlerts,
        queryFn: () => listaAlertas({ status: 1 }),
        enabled: !!hasPermissionToListAlerts,
    });

    const unreadAlerts = unreadAlertsData?.data?.data || [];
    const isLoading = isLoadingAlertas || isPendingAlertas || isLoadingUnreadAlerts || isPendingUnreadAlerts;

    useEffect(() => {
        if (!dadosUsuario || !hasPermission(["admin", "admin_alerta", "list_alerta"])) {
            return;
        }

        setHasPermissionToListAlerts(true);
    }, [dadosUsuario]);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    // verificar se dadosUsuario.pessoa.tipoUsuario é "administrador" para montar o link
    const encryptedId = encodeURIComponent(encrypt(String(dadosUsuario.pessoa.id_pessoa)));
    const linkProfile = dadosUsuario.pessoa.tipoUsuario === "colaborador"
        ? `/gerenciamento/pessoas/colaboradores?openEditForm=1&option=${encryptedId}`
        : `/gerenciamento/pessoas/usuarios?openEditForm=1&option=${encryptedId}`;

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            id={menuId}
            anchorEl={anchorEl}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>
                <Link to={linkProfile} reloadDocument style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Stack direction="row" spacing={1}>
                        <AccountCircle size="small" color="textSecondary" />
                        <Typography variant="body1" color="textSecondary">
                            Meu Perfil
                        </Typography>
                    </Stack>
                </Link>
            </MenuItem>

            <MenuItem onClick={() => {
                handleMenuClose()
                signOut()
                window.location.href = "/"
            }}>
                <Stack direction="row" spacing={1}>
                    <LogoutOutlined size="small" color='textSecondary' />
                    <Typography variant="body1" color="textSecondary">
                        Sair
                    </Typography>
                </Stack>
            </MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <InputLabel variant='filled' sx={{
                fontSize: '0.75rem',
                mb: 1
            }}>Alterar módulo:</InputLabel>
            <ModuleSelector />
            <Divider variant='middle' /> */}

            <MenuItem onClick={() => setAnchorAlertEl(true)}>
                <IconButton size="large" color="inherit" aria-label={`Exibir ${unreadAlerts.length} novos alertas`}>
                    <Badge badgeContent={unreadAlerts.length} color="error">
                        <Warning />
                    </Badge>
                </IconButton>
                <p>Alertas</p>
            </MenuItem>

            <MenuItem>
                <IconButton
                    size="large"
                    aria-label="Ajuda"
                    color="inherit"
                >
                    <Help />
                </IconButton>
                <p>Ajuda</p>
            </MenuItem>

            <MenuItem onClick={handleProfileMenuOpen}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    {dadosUsuario.pessoa.avatar && dadosUsuario.pessoa.avatar.includes('/9j/') ?
                        <img src={`data:image/jpeg;base64,${dadosUsuario.pessoa.avatar}`} alt="Avatar" style={{ borderRadius: '50%', width: 30, height: 30, outline: '2px solid #fff' }} />
                        : <AccountCircle />
                    }
                </IconButton>
                <p>Meu Perfil</p>
            </MenuItem>
        </Menu>
    );

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Toolbar sx={{ width: '100%', maxWidth: 1920, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                    <IconButton
                        onClick={handleToggleDrawer}
                        size='large'
                        sx={{ mr: 1, color: 'inherit' }}>
                        <MenuIcon />
                    </IconButton>
                    <Box
                        component={'img'}
                        src={Logotipo}
                        sx={{
                            height: 'auto',
                            width: {
                                xs: "23vw",
                                sm: "12vw",
                                md: "10vw",
                                lg: "8vw",
                                xl: "6vw"
                            },
                            maxWidth: 120,
                            pr: 2
                        }}

                    />
                    <Box>
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{
                                fontSize: {
                                    xs: "0.85em",
                                    sm: "1em",
                                    md: "1.1em",
                                    lg: "1.2em"
                                },
                                lineHeight: {
                                    xs: "1em",
                                    md: "inherit"
                                }
                            }}
                        >
                            SAFE TO WORK
                        </Typography>
                        <Typography
                            variant="caption"
                            component="div"

                        >
                            Sistema de Gestão de Segurança do Trabalho
                        </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1 }} />

                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        {hasPermission(["admin", "admin_alerta", "list_alerta"]) && (
                            <IconButton
                                size="large"
                                aria-label={`Exibir ${unreadAlerts.length} novos alertas`}
                                color="inherit"
                                aria-controls={Boolean(anchorEl) ? 'long-menu' : undefined}
                                aria-expanded={Boolean(anchorEl) ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(event) => setAnchorAlertEl(event.currentTarget)}
                            >
                                <Badge badgeContent={unreadAlerts.length} color="error">
                                    <Warning />
                                </Badge>
                            </IconButton>
                        )}

                        <IconButton
                            size="large"
                            aria-label="Ajuda"
                            color="inherit"
                        >
                            <Help />
                        </IconButton>

                        <IconButton
                            size="large"
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            {dadosUsuario.pessoa.avatar && dadosUsuario.pessoa.avatar.includes('/9j/') ?
                                <img src={`data:image/jpeg;base64,${dadosUsuario.pessoa.avatar}`} alt="Avatar" style={{ borderRadius: '50%', width: 30, height: 30, outline: '2px solid #fff' }} />
                                : <AccountCircle />
                            }
                        </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <Badge badgeContent={unreadAlerts.length} color="error">
                                <MoreIcon />
                            </Badge>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>

            {renderMobileMenu}
            {renderMenu}

            <AlertsMenu
                alerts={alerts}
                unreadAlerts={unreadAlerts}
                anchorEl={anchorAlertEl}
                handleCloseMenu={() => setAnchorAlertEl(null)}
                isLoading={isLoading}
                queryKeyListAlertsMenu={queryKeyListAlertsMenu}
                queryKeyListUnreadAlerts={queryKeyListUnreadAlerts}
            />
        </Box>
    );
}
