import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Home } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import CardOption from '../../../common/CardOption';
import { NotificationIcon } from '../../../common/Icons';

function Alerts() {
    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={3}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
                    <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
                    <Typography variant='span'>Alertas</Typography>
                </Breadcrumbs>

                <PageTitle icon={<NotificationIcon fontSize='large' />} title='Alertas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Alertas Disparados" description="Visualize os Alertas que foram disparados." url='lista_alertas' />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption title="Configuração dos Alarmes" description="Gerencie os Alarmes das empresas." url='config_alertas' />
            </Grid>
        </Grid>
    );
}

export default Alerts;