import { useState, useEffect } from 'react';
import {
    Box,
    Grid,
    FormControl,
    TextField,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    MenuItem,
    Typography,
    Autocomplete,
    InputAdornment,
    Tooltip,
    IconButton,
} from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { fillInputErrors, getFormErrorMessage } from '../../../../configs/functions';
import { CommonDialogActionsForm } from '../../../common/CommonDialogActionsForm';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { atualizaConfigAlarme } from './configs/functions';
import { MuiColorInput, matchIsValidColor } from "mui-color-input";
import { blue, grey } from '@mui/material/colors';
import { Info } from '@mui/icons-material';

export function ConfigAlertForm({ open, setOpen, selectedConfig, contratoId }) {
    const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

    const [isLoading, setIsLoading] = useState(false);
    const [usersEmails, setUsersEmails] = useState([]);
    const [emails, setEmails] = useState([]);

    const isEditAction = !!selectedConfig;

    const tipoAtivacaoOptions = [{ label: 'Pontual', value: 'pontual' }, { label: 'Persistente', value: 'persistente' }];

    const {
        register,
        handleSubmit,
        setError,
        reset,
        control,
        formState: { dirtyFields, errors },
    } = useForm();

    const onSubmit = async (data) => {
        let formDataToSend = {};
        setIsLoading(true);
        exibirDialog('Salvando. Aguarde...');

        if (isEditAction) {
            const dirtyFieldKeys = Object.keys(dirtyFields);

            const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
                if (data.hasOwnProperty(key)) {
                    acc[key] = data[key];
                }
                return acc;
            }, {});

            formDataToSend = dirtyData;

            formDataToSend.id = selectedConfig.id;
        } else {
            delete data.id;
            formDataToSend = data;
        }

        try {
            const res = await atualizaConfigAlarme(formDataToSend);
            exibirAlerta('Sucesso', res.data.message, 'success');
            setOpen(false);
        } catch (error) {
            console.log(error);
            let errorMessage = "Erro ao atualizar Configuração de Alarme.";
            if (error.response) {
                const responseErrorMessage = error.response.data?.error;
                errorMessage = responseErrorMessage || errorMessage;
                fillInputErrors(error, setError);
            }
            exibirAlerta('Erro', errorMessage, 'error');
        } finally {
            setIsLoading(false);
            handleCloseDialog();
        }
    };

    useEffect(() => {
        if (open) {
            const defaultValues = {
                id: selectedConfig ? selectedConfig.id : '',
                status: selectedConfig ? selectedConfig.status : '',
                tipo_ativacao: selectedConfig ? selectedConfig.tipoAtivacao : 1,
                emails: selectedConfig ? selectedConfig.emailResponsavel : '',
                hexadecimal_color: selectedConfig ? selectedConfig.hexadecimalColor : '',
            };
            reset(defaultValues);
            if (selectedConfig) {
                setEmails(selectedConfig.emailResponsavel);
            }
        } else {
            reset();
            setEmails([]);
        }
    }, [open, reset, selectedConfig]);

    useEffect(() => {
        // transformar por autocomplete também
        const filterByContratoId = { "id_contrato": contratoId };
        listaUsuariosFilter(filterByContratoId).then((res) => {
            const emails = res?.data.data.map((item) => item.email).filter((item) => item);
            setUsersEmails(emails);
        });
    }, [contratoId]);

    return (
        <Dialog open={open} fullWidth maxWidth={"md"}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditAction
                    ? <DialogTitle>Editando Configuração de Alarme <strong>{selectedConfig.nome}</strong></DialogTitle>
                    : <DialogTitle>Cadastrar novo Configuração de Alarme</DialogTitle>
                }
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Configuração de Alarme.'}
                    </DialogContentText>

                    <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
                        <FormControl variant="standard" fullWidth >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-end"
                                spacing={2}
                            >
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        select
                                        label="Tipo de Disparo do Alerta"
                                        name='tipo_ativacao'
                                        fullWidth
                                        defaultValue={selectedConfig ? selectedConfig.tipoAtivacao : 'pontual'}
                                        error={!!errors.tipo_ativacao}
                                        helperText={getFormErrorMessage(errors, 'tipo_ativacao')}
                                        {...register("tipo_ativacao")}
                                        sx={{ mt: 1 }}
                                        InputProps={{
                                            startAdornment:
                                                <>
                                                    <InputAdornment position="start">
                                                        <Tooltip
                                                            title={
                                                                <Box sx={{ backgroundColor: grey[50], padding: 1, borderRadius: 1, color: grey[900] }}>
                                                                    <Typography variant='body2'>
                                                                        <strong>Tipo de Disparo</strong>

                                                                        <p><strong>PONTUAL:</strong> O alarme será monitorado e apenas uma notificação será enviada para o endereço de e-mail cadastrado;</p>

                                                                        <p><strong>PERSISTENTE:</strong> O alarme será monitorado e as notificações não cessarão até que sejam checadas no cadastro de alertas.</p>
                                                                    </Typography>
                                                                </Box>
                                                            }
                                                            placement="top"
                                                        >
                                                            <IconButton size="small">
                                                                <Info sx={{ color: blue[500], cursor: 'pointer' }} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </InputAdornment>
                                                </>,
                                        }}
                                    >
                                        {tipoAtivacaoOptions.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            Cor do Alerta
                                        </Typography>

                                        <Controller
                                            name="hexadecimal_color"
                                            control={control}
                                            rules={{ validate: matchIsValidColor }}
                                            render={({ field, fieldState }) => (
                                                <MuiColorInput
                                                    {...field}
                                                    isAlphaHidden
                                                    format="hex"
                                                    helperText={fieldState.invalid ? "Color is invalid" : ""}
                                                    error={fieldState.invalid}
                                                    inputProps={{ readOnly: true }}
                                                    sx={{ '& .MuiInputBase-input': { color: grey[500] } }}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControl fullWidth sx={{ mt: 1 }}>
                                        <Controller
                                            name="emails"
                                            control={control}
                                            defaultValue={[]}
                                            rules={{
                                                validate: value => {
                                                    if (value.length === 0) {
                                                        return "Selecione ao menos um email.";
                                                    }
                                                    for (let email of value) {
                                                        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
                                                            return `Email inválido: ${email}`;
                                                        }
                                                    }
                                                    return true;
                                                }
                                            }}
                                            render={({ field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    multiple
                                                    freeSolo
                                                    options={usersEmails}
                                                    getOptionLabel={(option) => option}
                                                    value={emails}
                                                    onChange={(event, newValue) => {
                                                        setEmails(newValue);
                                                        field.onChange(newValue);
                                                    }}
                                                    disableClearable
                                                    noOptionsText="Nenhum email encontrado"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            label="Destinatários"
                                                            placeholder="Selecione os destinatários"
                                                            helperText={getFormErrorMessage(errors, 'emails')}
                                                            error={!!errors.emails}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </Box>
                </DialogContent>

                <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
            </form>
        </Dialog>
    );
}